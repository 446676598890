import * as React from 'react';
import isObjectNotEquals from '../../../../utils/object-comparison';
import { emptyArray } from '../../../../utils/const-variable';
import isEmptyObject from '../../../../utils/isEmptyObject';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from '../../../../utils/applicationTitle';
import ListToolbar from '../../../../common/components/list-toolbar/list-toolbar';
import { sortFilterList } from '../../../../common/components/list/sortList';
import Scrollbars from 'react-custom-scrollbars';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce } from '../../../../services/debounce';
import { List } from '../../../../common/components/list/list';
import { connect } from 'react-redux';
import {
  changeColumnsToDisplay,
  getTrainingsReports,
  resetTrainingsReportsListSettings,
  setTrainingsReports,
  setTrainingsReportsListSettings,
} from '../../actions/trainingsActions';
import { getFilters } from '../../../../common/actions/filtersActions';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import { resetComponent, setComponent } from '../../../../common/actions/headerActions';
import { initialState, defaultColumnsToDisplay } from '../../reducers/trainingsReducer';

const filterNames = [
  'Offices',
  'EmployeeStatusesNew',
  'Clients',
  'Trainings',
  'EmployeeDepartments',
];

export class Trainings extends React.Component {
  constructor(props) {
    super(props);

    this.headerWrapperRef = React.createRef();
    this.scrollBarRef = React.createRef();

    this.state = {
      hasMoreReports: true,
      scrollBarValues: null,
      trainingsCount: 0,
    };
  }

  componentDidMount() {
    const {
      setComponent, getFilters, listSettings: {
        searchValue, sortColumn, sortDirection, takeAmountReports,
      },
      getTrainingsReports,
    } = this.props;

    getFilters(filterNames);
    const defaultFilters = this.setDefaultFilters();
    getTrainingsReports(defaultFilters, searchValue, takeAmountReports, 0, { column: sortColumn, sortDirection });
    this.setColumnsForTrainings();
    setComponent({ title: 'Trainings Report' });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      getTrainingsReports, listSettings, reports,
    } = this.props;
    const { trainingsCount } = this.state;
    const {
      filters, searchValue, sortColumn, sortDirection, takeAmountReports,
    } = listSettings;
    if (prevProps.listSettings !== initialState.listSettings && isObjectNotEquals(prevProps.listSettings, listSettings)) {
      getTrainingsReports(filters, searchValue, takeAmountReports, 0, { column: sortColumn, sortDirection });
      this.setColumnsForTrainings();
    }

    if (reports.trainings && reports.trainings.length !== trainingsCount) {
      this.setColumnsForTrainings();
      this.setState({
        trainingsCount: reports.trainings.length,
      });
    }
  }

  componentWillUnmount() {
    const { setTrainingsReports, resetComponent, resetTrainingsReportsListSettings } = this.props;
    setTrainingsReports(emptyArray);
    resetComponent();
    resetTrainingsReportsListSettings();
  }

  sortTrainingsByOrder = (a, b) => {
    if (a.Order < b.Order) {
      return -1;
    }
    if (a.Order > b.Order) {
      return 1;
    }
    return 0;
  }

  setColumnsForTrainings = () => {
    const {
      changeColumnsToDisplay, listSettings: { filters }, trainings,
    } = this.props;

    const filteredByTrainings = filters && filters.Trainings;
    const templateColumnObject = {
      className: 'medium-col',
      isDate: true,
      isTrainingReport: true,
      isSelected: true,
      hideArrows: true,
    };
    const columnsWithTrainings = filteredByTrainings && filters.Trainings.length > 0 ? filters.Trainings
      .map(training => {
        const trainingObj = trainings.find(item => item.Id === training);
        return {
          ...templateColumnObject,
          Name: trainingObj.Name,
          Id: `EmployeeTrainings.${trainingObj.Id}.DateOfPassing`,
          Order: trainingObj.Order,
        };
      }).sort(this.sortTrainingsByOrder) : trainings && trainings
      .sort(this.sortTrainingsByOrder)
      .map(training => {
        return {
          ...templateColumnObject,
          Name: training.Name,
          Id: `EmployeeTrainings.${training.Id}.DateOfPassing`,
        };
      });
    const newColumnsToDisplay = [...defaultColumnsToDisplay, ...columnsWithTrainings];

    changeColumnsToDisplay(newColumnsToDisplay);
  }

  setDefaultFilters = () => {
    const {
      listSettings: { filters }, setTrainingsReportsListSettings,
    } = this.props;
    const currentFilters = {
      ...filters,
      Offices: filters.Offices || [],
      EmployeeTypes: filters.EmployeeTypes || [],
      Clients: filters.Clients || [],
      Trainings: filters.Trainings || [],
      EmployeeDepartments: filters.EmployeeDepartments || [],
    };

    setTrainingsReportsListSettings({ filters: currentFilters });

    return currentFilters;
  };

  applyFilters = (filters) => {
    const { setTrainingsReportsListSettings, textDataForFilters } = this.props;
    const newFilters = {
      ...filters,
      ...textDataForFilters,
    };
    const isExistsFilters = Object.keys(newFilters).some(filter => newFilters[filter] && !isEmptyObject(newFilters[filter]));
    setTrainingsReportsListSettings({
      filters: isExistsFilters ? newFilters : {},
    });
  };

  resetFilters = () => {
    const { resetTrainingsReportsListSettings } = this.props;
    resetTrainingsReportsListSettings();
  }

  searchTrainingsReports = (searchValue) => {
    const { setTrainingsReportsListSettings } = this.props;
    setTrainingsReportsListSettings({ searchValue });
  }

  changeReportsAmount = (page) => {
    const {
      isLoading,
      listSettings: {
        takeAmountReports,
      },
      setTrainingsReportsListSettings,
    } = this.props;
    const magnificationFactor = 15;
    const increaseParams = takeAmountReports + page * magnificationFactor;
    if (!isLoading) {
      this.checkIfNeedMoreReports(increaseParams);
      setTrainingsReportsListSettings({ takeAmountReports: increaseParams });
    }
  };

  checkIfNeedMoreReports = (takeAmountReports) => {
    const { totalCount } = this.props;
    this.setState({
      hasMoreReports: takeAmountReports <= totalCount,
    });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setTrainingsReportsListSettings } = this.props;
    setTrainingsReportsListSettings({
      sortColumn,
      sortDirection,
    });
  };

  getRequiredColumns = () => {
    const { columnsToDisplay } = this.props;
    const requiredColumns = [];

    for (const column of columnsToDisplay) {
      if (column.isSelected) {
        requiredColumns.push(column.Name.replace(/ /g, ''));
      }
    }

    return requiredColumns;
  };

  downloadXLSXDocument = () => {
    const { downloadXLSXDocument, listSettings } = this.props;
    const { searchValue, filters } = listSettings;
    const requestFilters = { ...filters };
    requestFilters.requiredColumns = this.getRequiredColumns();

    downloadXLSXDocument(
      requestFilters,
      searchValue,
      'trainings-reports/export-xlsx?SearchValue=',
      'Trainings Report.xlsx',
    );
  };

  render() {
    const {
      columnsToDisplay, offices, employeeTypes, employeeDepartments, clients, trainings, reports,
      listSettings: {
        sortColumn, sortDirection, filters, searchValue,
      },
    } = this.props;
    const { hasMoreReports } = this.state;

    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          showExportButton
          searchValue={searchValue}
          exportDocument={this.downloadXLSXDocument}
          searchPlaceholder='Search Reports'
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          onSearchClick={this.searchTrainingsReports}
          columnsToDisplay={columnsToDisplay}
          items={[{
            name: 'Offices',
            id: 'Offices',
            options: sortFilterList(offices) || [],
            multiSelect: true,
            placeholder: 'All Offices',
            filtersItem: filters && filters.Offices,
          }, {
            name: 'Employee Departments',
            id: 'EmployeeDepartments',
            options: sortFilterList(employeeDepartments) || [],
            multiSelect: true,
            placeholder: 'All Employee Departments',
            filtersItem: filters && filters.EmployeeDepartments,
          }, {
            name: 'Employee Types',
            id: 'EmployeeTypes',
            options: sortFilterList(employeeTypes) || [],
            multiSelect: true,
            placeholder: 'All Employee Types',
            filtersItem: filters && filters.EmployeeTypes,
          }, {
            name: 'Clients',
            id: 'Clients',
            options: sortFilterList(clients) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Clients',
            filtersItem: filters && filters.Clients,
          }, {
            name: 'Trainings',
            id: 'Trainings',
            options: sortFilterList(trainings) || [],
            multiSelect: false,
            suggestBox: true,
            placeholder: 'All Trainings',
            filtersItem: filters && filters.Trainings,
          }]}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll'>
            <InfiniteScroll
              pageStart={1}
              loadMore={debounce(this.changeReportsAmount, 500)}
              hasMore={hasMoreReports}
              useWindow={false}
              initialLoad={false}
              threshold={500}
            >
              <List
                columnsToDisplay={columnsToDisplay}
                items={reports.trainings}
                hideDots
                setSortSettings={this.setSortSettings}
                sortColumnName={sortColumn}
                sortDirection={sortDirection}
                fixedHeader
                dontShowOptions
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default connect((store) => ({
  reports: store.reportsReducer.reports,
  offices: store.filtersReducer.filters.Offices,
  employeeTypes: store.filtersReducer.filters.EmployeeStatusesNew,
  employeeDepartments: store.filtersReducer.filters.EmployeeDepartments,
  clients: store.filtersReducer.filters.Clients,
  trainings: store.filtersReducer.filters.Trainings,
  listSettings: store.trainingsReducer.listSettings,
  columnsToDisplay: store.trainingsReducer.columnsToDisplay,
  totalCount: store.reportsReducer.trainingsTotalCount,
}), {
  getTrainingsReports,
  getFilters,
  downloadXLSXDocument,
  setComponent,
  resetComponent,
  changeColumnsToDisplay,
  setTrainingsReportsListSettings,
  setTrainingsReports,
  resetTrainingsReportsListSettings,
})(Trainings);
