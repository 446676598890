import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import DocumentTitle from 'react-document-title';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { List } from '../../../../../common/components/list/list';
import { recursiveSearch } from 'services/recursiveSearch';
import { sortList } from 'common/components/list/sortList';
import { applicationTitle } from 'utils/applicationTitle';
import classNames from 'classnames';
import TextField from 'common/components/form-items/input/text-field';
import {
  createNewTraining,
  deleteTraining,
  getEmployeeTrainingClients,
  getEmployeeTrainings,
  updateTraining,
} from 'pages/admin/actions/adminManageEmployeeTrainings';
import { NumberFormatCustom } from '../../../../../common/components/form-items/input-currency/input-currency';

const additionalSelectsStyles = {
  control: (provided) => ({
    ...provided,
    width: '100px',
    marginTop: '15px',
    marginBottom: '5px',
    marginRight: '20px',
    maxHeight: '40px',
    overflowY: 'auto',
  }),
};

const toggleOptions = {
  'No': { value: 'No', label: 'No', name: 'No' },
  'Yes': { value: 'Yes', label: 'Yes', name: 'Yes' },
};

class ManageEmployeeTrainings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Training', className: 'without-width-col', Id: 'Training',
        },
        {
          Name: 'Customers', className: 'big-col', Id: 'Customers', isArray: true,
        },
        {
          Name: 'MoodleId', className: 'small-col', Id: 'MoodleId',
        },
        {
          Name: 'One Time', className: 'small-col', Id: 'OneTime',
        },
        {
          Name: 'Is Optional', className: 'small-col', Id: 'IsOptional',
        },
        {
          Name: 'Order', className: 'small-col', Id: 'Order', isString: true,
        },
      ],
      searchValue: '',
      sortColumn: '',
      sortDirection: '',
      focusedInput: null,
      showAddTrainingFields: false,
      blockAddTraining: false,
      newTraining: {
        Training: '',
        Customers: [],
        MoodleId: null,
        OneTime: 'No',
        IsOptional: 'No',
        Order: 0,
      },
      editTrainingObject: {},
    };
  }

  componentDidMount() {
    const { loadManageEmployeeTrainings, loadManageEmployeeTrainingClients } = this.props;
    loadManageEmployeeTrainings();
    loadManageEmployeeTrainingClients();
  }

  handleCustomerSelect = (selectedCustomers) => {
    this.setState((prevState) => ({
      newTraining: {
        ...prevState.newTraining,
        Customers: selectedCustomers,
      },
    }));
  };

  handleOneTimeSelect = (selectedOneTime) => {
    const { newTraining } = this.state;
    this.setState({
      newTraining: {
        ...newTraining,
        OneTime: selectedOneTime.value,
      },
    });
  }

  handleIsOptionalSelect = (selectedIsOptional) => {
    const { newTraining } = this.state;
    this.setState({
      newTraining: {
        ...newTraining,
        IsOptional: selectedIsOptional.value,
      },
    });
  }

  handleSearch = (value) => {
    this.setState({ searchValue: value });
  };

  setSortSettings = (column, direction) => {
    this.setState({ sortColumn: column, sortDirection: direction });
  };

  addNewTraining = () => {
    const { blockAddTraining } = this.state;

    if (!blockAddTraining) {
      this.setState({ showAddTrainingFields: true });
    }
  };

  validateNewTraining = () => {
    const { newTraining } = this.state;
    const isTrainingNotEmpty = newTraining.Training.trim() !== '';
    const isMoodleIdValid = !newTraining.MoodleId || newTraining.MoodleId > 0;

    this.setState({
      blockAddTraining: !(isTrainingNotEmpty && isMoodleIdValid),
    });
  }

  getNewTrainingObj = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      newTraining: {
        ...prevState.newTraining,
        [name]: value,
      },
    }), this.validateNewTraining);
  };

  newTrainingCancel = () => {
    this.setState({
      newTraining: {
        Training: '',
        Customers: [],
        MoodleId: null,
        OneTime: 'No',
        IsOptional: 'No',
        Order: 0,
      },
      showAddTrainingFields: false,
      blockAddTraining: false,
      isEditing: false,
    });
  };

  newTrainingApply = () => {
    const {
      isEditing, editTrainingObject, newTraining, blockAddTraining,
    } = this.state;
    const { createNewTraining, updateTraining } = this.props;

    if (blockAddTraining) {
      return;
    }

    const training = {
      ...newTraining,
      Customers: newTraining.Customers && newTraining.Customers.map(customer => customer.Id),
      OneTime: newTraining.OneTime === 'Yes',
      IsOptional: newTraining.IsOptional === 'Yes',
    };
    if (newTraining.Training.trim() !== '') {
      if (isEditing) {
        updateTraining(editTrainingObject.Id, training);
      } else {
        createNewTraining(training);
      }

      this.setState({
        newTraining: {
          Training: '',
          Customers: [],
          MoodleId: null,
          OneTime: 'No',
          IsOptional: 'No',
          Order: 0,
        },
        showAddTrainingFields: false,
        blockAddTraining: false,
        isEditing: false,
      });
    }
  };

  editTraining = (data) => {
    const { item } = data;
    this.setState({
      newTraining: {
        Training: item.Training,
        Customers: item.Customers,
        MoodleId: item.MoodleId,
        OneTime: item.OneTime,
        IsOptional: item.IsOptional,
        Order: item.Order,
      },
      editTrainingObject: item,
      showAddTrainingFields: true,
      isEditing: true,
    });
  };

  handleDeleteClick = (item) => {
    const { deleteTraining } = this.props;
    deleteTraining(item.item.Id);
  };

  handleInputFocusOrBlur = (inputName) => {
    this.setState({ focusedInput: inputName });
  };

  isInputFocused = (inputName) => {
    const { focusedInput } = this.state;
    return focusedInput === inputName;
  };

  filterTrainings = (trainings) => {
    const { editTrainingObject } = this.state;
    return trainings.filter(
      (training) => training.Id !== editTrainingObject.Id,
    );
  };

  render() {
    const { employeeTrainings, employeeTrainingClients } = this.props;
    const {
      columnsToDisplay,
      searchValue,
      sortColumn,
      sortDirection,
      showAddTrainingFields,
      newTraining,
      blockAddTraining,
      isEditing,
    } = this.state;

    return (
      <div className='page-cont'>
        <DocumentTitle
          title={applicationTitle.getTitile('lookupListOfTrainings')}
        />
        <ListToolbar
          showAddButton
          showExportButton={false}
          detailsStyles
          onAddNew={this.addNewTraining}
          onSearchClick={this.handleSearch}
          searchPlaceholder='Search available trainings'
          searchValue={searchValue}
        />
        <List
          columnsToDisplay={columnsToDisplay}
          items={recursiveSearch(
            sortList(
              isEditing
                ? this.filterTrainings(employeeTrainings)
                : employeeTrainings,
              sortColumn,
              sortDirection,
            ),
            searchValue,
          )}
          columnsHeaders={employeeTrainings.length === 0 && columnsToDisplay}
          actionLabel
          setSortSettings={this.setSortSettings}
          sortColumnName={sortColumn}
          isEditable
          editManageItem
          getFullEditItem
          showItemsEditMenu
          onEditClick={this.editTraining}
          onDeleteClick={this.handleDeleteClick}
          sortDirection={sortDirection}
          isHaveRights
          shiftOnEditClassName={showAddTrainingFields && 'training-edit-shift'}
          // config={{
          //   'Training': { cell: CellTextField, valueSetter: () => { } },
          //   'Customers': {
          //     cell: CellSelect, valueSetter: () => { },
          //     options: updatedClients.map((customer) => ({
          //       id: customer.Id,
          //       value: customer.Name,
          //       name: customer.Name,
          //       label: customer.Name,
          //     }))
          //   },
          //   'OneTime': { cell: CellToggleSwitcher, valueSetter: () => {} },
          // }}
        />
        {showAddTrainingFields && (
          <div className='c-list__item-cont c-tabs employee-info__tabs'>
            <div className='c-list__item-column resume-member-inputs c-list__item-column'>
              <div className='c-list__item-column without-width-col training-inputs manage-resume__input-link'>
                <TextField
                  formClassName='step__form resume-input without-width-col'
                  inputClassName='step__input'
                  id='training'
                  placeholder='Training'
                  maxLength='4000'
                  name='Training'
                  value={newTraining.Training}
                  onChange={this.getNewTrainingObj}
                  showClear={false}
                  onFocus={() => this.handleInputFocusOrBlur('training')}
                  onBlur={() => this.handleInputFocusOrBlur('training')}
                  autoFocus={this.isInputFocused('training')}
                />
                <Select
                  ref={(ref) => (this.selectRef = ref)}
                  menuPortalTarget={document.body}
                  isMulti
                  options={employeeTrainingClients.map((customer) => ({
                    id: customer.Id,
                    value: customer.Name,
                    name: customer.Name,
                    label: customer.Name,
                  }))}
                  value={(() => {
                    return newTraining.Customers.map(
                      (customer) => ({
                        id: customer.Id,
                        name: customer.Name,
                        value: customer.Name,
                        label: customer.Name,
                      }),
                    );
                  })()}
                  onChange={(selectedOptions) => {
                    const selectedCustomers = selectedOptions.map(
                      (option) => ({
                        Id: option.id,
                        Name: option.name,
                      }),
                    );
                    this.handleCustomerSelect(selectedCustomers);
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '230px',
                      marginTop: '15px',
                      marginBottom: '5px',
                      marginRight: '20px',
                      maxHeight: '40px',
                      overflowY: 'auto',
                    }),
                  }}
                  menuPosition='fixed'
                />
                <TextField
                  formClassName='step__form resume-input training-edit-order small-col'
                  inputClassName='step__input'
                  id='moodleId'
                  name='MoodleId'
                  placeholder='Moodle Id'
                  allowNegative={false}
                  maxValue={1000}
                  value={typeof newTraining.MoodleId === 'number' ? newTraining.MoodleId.toString() : newTraining.MoodleId}
                  onChange={this.getNewTrainingObj}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <Select
                  name='OneTime'
                  id='oneTime'
                  options={Object.values(toggleOptions)}
                  value={toggleOptions[newTraining.OneTime]}
                  onChange={this.handleOneTimeSelect}
                  styles={additionalSelectsStyles}
                  menuPosition='fixed'
                />
                <Select
                  name='IsOptional'
                  id='isOptional'
                  options={Object.values(toggleOptions)}
                  value={toggleOptions[newTraining.IsOptional]}
                  onChange={this.handleIsOptionalSelect}
                  styles={additionalSelectsStyles}
                  menuPosition='fixed'
                />
                <TextField
                  formClassName='step__form resume-input training-edit-order small-col'
                  inputClassName='step__input'
                  id='order'
                  name='Order'
                  placeholder='Order'
                  maxValue={100}
                  value={typeof newTraining.Order === 'number' ? newTraining.Order.toString() : newTraining.Order}
                  onChange={this.getNewTrainingObj}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </div>
              <div
                className='training-confirmation-buttons manage-resume__input-discription'
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              >
                <div
                  className='toolbar-button training-button-cancel'
                  onClick={this.newTrainingCancel}
                >
                  <span>Cancel</span>
                </div>
                <div
                  className={classNames('button next training-button-apply', {
                    'btn-disabled': blockAddTraining || newTraining.Training.trim() === '',
                  })}
                  onClick={this.newTrainingApply}
                >
                  <span>Apply</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ManageEmployeeTrainings.propTypes = {
  loadManageEmployeeTrainings: PropTypes.func,
  createNewTraining: PropTypes.func,
  updateTraining: PropTypes.func,
  deleteTraining: PropTypes.func,
  loadManageEmployeeTrainingClients: PropTypes.func,
};

export default connect(
  (store) => ({
    employeeTrainings: store.adminManageLookups.trainings,
    employeeTrainingClients: store.adminManageLookups.clients,
    listSettings: store.adminManageLookups.listSettings,
  }),
  {
    loadManageEmployeeTrainings: getEmployeeTrainings,
    createNewTraining,
    updateTraining,
    deleteTraining,
    loadManageEmployeeTrainingClients: getEmployeeTrainingClients,
  },
)(ManageEmployeeTrainings);
