/* eslint-disable */
// //////////////////////////////////////////
//               HINT MESSAGES             //
// //////////////////////////////////////////
export const NO_ITEMS_TITLE = 'No items';
export const MAX_QUANTITY_OF_RESUME = 7;

// //////////////////////////////////////////
//              ERROR MESSAGES            //
// //////////////////////////////////////////
export const CLIENT_NAME_ALREADY_EXISTS = 'Client name already exists';
export const OFFICE_NAME_ALREADY_EXISTS = 'Office name already exists';
export const INVOICE_AMOUNT_NEGATIVE_VALUE_ERROR_MESSAGE = 'Difference between previous and current Invoiced Amount values can not be greater than Invoice Amount value of parent contract';
export const MESSAGE_FOR_INCORRECT_DATA_ON_PROJECT_DETAILS = 'Data entered incorrectly and will not be saved';
export const MESSAGE_ACCESS_DENIED = 'Access denied';
export const MESSAGE_FOR_DUPLICATED_LOCATION_IN_OFFICE = 'The office should not have duplicate locations';

// //////////////////////////////////////////
//              WARNING MESSAGES           //
// //////////////////////////////////////////
export const CHANGE_ENGAGEMENT_TYPE = 'If you change Engagement Type to "Individuals" all current teams in a project will be deleted. Are you sure you want to change Engagement Type?';

// //////////////////////////////////////////
//              ERROR STATUSES             //
// //////////////////////////////////////////
export const BAD_REQUEST_STATUS = 400;
export const UNAUTHORIZED_STATUS = 401;
export const FORBIDEN_STATUS = 403;
export const PAGE_NOT_FOUND_STATUS = 404;
export const CONFLICT = 409;
export const INVOICE_AMOUNT_NEGATIVE_VALUE_ERROR_CODE = 1004;
export const ROLE_ASSIGNMENT_ERROR_CODE = 1004;

// //////////////////////////////////////////
//              USER ROLES                //
// //////////////////////////////////////////
export const ADMIN_ROLE_NAME = 'Admin';
export const OFFICE_DIRECTOR_ROLE_NAME = 'Office Director';
export const HR_ROLE_NAME = 'HR';
export const ACCOUNT_MANAGER_ROLE_NAME = 'Account Manager';
export const ENGINEERING_MANAGER_ROLE_NAME = 'Engineering Manager';
export const FINANCE_MANAGER_ROLE_NAME = 'Finance Manager';
export const DELIVARY_MANAGER_ROLE_NAME = 'Delivery Manager';
export const API_ACCESS_ROLE_NAME = 'API Access';

// //////////////////////////////////////////
//              EMPLOYEE ROLES            //
// //////////////////////////////////////////
export const PROJECT_ROLES_EMPLOYEES_PRIMARY = 'Primary';
export const PROJECT_ROLES_EMPLOYEES_SHADOW = 'Shadow';

// //////////////////////////////////////////
//              ROLE ASSIGNMENTS             //
// //////////////////////////////////////////
export const MAX_COUNT_ROLE_ASSIGNMENTS = 1000;
export const MAX_COUNT_ROLE_EMPLOYEE_ASSIGNMENTS = 10;
export const DEFAULT_EMPLOYEE_ASSIGNMENTS = 1;

// //////////////////////////////////////////
//              EMPLOYEE TYPES (STATUS)    //
// //////////////////////////////////////////
export const PROJECT_ROLES_EMPLOYEES_DISABLED = 'Disabled';
export const PROJECT_ROLES_EMPLOYEES_FORMER = 'Former employee';
export const PROJECT_ROLES_EMPLOYEES_ADMIN = 'Admin Staff';
export const EMPLOYEES_STATUS_CONTRACTOR = 'Contractor';
export const EMPLOYEES_STATUS_EMPLOYEE = 'Employee';
export const EMPLOYEES_STATUS_TRAINEE = 'Trainee';
export const EMPLOYEES_STATUS_CANDIDATE = 'Candidate';
export const EMPLOYEES_STATUS_BENCH = 'Bench';
export const EMPLOYEES_STATUS_UNPAID = 'Unpaid';
export const EMPLOYEES_STATUS_MATERNITY = 'Maternity';

// //////////////////////////////////////////
//         EMPLOYEE TYPES (STATUS) IDs     //
// //////////////////////////////////////////
export const EMPLOYEES_STATUS_EMPLOYEE_ID = 1;
export const EMPLOYEES_STATUS_CONTRACTOR_ID = 2;
export const EMPLOYEES_STATUS_FORMER_ID = 3;
export const EMPLOYEES_STATUS_ADMIN_ID = 4;
export const EMPLOYEES_STATUS_TRAINEE_ID = 5;
export const EMPLOYEES_STATUS_DISABLED_ID = 6;
export const EMPLOYEES_STATUS_CANDIDATE_ID = 7;
export const EMPLOYEES_STATUS_BENCH_ID = 8;
export const EMPLOYEES_STATUS_UNPAID_ID = 9;
export const EMPLOYEES_STATUS_MATERNITY_ID = 10;

// //////////////////////////////////////////
//              ASSIGNMENT STATUSES        //
// //////////////////////////////////////////
export const PROJECT_ROLES_STATUS_ACTIVE = 'Active';
export const PROJECT_ROLES_STATUS_PROSPECTIVE = 'Prospective';
export const PROJECT_ROLES_STATUS_REMOVED = 'Removed';

// //////////////////////////////////////////
//              PROJECT TYPES             //
// //////////////////////////////////////////
export const NON_BILLABLE_TRAINING = 'Non-billable Training';
export const PROJECT_TYPE_BILLABLE = 1;
export const CREATE_PROJECT_SOW_TYPE_TIME_AND_MATERIAL = '3';
export const CREATE_PROJECT_DELETE_SOW_DIALOG_TITLE = 'DELETE SOW';
export const CREATE_PROJECT_DELETE_PO_DIALOG_TITLE = 'DELETE PO';

// //////////////////////////////////////////
//              SOW TYPES                 //
// //////////////////////////////////////////
export const TIME_AND_MATERIAL = 'T&M Actual Time';
export const MAX_QUANTITY_OF_DOCUMENTS = 15;

// //////////////////////////////////////////
//              DOCUMENT STATUSES         //
// //////////////////////////////////////////
export const DRAFT = 'Draft';
export const ACTIVE = 'Active';
export const EXTENDED = 'Extended';
export const FINISHED = 'Finished';

// //////////////////////////////////////////
//              APPROVE STATUSES          //
// //////////////////////////////////////////
export const APPROVE = 'Approve';
export const NOT_APPROVE = 'Not Approve';
// //////////////////////////////////////////
//             COLORS STATUS              //
// //////////////////////////////////////////
export const STATUS_COLOR = {
  red: '#D54144',
  green: '#27AE60',
  grey: '#BDBDBD',
  blue: '#56CCF2',
  lightRed: '#F5716F',
  charcoal: '#464646',
  violet: '#AA66C3',
  yellow: '#EBAA16',
  emerald: '#4DCFA9',
  turquoise: '#3089A7',
  mediumTurquoise: '#1A6267',
  slightlyDesaturatedBlue: '#8884d8',
  softRed: '#e06e6e',
};
// //////////////////////////////////////////
//             POs LOCATION               //
// //////////////////////////////////////////
export const ADD_PO_LOCATION = 'add po';
export const EDIT_PO_LOCATION = 'edit po';
export const VIEV_PO_LOCATION = 'view po';

// //////////////////////////////////////////
//             Billable Statuses          //
// //////////////////////////////////////////
export const STATUS_BILLABLE = 'Billable';
export const STATUS_BILLABLE_ID = 1;
export const STATUS_INVESTED_CLIENT = 'Invested - client';
export const STATUS_INVESTED_CLIENT_ID = 2;
export const STATUS_INVESTED_AKVELON = 'Invested - akvelon';
export const STATUS_INVESTED_AKVELON_ID = 3;
export const STATUS_INTERNAL = 'Internal - akvelon';
export const STATUS_INTERNAL_ID = 4;

// //////////////////////////////////////////
//              List item                 //
// //////////////////////////////////////////
export const DELETE_SPAN_REMOVE_ACCESS = 'Remove access';
export const FILTER_ITEM_DATEPICKER_DEFAULT_TITLE = 'Date';

// //////////////////////////////////////////
//              Role Status                //
// //////////////////////////////////////////
export const ROLE_STATUS_REMOVED = 'Removed';
export const ROLE_STATUS_REMOVED_ID = 3;

// //////////////////////////////////////////
//              REGEX                 //
// //////////////////////////////////////////
export const regexForEmail = /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const reqularExpRate = /^((?!0)|(0\.))\d{1,9}(\,\d{1,9}){0,3}?(\.\d{1,5})?$/;

// //////////////////////////////////////////
//              Milestone                 //
// //////////////////////////////////////////
export const MONTH_AMOUNT_FOR_FIVE_YEARS = 60;
export const MILESTONE_INVOICE_AMOUNT_COLUMN_NAME = 'Invoice Amount';
export const MILESTONE_INVOICED_DATE_COLUMN_NAME = 'Invoiced Date';
export const MILESTONE_REMAINING_BALANCE_COLUMN_NAME = 'Delta to Goal';
export const MILESTONE_APPROVED_COLUMN_NAME = 'Approved';
export const MILESTONE_BACKUP_USED_COLUMN_NAME = 'Backup used';
export const MILESTONE_REVENUE_RECEIVED_COLUMN_NAME = 'Revenue Received';

// //////////////////////////////////////////
//              REPORTS                    //
// /////////////////////////////////////////
export const emptyArray = [];

export const fieldDate = 'Date';

export const TOTAL_PERCENT_FOR_RENEWAL_STATUSES = 100;
export const DATES_CROSSING_MESSAGE = 'Some dates are crossing';

export const ACCEPTED_STATUS_PATCH = 202;

// //////////////////////////////////////////
//          Team Composition Report        //
// //////////////////////////////////////////
export const TEAM_COMPOSITION_PROJECT_BASED = 1;
export const TEAM_COMPOSITION_TEAM_BASED = 2;

// //////////////////////////////////////////
//              ASSIGNMENT STATUSES        //
// //////////////////////////////////////////
export const CERTIFICATION_STATUS_ACTIVE = 'Active';
export const CERTIFICATION_STATUS_EXPIRED = 'Expired';
export const CERTIFICATION_STATUS_IN_PROGRESS = 'In Progress';
export const CERTIFICATION_STATUS_PERPETUAL = 'Perpetual';
export const CERTIFICATION_STATUS_ACTIVE_ID = 1;
export const CERTIFICATION_STATUS_EXPIRED_ID = 2;
export const CERTIFICATION_STATUS_IN_PROGRESS_ID = 3;
export const CERTIFICATION_STATUS_PERPETUAL_ID = 4;

// //////////////////////////////////////////
//              Employee Departments       //
// //////////////////////////////////////////
export const EMPLOYEE_DEPARTMENT_TECHNICAL_PRODUCTION_ID = 'a23fc11b-d542-483e-80c6-87ba8fe40346';
export const EMPLOYEE_DEPARTMENT_DELIVERY_MANAGERS_ID = 'eb308239-f8a1-440f-abad-7a45d79f5123';
export const EMPLOYEE_DEPARTMENT_SALES_AND_MARKETING_ID = '0dfb9135-8f5f-4632-b5cd-92d802202b4f';
export const EMPLOYEE_DEPARTMENT_RECRUITERS_ID = '3b984096-c53c-44b2-91a4-4127711b3af2';
export const EMPLOYEE_DEPARTMENT_HRS_ID = '0ce93201-32e0-4ffe-b087-5480f61aa413';
export const EMPLOYEE_DEPARTMENT_ADMIN_STAFF_ID = '320ce362-a2a7-49dc-b3a1-94115ad02547';
export const EMPLOYEE_DEPARTMENT_TRAINEES_ID = 'e494fdf4-61e2-4fe8-a188-0676da2485a6';
