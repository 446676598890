import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import {
  addBackgroundCheck,
  addCertificate,
  addEmployeeTraining,
  deleteBackgroundCheck,
  deleteCertificate,
  deleteEmployeeSkill,
  deleteEmployeeTraining,
  deleteResume,
  editBackgroundCheck,
  editCertificate,
  editEmployeeTraining,
  editVacation,
  getAllBackFillTypes,
  getBackgroundChecks,
  getCanEditBackgroundChecks,
  getCanEditEmployeeProjectInfo,
  getEmployeeBusinessLocations,
  getEmployeeDetails,
  getEmployeeDetailsCertificates,
  getEmployeeDetailsProjects,
  getEmployeeDetailsResume,
  getEmployeeDetailsSkills,
  getEmployeeHrManagers,
  getEmployeeProjectInfo,
  getEmployeeResourceManagers,
  getEmployeeTrainings,
  getHasBackFill,
  getShouldRestrictEmployeeInfo,
  hideProjectInfo,
  loadEmployeeHolidays,
  postNewEmployeeResume,
  putEmployeeResume,
  putEmployeeSkills,
  resetEmployeeDetails,
  setBackgroundChecks,
  setEmployeeTrainings,
  setListSettings,
  showAddVacationModal,
  showSkillsModal,
  updateEmployeeProjectInfo,
} from '../../actions/membersActions';
import { getFilters } from 'common/actions/filtersActions.js';
import { getSelectedTab } from '../../../../common/actions/selectedTabActions';
import { showAlertDialog, showDialog } from 'common/actions/confirmationDialogActions';
import Tabs from 'common/components/tabs/tabs';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import { getStatusColor } from 'services/getStatusColor';
import history from 'utils/history';
import { getId } from 'services/location';
import Vacations from './vacations/vacations';
import DocumentList from 'common/components/documents-list/documents-list';
import { UpdateDocumentItem } from 'common/components/documents-list/update-document-item';
import { recursiveSearch } from 'services/recursiveSearch';
import { sortList, sortProjectsForEmployee } from 'common/components/list/sortList';
import sortArrayByPriority from 'utils/sortArrayByPriority';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { CellLinkForRedirect } from 'common/components/list/custom-cell/cell-link-for-redirect';
import { CellSelect } from 'common/components/list/custom-cell/cell-employee-level';
import { CellTextField } from 'common/components/list/custom-cell/cell-toogle-textfield';
import { CellRate } from 'common/components/list/custom-cell/cell-rate';
import { CellDatePicker } from 'common/components/list/custom-cell/cell-toogle-datepicker';
import { Badge } from 'common/components/badges/badges';
import { getColorFromString } from 'services/getColor';
import { getDeleteConfig } from 'utils/getDeleteConfig.js';
import EmployeeBasicInfoForm from 'common/components/redux-form/employee-basic-info-form/employee-basic-info-form';
import { formHandleSubmit } from 'utils/formHandleSubmit';
import sortByAplhabet from 'utils/sortByAplhabet';
import CertificationModal from './certification-modal';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog';
import { resetComponent, setComponent } from 'common/actions/headerActions';
import { PROJECT_ROLES_STATUS_ACTIVE, PROJECT_ROLES_STATUS_REMOVED } from 'utils/const-variable';
import Select from 'common/components/form-items/select/select';
import DatePicker from 'common/components/form-items/date-picker/date-picker';
import TextField from 'common/components/form-items/input/text-field';

import './member-details.css';
import { CellLabel } from '../../../../common/components/list/custom-cell/cell-label';
import classNames from 'classnames';
import CustomIcon from '../../../../common/components/icon/Icon';
import moment from 'moment';
import {
  addBusinessLocation, addHoliday, deleteHoliday, updateHoliday,
} from '../../../offices/actions/officesActions';
import BackgroundCheckModal from './background-check-modal';
import EmployeeTrainingModal from './employee-training-modal';
import ProjectInfoModal from './project-info-modal';

const SKILLS_TAB_INDEX = 0;
const PROJECTS_TAB_INDEX = 1;
const TRAININGS_TAB_INDEX = 2;
const PROJECT_INFO_TAB_INDEX = 3;
const HOLIDAYS_TAB_INDEX = 4;
const VACATIONS_TAB_INDEX = 5;
const RESUME_TAB_INDEX = 6;
const CERTIFICATES_TAB_INDEX = 7;
const BACKGROUND_CHECKS_TAB_INDEX = 8;
const FIRST_MONTH_ON_YEAR = 0;
const LAST_MONTH_ON_YEAR = 11;
const FIRST_DAY_OF_MONTH = 1;
const LAST_DAY_OF_MONTH = 31;
const ACTIVE_PROJECT_INFO_INDEX = 0;
const FINISHED_PROJECT_INFO_INDEX = 1;

const fullTabsList = ['Skills', 'Projects', 'Trainings', 'Project Info', 'Holidays', 'Vacations', 'Resume', 'Certificates', 'Background Checks'];
const tabsListWithoutProjectInfo = ['Skills', 'Projects', 'Trainings', 'Holidays', 'Vacations', 'Resume', 'Certificates', 'Background Checks'];
const restrictedTabsList = ['Trainings', 'Background Checks'];

export class EmployeeDetails extends React.Component {
  constructor(props) {
    super(props);
    const { selectedTab } = this.props;
    this.state = {
      selectedTab,
      employee: {},
      resume: [],
      addResume: {},
      showAddResumeFields: false,
      blockAddResume: false,
      blockSaveResume: false,
      showPopOverForCell: false,
      columnsToDisplay: [
        // SKILLS
        [
          {
            Name: 'Level', Id: 'Level', className: 'medium-col', pressArrowLeft: true,
          },
          {
            Name: 'Skill', Id: 'Skill', className: 'without-width-col', pressArrowLeft: true,
          },
        ],
        // PROJECTS
        [
          {
            Name: 'Company', Id: 'Client',
          },
          {
            Name: 'Project Name', Id: 'ProjectName', className: 'big-col',
          },
          {
            Name: 'Team', Id: 'Team', isArray: true,
          },
          {
            Name: 'Role Type', Id: 'ExpertRole', isObject: true,
          },
          {
            Name: 'Assignment', Id: 'Allocation',
          },
          {
            Name: 'Ass.Type', Id: 'AssignmentType', isObject: true, deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Billable Status', Id: 'PositionBillableStatus',
          },
          {
            Name: 'Rate', Id: 'Rate', isBudget: true,
          },
          {
            Name: 'SOW/PO Number', Id: 'Contract', isObject: true, className: 'medium-col',
          },
          {
            Name: 'Start Date', Id: 'StartDate', isDate: true,
          },
          {
            Name: 'End Date', Id: 'EndDate', isDate: true,
          },
          {
            Name: 'Status', Id: 'AssignmentStatus', isObject: false,
          },
        ],
        // TRAININGS
        [
          {
            Name: 'Clients', Id: 'Clients', className: 'big-col', isArray: true, isTrainingClient: true,
          },
          {
            Name: 'Training', Id: 'Training', className: 'big-col', isObject: true,
          },
          {
            Name: 'Date of Passing', Id: 'DateOfPassing', className: 'without-width-col', isDate: true, isTrainingDate: true,
          },
        ],
        // PROJECT INFOS
        [
          // Active Project Info
          [
            {
              Name: 'Active', Id: 'Client', className: 'big-col', isObject: true,
            },
            {
              Name: 'Contract Types', Id: 'ContractTypes', className: 'big-col', isArray: true,
            },
            {
              Name: 'Alias', Id: 'Alias', className: 'medium-col',
            },
            {
              Name: 'Account Join Date', Id: 'Date', className: 'big-col', isDate: true,
            },
            {
              Name: 'Max Account Date', Id: 'RestrictionDate', className: 'without-width-col', isDate: true,
            },
          ],
          // Finished Project Info
          [
            {
              Name: 'Finished', Id: 'Client', className: 'big-col', isObject: true,
            },
            {
              Name: 'Contract Types', Id: 'ContractTypes', className: 'big-col', isArray: true,
            },
            {
              Name: 'Alias', Id: 'Alias', className: 'medium-col',
            },
            {
              Name: 'Account Left Date', Id: 'Date', className: 'big-col', isDate: true,
            },
            {
              Name: 'Min Account Renewal Date', Id: 'RestrictionDate', className: 'without-width-col', isDate: true,
            },
          ],
        ],
        // HOLIDAYS
        [
          {
            Name: 'Date', Id: 'Date', className: 'big-col', deleteArrow: 'delete-arrow',
          },
          {
            Name: 'Description', Id: 'Description', className: 'without-width-col', deleteArrow: 'delete-arrow',
          },
        ],
        // VACATIONS
        [],
        // RESUMES
        [
          {
            Name: 'Documents Links', isSelected: true, Id: 'URL', className: 'without-width-col', pressArrowLeft: true,
          },
        ],
        // CERTIFICATES
        [
          {
            Name: 'Certification type', Id: 'Title', className: 'biggest-col', isObject: true,
          },
          {
            Name: 'Certification specialization', Id: 'CertificationSpec', className: 'big-col',
          },
          {
            Name: 'Certification requester', Id: 'CertificationRequester', className: 'big-col', isObject: true,
          },
          {
            Name: 'Certificate since', Id: 'CertificateDate', className: 'big-col', isDate: true,
          },
          {
            Name: 'Expiration date', Id: 'ExpirationDate', className: 'big-col', isDate: true,
          },
          {
            Name: 'Certification link', Id: 'CertificationLink', className: 'big-col',
          },
          {
            Name: 'Status', Id: 'Status', className: 'small-col',
          },
        ],
        // BACKGROUND CHECKS
        [
          {
            Name: 'Date', Id: 'Date', className: 'big-col', isDate: true,
          },
          {
            Name: 'Expiration date', Id: 'ExpirationDate', className: 'big-col', isDate: true, isNull: true, isBackgroundCheck: true,
          },
          {
            Name: 'BGC Request', Id: 'Request', className: 'without-width-col',
          },
          {
            Name: 'Status', Id: 'Status', className: 'small-col', isObject: false,
          },
        ],
      ],
      isOpenDropdown: {
        Skills: {},
        Resume: {},
      },
      highlightSkype: false,
      editSkils: {},
      showAddCertificateModal: false,
      isEditCertificateMode: false,
      editCertificateObject: {},
      showDeleteCertificateDialog: false,
      deleteCertificateId: '',
      showAddHoliday: false,
      isValidAddHoliday: false,
      yearForTheHolidays: moment().format('YYYY'),
      years: [],
      newHolidayDate: null,
      newHolidayDescription: '',
      showAddBackgroundCheckModal: false,
      isEditBackgroundCheckMode: false,
      editBackgroundCheckObject: {},
      showDeleteBackgroundCheckDialog: false,
      deleteBackgroundCheckId: '',
      showAddEmployeeTrainingModal: false,
      isEditEmployeeTrainingMode: false,
      editEmployeeTrainingObject: {},
      showDeleteEmployeeTrainingDialog: false,
      deleteEmployeeTrainingId: '',
      showProjectInfoModal: false,
      editProjectInfoObject: {},
      firstUpdate: true,
      isFormError: false,
      confirmToHideProjectInfo: false,
      projectInfoToDelete: null,
      hideProjectInfoDialogTitle: '',
      projectInfoListsShouldUpdate: false,
    };
    this.skilsDeleteConfig = getDeleteConfig('employees/main-skills/', 'employees/skills');
  }

  componentDidMount() {
    const {
      getEmployeeDetails, setComponent, resetDefaultFilters, loadEmployeeHolidays, getEmployeeBusinessLocations,
      getEmployeeResourceManagers, getEmployeeHrManagers, getEmployeeProjectInfo, getShouldRestrictEmployeeInfo,
      getHasBackFill, getAllBackFillTypes,
    } = this.props;
    const { yearForTheHolidays } = this.state;
    const year = yearForTheHolidays ? yearForTheHolidays : moment().year();
    getEmployeeDetails(getId());
    loadEmployeeHolidays(getId(), year);
    getEmployeeBusinessLocations(getId());
    getEmployeeResourceManagers(getId());
    getEmployeeHrManagers(getId());
    getEmployeeProjectInfo(getId());
    getShouldRestrictEmployeeInfo(getId());
    getHasBackFill(getId());
    getAllBackFillTypes();
    this.fetchSelectedTabData();
    this.setOptionYears();
    setComponent({ returnButton: { text: 'Employees', goToPreviousPage: this.goToEmployeesList } });
    resetDefaultFilters();
  }

  componentDidUpdate(prevProps) {
    const { firstUpdate, projectInfoListsShouldUpdate } = this.state;
    const {
      employeeDetails, selectedTab, getEmployeeHrManagers, getEmployeeResourceManagers,
      shouldRestrictEmployeeInfo, getSelectedTab, projectInfo,
    } = this.props;
    if (prevProps.employeeDetails !== employeeDetails) {
      this.checkRightsToEditBackgroundChecks();
      this.checkRightsToEditProjectInfo();
      this.setState({
        employee: {
          Id: employeeDetails.Id,
          Name: employeeDetails.Name,
        },
        resume: employeeDetails.Documents,
      });
    }

    if (prevProps.selectedTab !== selectedTab) {
      this.fetchSelectedTabData();

      this.setState({
        selectedTab,
      });
    }

    if (firstUpdate) {
      getSelectedTab(shouldRestrictEmployeeInfo ? TRAININGS_TAB_INDEX : selectedTab);
    }

    if ((prevProps.employeeDetails.Office && prevProps.employeeDetails.Office.Id) !== (employeeDetails.Office && employeeDetails.Office.Id)) {
      getEmployeeHrManagers(getId());
      getEmployeeResourceManagers(getId());
    }

    if (prevProps.projectInfo !== projectInfo) {
      this.setState({
        projectInfoListsShouldUpdate: true,
      });
    } else if (prevProps.projectInfo === projectInfo && projectInfoListsShouldUpdate) {
      this.setState({
        projectInfoListsShouldUpdate: false,
      });
    }
  }

  componentWillUnmount() {
    const { resetComponent, resetEmployeeDetails } = this.props;
    resetComponent();
    resetEmployeeDetails();
  }

  checkRightsToEditBackgroundChecks = () => {
    const { getCanEditBackgroundChecks } = this.props;

    getCanEditBackgroundChecks(getId());
  }

  checkRightsToEditProjectInfo = () => {
    const { getCanEditEmployeeProjectInfo } = this.props;

    getCanEditEmployeeProjectInfo(getId());
  }

  fetchSelectedTabData = () => {
    const {
      getEmployeeDetailsResume,
      getEmployeeDetailsProjects,
      getEmployeeDetailsSkills,
      getEmployeeDetailsCertificates,
      getBackgroundChecks,
      getEmployeeTrainings,
      getEmployeeProjectInfo,
      selectedTab,
    } = this.props;

    switch (selectedTab) {
      case RESUME_TAB_INDEX:
        getEmployeeDetailsResume(getId());
        break;
      case PROJECTS_TAB_INDEX:
        getEmployeeDetailsProjects(getId());
        break;
      case PROJECT_INFO_TAB_INDEX:
        this.checkRightsToEditProjectInfo();
        getEmployeeProjectInfo(getId());
        break;
      case SKILLS_TAB_INDEX:
        getEmployeeDetailsSkills(getId());
        break;
      case CERTIFICATES_TAB_INDEX:
        getEmployeeDetailsCertificates(getId());
        break;
      case BACKGROUND_CHECKS_TAB_INDEX:
        this.checkRightsToEditBackgroundChecks();
        getBackgroundChecks(getId());
        break;
      case TRAININGS_TAB_INDEX:
        this.checkRightsToEditBackgroundChecks();
        getEmployeeTrainings(getId());
        break;
      default:
        break;
    }
  }

  goEditProject = (id) => {
    return `/projects/${id}`;
  }

  onTab = (selectedTab) => {
    const { getSelectedTab } = this.props;
    getSelectedTab(selectedTab);
  }

  addNewResume = () => {
    const { blockAddResume } = this.state;
    const { employeeDetails } = this.props;
    if (!blockAddResume) {
      this.setState({
        showAddResumeFields: true,
        addResume: { EmployeeId: employeeDetails.Id, URL: '', Comment: '' },
        blockAddResume: true,
      });
    }
  }

  getNewResumeObj = ({ target }) => {
    const { addResume } = this.state;
    this.setState({
      addResume: {
        ...addResume,
        [target.name]: target.value,
      },
    }, this.newResumeValidation);
  }

  newResumeApply = () => {
    const { postNewEmployeeResume } = this.props;
    const { blockSaveResume, addResume } = this.state;
    if (blockSaveResume) {
      postNewEmployeeResume(addResume);
      this.setState({
        showAddResumeFields: false,
        blockAddResume: false,
        blockSaveResume: false,
        addResume: {},
      });
    }
  }

  newResumeCancel = () => {
    const { employeeDetails } = this.props;
    this.setState({
      addResume: { EmployeeId: employeeDetails.Id, URL: '', Comment: '' },
      showAddResumeFields: false,
      blockSaveResume: false,
      blockAddResume: false,
    });
  }

  newResumeValidation = () => {
    const { addResume } = this.state;
    if (addResume.URL !== '' && addResume.Comment !== '') {
      this.setState({
        blockSaveResume: true,
      });
    } else {
      this.setState({
        blockSaveResume: false,
      });
    }
  };

  updateEmployeeResume = (resume) => {
    const { putEmployeeResume } = this.props;
    const { employee } = this.state;
    const updateObj = { EmployeeId: employee.Id, resume };
    putEmployeeResume(updateObj);
  }

  putEmployeeSkills = (skill) => {
    const { putEmployeeSkills } = this.props;
    putEmployeeSkills(getId(), skill);
  };

  setSearchValue = (tabName) => (searchValue) => {
    const { setListSettings, listSettings } = this.props;
    setListSettings({
      detailsTabsState: {
        ...listSettings.detailsTabsState,
        [tabName]: {
          ...listSettings.detailsTabsState[tabName],
          searchValue,
        },
      },
    });
  };

  setSortSettings = (tabName) => (sortColumn, sortDirection) => {
    const { setListSettings, listSettings } = this.props;
    setListSettings({
      detailsTabsState: {
        ...listSettings.detailsTabsState,
        [tabName]: {
          ...listSettings.detailsTabsState[tabName],
          sortColumn,
          sortDirection,
        },
      },
    });
  }

  getEmployeePositions = (positions) => {
    return positions.map((item, i) => {
      if (item.Position && item.Status === PROJECT_ROLES_STATUS_ACTIVE) {
        return (<span className='item-details__tag' key={i}>
          {this.getEmployeePositionString(item)}
        </span>);
      }
      return false;
    });
  }

  getEmployeePositionString = (position) => {
    const roleName = position.RoleName ? `${position.RoleName} - ` : '';
    const newString = roleName + position.Position + ' / ' + position.Project + ` ${position.Assignment * 100}%`;
    return newString;
  }

  setEmployeeLevel = (item) => {
    this.putEmployeeSkills(item);
  }

  setEmployeeSkill = (item) => {
    const sendSkill = {
      Id: item.Id,
      Level: item.Level && item.Level.Id,
      Skill: item.Skill,
    };
    this.putEmployeeSkills(sendSkill);
  }

  goToEmployeesList = () => {
    history.push({ pathname: '/employees' });
  }

  addCertificate = (certificateObject, isEdit) => {
    const { addCertificate, employeeDetails, editCertificate } = this.props;
    if (isEdit) {
      const { editCertificateObject } = this.state;
      editCertificate(employeeDetails.Id, editCertificateObject.Id, certificateObject);
    } else {
      addCertificate(employeeDetails.Id, certificateObject);
    }
  }

  addNewCertificate = () => {
    this.setState({
      showAddCertificateModal: true,
      isEditCertificateMode: false,
    });
  }

  closeCertificateModel = () => {
    this.setState({
      showAddCertificateModal: false,
      isEditCertificateMode: false,
    });
  }

  editCertificate = (data) => {
    this.setState({
      editCertificateObject: data.item,
      isEditCertificateMode: true,
    }, this.setState({
      showAddCertificateModal: true,
    }));
  }

  openDeleteCertificateDialog = (Id) => {
    this.setState({
      showDeleteCertificateDialog: true,
      deleteCertificateId: Id,
    });
  }

  closeDeleteCertificateDialog = () => {
    this.setState({
      showDeleteCertificateDialog: false,
      deleteCertificateId: '',
    });
  }

  confirmDeleteCertificateDialog = () => {
    const { deleteCertificate, employeeDetails } = this.props;
    const { deleteCertificateId } = this.state;
    deleteCertificate(employeeDetails.Id, deleteCertificateId);
    this.closeDeleteCertificateDialog();
  }

  addBackgroundCheck = (backgroundCheckObject, isEdit) => {
    const { addBackgroundCheck, employeeDetails, editBackgroundCheck } = this.props;
    if (isEdit) {
      const { editBackgroundCheckObject } = this.state;
      editBackgroundCheck(employeeDetails.Id, editBackgroundCheckObject.Id, backgroundCheckObject);
    } else {
      addBackgroundCheck(employeeDetails.Id, backgroundCheckObject);
    }
  }

  addNewBackgroundCheck = () => {
    this.setState({
      showAddBackgroundCheckModal: true,
      isEditBackgroundCheckMode: false,
    });
  }

  closeBackgroundCheckModal = () => {
    this.setState({
      showAddBackgroundCheckModal: false,
      isEditBackgroundCheckMode: false,
    });
  }

  editBackgroundCheck = (data) => {
    this.setState({
      editBackgroundCheckObject: data.item,
      isEditBackgroundCheckMode: true,
    }, this.setState({
      showAddBackgroundCheckModal: true,
    }));
  }

  openDeleteBackgroundCheckDialog = (id) => {
    this.setState({
      showDeleteBackgroundCheckDialog: true,
      deleteBackgroundCheckId: id,
    });
  }

  closeDeleteBackgroundCheckDialog = () => {
    this.setState({
      showDeleteBackgroundCheckDialog: false,
      deleteBackgroundCheckId: '',
    });
  }

  confirmDeleteBackgroundCheckDialog = () => {
    const { deleteBackgroundCheck, employeeDetails } = this.props;
    const { deleteBackgroundCheckId } = this.state;
    deleteBackgroundCheck(employeeDetails.Id, deleteBackgroundCheckId);
    this.closeDeleteBackgroundCheckDialog();
  }

  addEmployeeTraining = (employeeTrainingObject, isEdit) => {
    const { addEmployeeTraining, editEmployeeTraining } = this.props;
    const employeeTrainingObjectWithEmployeeId = {
      ...employeeTrainingObject,
      EmployeeId: getId(),
    };
    if (isEdit) {
      const { editEmployeeTrainingObject } = this.state;
      const updatedTraining = {
        DateOfPassing: employeeTrainingObject.DateOfPassing,
        IsNotApplicable: employeeTrainingObject.IsNotApplicable,
        EmployeeId: getId(),
      };
      editEmployeeTraining(editEmployeeTrainingObject.Id, updatedTraining);
    } else {
      addEmployeeTraining(employeeTrainingObjectWithEmployeeId);
    }
  }

  closeEmployeeTrainingModal = () => {
    this.setState({
      showAddEmployeeTrainingModal: false,
      isEditEmployeeTrainingMode: false,
    });
  }

  editEmployeeTraining = (data) => {
    this.setState({
      editEmployeeTrainingObject: data.item,
      isEditEmployeeTrainingMode: true,
    }, this.setState({
      showAddEmployeeTrainingModal: true,
    }));
  }

  openDeleteEmployeeTrainingDialog = (id) => {
    this.setState({
      showDeleteEmployeeTrainingDialog: true,
      deleteEmployeeTrainingId: id,
    });
  }

  closeDeleteEmployeeTrainingDialog = () => {
    this.setState({
      showDeleteEmployeeTrainingDialog: false,
      deleteEmployeeTrainingId: '',
    });
  }

  confirmDeleteEmployeeTrainingDialog = () => {
    const { deleteEmployeeTraining } = this.props;
    const { deleteEmployeeTrainingId } = this.state;
    deleteEmployeeTraining(deleteEmployeeTrainingId);
    this.closeDeleteEmployeeTrainingDialog();
  }

  updateEmployeeTrainingDates = (employeeTrainings) => {
    return employeeTrainings.map(training => {
      if (!training.DateOfPassing || training.DateOfPassing === '0001-01-01T00:00:00') {
        return {
          ...training,
          DateOfPassing: '',
        };
      }
      return training;
    });
  }

  updateHoliday = (item) => {
    const {
      updateHoliday, showAlertDialog, employeeDetails, loadEmployeeHolidays, addHoliday, holidays,
    } = this.props;
    const { yearForTheHolidays } = this.state;
    const holiday = holidays && holidays.Holidays && holidays.Holidays.find(h => h.Id === item.Id);

    if (holiday && moment(holiday.Date).format('L') === moment(item.Date).format('L') && holiday.Description === item.Description) {
      return;
    }

    if (!item.Description.length) {
      showAlertDialog('Description cannot be empty');
      loadEmployeeHolidays(getId(), yearForTheHolidays);
      return false;
    }

    const holidaysToCopy = holidays && holidays.Holidays && holidays.Holidays.filter(holiday => holiday.Id !== item.Id).map(holiday => ({
      Date: moment(holiday.Date).format('L'),
      Description: holiday.Description,
      EmployeeId: getId(),
      year: yearForTheHolidays,
      BusinessLocationId: employeeDetails.BusinessLocation.Id,
      IsOverridden: false,
    })) || [];

    const updateHolidayData = {
      Id: item.Id,
      Date: moment(item.Date).format('L'),
      Description: item.Description,
      LocationId: employeeDetails.BusinessLocation.Id,
      OfficeId: employeeDetails.Office.Id,
      Year: yearForTheHolidays,
      EmployeeId: getId(),
      IsOverridden: true,
    };
    if (item.Location && item.Location.Id === employeeDetails.BusinessLocation.Id && employeeDetails.BusinessLocation.IsPersonal) {
      updateHoliday(updateHolidayData);
    } else {
      updateHolidayData.Location = {
        Name: `${employeeDetails.BusinessLocation.Name}-Individual`,
        OfficeId: employeeDetails.Office.Id,
        Offices: employeeDetails.BusinessLocation.Offices.map(item => item.Id),
        EmployeeId: getId(),
        BaseBusinessLocationId: employeeDetails.BusinessLocation.Id,
        OfficeDaysOff: holidaysToCopy,
      };

      addHoliday(updateHolidayData);
    }
  }

  canEditHolidays = () => {
    const { ManageOfficeCalendar, isAdmin } = this.props;
    return ManageOfficeCalendar || isAdmin;
  }

  showAddNewHoliday = () => {
    this.setState({
      showAddHoliday: true,
    });
  }

  setOptionYears = () => {
    const years = [new Date().getFullYear()];
    for (let i = 1; i < 10; i++) {
      years.push(new Date().getFullYear() - i);
      years.push(new Date().getFullYear() + i);
    }
    this.setState({ years: years.sort() });
  }

  handleChangeYear = ({ target }) => {
    const { loadEmployeeHolidays } = this.props;
    this.setState({
      yearForTheHolidays: target.value,
    });
    loadEmployeeHolidays(getId(), target.value);
  }

  validateAddHoliday = () => {
    const { newHolidayDate, newHolidayDescription } = this.state;
    this.setState({
      isValidAddHoliday: !!(newHolidayDate && newHolidayDescription.length),
    });
  }

  onChangeNewCalendarDate = (date) => {
    this.setState({
      newHolidayDate: moment(date).format('L'),
    }, this.validateAddHoliday);
  }

  onChangeNewCalendarDescription = (event) => {
    this.setState({
      newHolidayDescription: event.target.value,
    }, this.validateAddHoliday);
  }

  cancelAddHoliday = () => {
    this.setState({
      showAddHoliday: false,
      newHolidayDate: null,
      newHolidayDescription: '',
      isValidAddHoliday: false,
    });
  }

  applyAddHoliday = () => {
    const {
      newHolidayDate, newHolidayDescription, isValidAddHoliday, yearForTheHolidays,
    } = this.state;
    const {
      addHoliday, employeeDetails, addBusinessLocation, loadEmployeeHolidays, holidays,
    } = this.props;

    const holidaysToCopy = holidays && holidays.Holidays && holidays.Holidays.map(holiday => ({
      Date: moment(holiday.Date).format('L'),
      Description: holiday.Description,
      EmployeeId: getId(),
      year: yearForTheHolidays,
      BusinessLocationId: employeeDetails.BusinessLocation.Id,
      IsOverridden: false,
    })) || [];

    if (isValidAddHoliday) {
      const newHolidayData = {
        Date: moment(newHolidayDate).format('L'),
        Description: newHolidayDescription,
        year: yearForTheHolidays,
        EmployeeId: getId(),
        IsOverridden: true,
      };
      if (employeeDetails.BusinessLocation && employeeDetails.BusinessLocation.IsPersonal) {
        newHolidayData.LocationId = employeeDetails.BusinessLocation.Id;
        newHolidayData.OfficeId = employeeDetails.Office.Id;
        addHoliday(newHolidayData);
      } else {
        newHolidayData.BusinessLocationId = employeeDetails.BusinessLocation.Id;
        const data = {
          Name: `${employeeDetails.BusinessLocation.Name}-Individual`,
          OfficeId: employeeDetails.Office.Id,
          Offices: [employeeDetails.Office.Id],
          OfficeDaysOff: [...holidaysToCopy, newHolidayData],
          EmployeeId: getId(),
          BaseBusinessLocationId: employeeDetails.BusinessLocation.Id,
          year: yearForTheHolidays,
        };

        addBusinessLocation(data);
      }

      loadEmployeeHolidays(getId(), yearForTheHolidays);
      this.cancelAddHoliday();
    }
  }

  showDeleteHolidayModal = ({ item }) => {
    const dialogTitle = `Please confirm you want to delete ${moment(item.Date).format('MM-DD')} ${item.Description}?`;

    this.setState({
      confirmToDelete: true,
      holidayToDelete: item,
      deleteDialogTitle: dialogTitle,
    });
  }

  closeDeleteHolidayModal = () => {
    this.setState({
      confirmToDelete: false,
      holidayToDelete: '',
      deleteDialogTitle: '',
    });
  }

  applyDeleteHoliday = () => {
    const {
      deleteHoliday, employeeDetails, holidays, addBusinessLocation,
    } = this.props;
    const { holidayToDelete, yearForTheHolidays } = this.state;

    const deleteHolidayData = {
      holiday: holidayToDelete,
      officeId: employeeDetails.Office.Id,
      year: yearForTheHolidays,
      EmployeeId: getId(),
    };

    if (employeeDetails && employeeDetails.BusinessLocation && employeeDetails.BusinessLocation.IsPersonal) {
      deleteHoliday(deleteHolidayData);
    } else {
      const holidaysToCopy = holidays
        && holidays.Holidays && holidays.Holidays.filter(holiday => holiday
          && holidayToDelete && holiday.Id !== holidayToDelete.Id)
        .map(holiday => ({
          Date: moment(holiday.Date).format('L'),
          Description: holiday.Description,
          EmployeeId: getId(),
          year: yearForTheHolidays,
          BusinessLocationId: employeeDetails.BusinessLocation.Id,
          IsOverridden: false,
        })) || [];

      const data = {
        Name: `${employeeDetails.BusinessLocation.Name}-Individual`,
        OfficeId: employeeDetails.Office.Id,
        Offices: [employeeDetails.Office.Id],
        OfficeDaysOff: [...holidaysToCopy],
        EmployeeId: getId(),
        BaseBusinessLocationId: employeeDetails.BusinessLocation.Id,
        year: yearForTheHolidays,
      };

      addBusinessLocation(data);
    }

    this.closeDeleteHolidayModal();
  }

  getTabsList = () => {
    const { projectInfo, shouldRestrictEmployeeInfo } = this.props;
    if (shouldRestrictEmployeeInfo) {
      return restrictedTabsList;
    }
    return projectInfo.length ? fullTabsList : tabsListWithoutProjectInfo;
  }

  closeProjectInfoModal = () => {
    this.setState({
      showProjectInfoModal: false,
    });
  }

  onProjectInfoEditClick = (data) => {
    const { projectInfo } = this.props;

    const projectInfoObject = projectInfo.find(item => item.Id === data);
    if (projectInfoObject) {
      this.setState({
        editProjectInfoObject: projectInfoObject,
        showProjectInfoModal: true,
      });
    }
  }

  editProjectInfo = (data) => {
    const { updateEmployeeProjectInfo } = this.props;
    const updatedProjectInfo = {
      Alias: data.Alias,
      Date: data.Date,
    };

    updateEmployeeProjectInfo(data.Id, getId(), updatedProjectInfo);
  }

  handleFormError = (isError) => {
    this.setState({
      isFormError: isError,
    });
  }

  showHideProjectInfoModal = ({ item }) => {
    const dialogTitle = 'Are you sure you want to delete this project information? This action cannot be undone using UI';

    this.setState({
      confirmToHideProjectInfo: true,
      projectInfoToDelete: item,
      hideProjectInfoDialogTitle: dialogTitle,
    });
  };

  closeHideProjectInfoModal = () => {
    this.setState({
      confirmToHideProjectInfo: false,
      projectInfoToDelete: null,
      hideProjectInfoDialogTitle: '',
    });
  };

  applyHideProjectInfo = () => {
    const { hideProjectInfo } = this.props;
    const { projectInfoToDelete } = this.state;

    hideProjectInfo(getId(), projectInfoToDelete.Id);

    this.closeHideProjectInfoModal();
  };

  checkIfProjectInfoIsDeletable = (item) => {
    const { projectInfo } = this.props;
    let deletableProjectInfos = [];

    // get all project infos, for similar clients
    // i.e. when we have few project infos for one client
    Object.values(projectInfo.reduce((acc, cur) => {
      const key = cur.Client.Id;
      if (acc[key]) {
        acc[key] = [...acc[key], cur.Id];
      } else {
        acc[key] = [cur.Id];
      }
      return acc;
    }, {})).forEach((element) => {
      if (element.length > 1) {
        deletableProjectInfos = [...deletableProjectInfos, ...element];
      }
    });

    return deletableProjectInfos.some(element => item.Id === element);
  }

  sortCertificates = (certificates, sortColumn, sortDirection) => {
    const sortProperties = {
      'Perpetual': 1,
      'Active': 2,
      'In Progress': 3,
      'Expired': 4,
    };
    let sortedCertificates = certificates;

    if (sortColumn === 'Status') {
      sortedCertificates = sortList(certificates, 'Title', sortDirection);

      sortedCertificates.sort((a, b) => {
        if (sortDirection === 'Up') {
          return sortProperties[a.Status] - sortProperties[b.Status];
        }
        return sortProperties[b.Status] - sortProperties[a.Status];
      });
    } else {
      sortedCertificates = sortList(certificates, sortColumn, sortDirection);
    }

    return sortedCertificates;
  };

  render() {
    const {
      selectedTab, columnsToDisplay, employee, resume, showAddResumeFields, showAddCertificateModal,
      blockSaveResume, addResume, isEditCertificateMode, editCertificateObject, showDeleteCertificateDialog, showAddHoliday,
      newHolidayDate, yearForTheHolidays, newHolidayDescription, isValidAddHoliday, years, deleteDialogTitle, confirmToDelete,
      showAddBackgroundCheckModal, isEditBackgroundCheckMode, editBackgroundCheckObject, showDeleteBackgroundCheckDialog,
      showAddEmployeeTrainingModal, isEditEmployeeTrainingMode, editEmployeeTrainingObject, showDeleteEmployeeTrainingDialog,
      showProjectInfoModal, editProjectInfoObject, isFormError, confirmToHideProjectInfo, hideProjectInfoDialogTitle,
      projectInfoListsShouldUpdate,
    } = this.state;
    const {
      listSettings, employeeDetails, showDialog, skillLevels, showSkillsModal, locations, isAdmin,
      businesstrips, jobtitles, employeeDepartments, statuses, offices, HasFinancialAccess, employeeCerteficates, certificatesOptions,
      EditEmployeeBasicInfo, ManageEmployeeSkills, ManageEmployeeVacations, ManageEmployeeResume, ManageEmployeeCertificates, holidays,
      backgroundChecks, canEditBackgroundChecks, UserOfficeId, ViewEmployeesAssignedToProject, employeeTrainings,
      projectInfo, canEditProjectInfo, shouldRestrictEmployeeInfo, hasBackFill, allBackFillTypes, certificationRequesterOptions,
    } = this.props;
    const {
      skills: skillsState,
      projects: projectsState,
      resume: resumeState,
      certificates: certificatesState,
      holidays: holidaysState,
      backgroundChecks: backgroundChecksState,
      trainings: employeeTrainingsState,
      activeProjectInfo: activeProjectInfoState,
      finishedProjectInfo: finishedProjectInfoState,
    } = listSettings.detailsTabsState;
    const activeProjectInfo = projectInfo.filter(item => item.Status.Name === PROJECT_ROLES_STATUS_ACTIVE);
    const finishedProjectInfo = projectInfo.filter(item => item.Status.Name === PROJECT_ROLES_STATUS_REMOVED);

    return !employeeDetails ? null : (
      <Scrollbars
        autoHide
        autoHideTimeout={300}
        hideTracksWhenNotNeeded
      >
        <div className='page-container'>
          {
            confirmToDelete && <ConfirmationDialog
              dialogHeader='DELETE'
              dialogTitle={deleteDialogTitle}
              closeButtonTitle='Cancel'
              confirmButtonTitle='Confirm'
              closeDialog={this.closeDeleteHolidayModal}
              confirmDialog={this.applyDeleteHoliday}
            />
          }
          {
            confirmToHideProjectInfo && <ConfirmationDialog
              dialogHeader='DELETE'
              dialogTitle={hideProjectInfoDialogTitle}
              closeButtonTitle='Cancel'
              confirmButtonTitle='Confirm'
              closeDialog={this.closeHideProjectInfoModal}
              confirmDialog={this.applyHideProjectInfo}
            />
          }
          <div className='employee-info'>
            <div className='employee-info__header'>
              <div className='employee-info__header__left'>
                <div className='new_employee_photo'>
                  <Badge
                    color={getColorFromString(employee.Name || '')}
                    item={employee.Name || ''}
                  />
                </div>
                <div className='employee-info__header__left__title'>
                  <div className='employee-info__header__name'>
                    <div className='employee-info__header__part'>
                      <span className='employee-title'>{employee.Name}</span>
                    </div>
                    {isFormError && <div className='employee-info__header__error'>
                      <span>Employee information wasn’t stored while all mandatory fields weren’t filled</span>
                    </div>}
                  </div>
                  <div className='employee-info__tags-container'>
                    {
                      employeeDetails.Positions && this.getEmployeePositions(employeeDetails.Positions)
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='employee-details'>
            <EmployeeBasicInfoForm
              data={employeeDetails}
              canEditCurrentEmployee={EditEmployeeBasicInfo}
              isAdmin={isAdmin}
              viewFinancialInfo={HasFinancialAccess}
              allOffices={sortByAplhabet(offices)}
              allEmployeeDepartments={sortByAplhabet(employeeDepartments)}
              allStatuses={sortByAplhabet(statuses)}
              allJobTitles={sortByAplhabet(jobtitles)}
              allBusinessTrip={sortByAplhabet(businesstrips)}
              allLocations={locations}
              handleSubmit={formHandleSubmit}
              userOfficeId={UserOfficeId}
              viewEmployeesAssignedToProject={ViewEmployeesAssignedToProject}
              shouldRestrictInformation={shouldRestrictEmployeeInfo}
              hasBackFill={hasBackFill}
              allBackFillTypes={allBackFillTypes}
              handleFormError={this.handleFormError}
            />
          </div>
          <Tabs
            className='employee-info__tabs'
            list={fullTabsList}
            listToDisplay={this.getTabsList()}
            selectedIndex={selectedTab}
            onTab={this.onTab}
          />
          {
            selectedTab === SKILLS_TAB_INDEX ? (
              <ListToolbar
                key={`ListToolbar-${SKILLS_TAB_INDEX}`}
                onSearchClick={this.setSearchValue('skills')}
                searchValue={skillsState.searchValue}
                showAddButton={ManageEmployeeSkills}
                showExportButton={false}
                detailsStyles
                onAddNew={showSkillsModal}
                searchPlaceholder='Search Skills'
              />
            ) : selectedTab === PROJECTS_TAB_INDEX ? (
              <ListToolbar
                key={`ListToolbar-${PROJECTS_TAB_INDEX}`}
                onSearchClick={this.setSearchValue('projects')}
                searchValue={projectsState.searchValue}
                showAddButton={false}
                showExportButton={false}
                detailsStyles
                onAddNew={this.showRoleModal}
                searchPlaceholder='Search Projects'
              />
            ) : selectedTab === HOLIDAYS_TAB_INDEX ? (
              <ListToolbar
                key={`ListToolbar-${HOLIDAYS_TAB_INDEX}`}
                onSearchClick={this.setSearchValue('holidays')}
                setSearchValue={holidaysState.searchValue}
                showAddButton={false}
                showExportButton={false}
                detailsStyles
                searchPlaceholder='Search Holidays'
              />
            ) : selectedTab === RESUME_TAB_INDEX ? (
              <ListToolbar
                key={`ListToolbar-${RESUME_TAB_INDEX}`}
                onSearchClick={this.setSearchValue('resume')}
                searchValue={resumeState.searchValue}
                showAddButton={ManageEmployeeResume}
                showExportButton={false}
                detailsStyles
                onAddNew={this.addNewResume}
                disabled={!ManageEmployeeResume}
                searchPlaceholder='Search Resume'
              />
            ) : selectedTab === CERTIFICATES_TAB_INDEX ? (
              <ListToolbar
                key={`ListToolbar-${CERTIFICATES_TAB_INDEX}`}
                onSearchClick={this.setSearchValue('certificate')}
                searchValue={certificatesState.search}
                showAddButton={ManageEmployeeCertificates}
                showExportButton={false}
                detailsStyles
                onAddNew={this.addNewCertificate}
                disabled={!ManageEmployeeCertificates}
                searchPlaceholder='Search Certificate'
              />
            ) : selectedTab === BACKGROUND_CHECKS_TAB_INDEX && canEditBackgroundChecks ? (
              <ListToolbar
                key={`ListToolbar-${BACKGROUND_CHECKS_TAB_INDEX}`}
                showAddButton={canEditBackgroundChecks}
                showExportButton={false}
                detailsStyles
                onAddNew={this.addNewBackgroundCheck}
                disabled={!canEditBackgroundChecks}
                searchField={false}
              />
            ) : null
          }
          {
            selectedTab === SKILLS_TAB_INDEX ? (
              <List
                key={`List-${SKILLS_TAB_INDEX}`}
                columnsToDisplay={columnsToDisplay[selectedTab]}
                items={employeeDetails.Skills
                  && recursiveSearch(
                    sortList(employeeDetails.Skills, skillsState.sortColumn, skillsState.sortDirection),
                    skillsState.searchValue,
                  )}
                setSortSettings={this.setSortSettings('skills')}
                sortColumnName={skillsState.sortColumn}
                sortDirection={skillsState.sortDirection}
                isHaveRights={ManageEmployeeSkills}
                hideAddNewEntity
                hideEntityEdit
                onDeleteClick={showDialog}
                deleteConfig={this.skilsDeleteConfig}
                config={{
                  'Level': {
                    cell: CellSelect,
                    valueSetter: this.setEmployeeLevel,
                    options: sortArrayByPriority(skillLevels),
                  },
                  'Skill': {
                    cell: CellTextField,
                    valueSetter: this.setEmployeeSkill,
                  },
                }}
              />
            ) : selectedTab === PROJECTS_TAB_INDEX ? (
              <List
                key={`List-${PROJECTS_TAB_INDEX}`}
                columnsToDisplay={columnsToDisplay[selectedTab]}
                items={employeeDetails.Projects
                  && recursiveSearch(
                    sortProjectsForEmployee(employeeDetails.Projects, projectsState.sortColumn, projectsState.sortDirection),
                    projectsState.searchValue,
                  )}
                setSortSettings={this.setSortSettings('projects')}
                sortColumnName={projectsState.sortColumn}
                sortDirection={projectsState.sortDirection}
                hideDots
                dontShowOptions
                transition={this.goEditProject}
                viewFinancialInfo={HasFinancialAccess}
                config={{
                  'AssignmentStatus': { cell: CustomStatusCell },
                  'ProjectName': { cell: CellLinkForRedirect },
                  'Rate': { cell: CellRate },
                }}
              />
            ) : selectedTab === PROJECT_INFO_TAB_INDEX ? (
              <div>
                {showProjectInfoModal && <ProjectInfoModal
                  closeDialog={this.closeProjectInfoModal}
                  confirmDialog={this.editProjectInfo}
                  editProjectInfoObject={editProjectInfoObject}
                />}
                <List
                  key={`List-${PROJECT_INFO_TAB_INDEX}-${ACTIVE_PROJECT_INFO_INDEX}`}
                  columnsToDisplay={columnsToDisplay[selectedTab][ACTIVE_PROJECT_INFO_INDEX]}
                  items={projectInfo && sortList(activeProjectInfo, activeProjectInfoState.sortColumn, activeProjectInfoState.sortDirection)}
                  setSortSettings={this.setSortSettings('activeProjectInfo')}
                  sortColumnName={activeProjectInfoState.sortColumn}
                  sortDirection={activeProjectInfoState.sortDirection}
                  onEditClick={this.onProjectInfoEditClick}
                  onDeleteClick={this.showHideProjectInfoModal}
                  hideDots={!canEditProjectInfo}
                  dontShowOptions={!canEditProjectInfo}
                  isHaveRights={canEditProjectInfo}
                  dontShowNoItemBlock
                  isIndividualDeletable
                  checkIfItemIsDeletable={this.checkIfProjectInfoIsDeletable}
                  shouldUpdate={projectInfoListsShouldUpdate}
                />
                <List
                  key={`List-${PROJECT_INFO_TAB_INDEX}-${FINISHED_PROJECT_INFO_INDEX}`}
                  columnsToDisplay={columnsToDisplay[selectedTab][FINISHED_PROJECT_INFO_INDEX]}
                  items={projectInfo && sortList(finishedProjectInfo, finishedProjectInfoState.sortColumn, finishedProjectInfoState.sortDirection)}
                  setSortSettings={this.setSortSettings('finishedProjectInfo')}
                  sortColumnName={finishedProjectInfoState.sortColumn}
                  sortDirection={finishedProjectInfoState.sortDirection}
                  onEditClick={this.onProjectInfoEditClick}
                  onDeleteClick={this.showHideProjectInfoModal}
                  hideDots={!canEditProjectInfo}
                  dontShowOptions={!canEditProjectInfo}
                  isHaveRights={canEditProjectInfo}
                  dontShowNoItemBlock
                  isIndividualDeletable
                  checkIfItemIsDeletable={this.checkIfProjectInfoIsDeletable}
                  shouldUpdate={projectInfoListsShouldUpdate}
                />
              </div>
            ) : selectedTab === HOLIDAYS_TAB_INDEX ? (
              <div>
                <div className='calendar-title__container'>
                  <Select
                    formClassName='vacation-year-selector calendar-office-select'
                    value={yearForTheHolidays}
                    selectOptions={years}
                    inputProps={{
                      name: 'yearForTheHolidays',
                      id: 'yearForTheHolidays',
                    }}
                    showClear={false}
                    onChange={this.handleChangeYear}
                  />
                  {
                    this.canEditHolidays() && <div
                      className={classNames('toolbar-button_office-details tolbar-button_ofice-calendar')}
                      onClick={this.showAddNewHoliday}
                    >
                      <CustomIcon iconName='plus-orange' />
                      <span>Add New Date</span>
                    </div>
                  }
                </div>
                {showAddHoliday && <div className='c-list__item-cont add-calendar--block'>
                  <div className='c-list__item-column big-col'>
                    <DatePicker
                      formClassName='cell-container_datepicker'
                      datePickerClassName='cell-container_datepicker add-calendar--datepicker'
                      placeholder='Choose Date'
                      value={newHolidayDate}
                      name='newHolidayDate'
                      onChange={this.onChangeNewCalendarDate}
                      minVal={new Date(yearForTheHolidays, FIRST_MONTH_ON_YEAR, FIRST_DAY_OF_MONTH)}
                      maxVal={new Date(yearForTheHolidays, LAST_MONTH_ON_YEAR, LAST_DAY_OF_MONTH)}
                    />
                  </div>
                  <div className='c-list__item-column c-skill__item without-width-col add-calendar--text-field-block'>
                    <TextField
                      formClassName='change-border__input-cell add-calendar--text-field'
                      inputClassName='change-name__input'
                      value={newHolidayDescription}
                      name='newHolidayDescription'
                      onChange={this.onChangeNewCalendarDescription}
                      maxlength='4000'
                      placeholder='Enter a description'
                    />
                  </div>
                  <div className='add-calendar--button-block'>
                    <div className='toolbar-button resume-buttom-cancel' onClick={this.cancelAddHoliday}>
                      <span>Cancel</span>
                    </div>
                    <div
                      className={classNames(
                        'button next resume-buttom-apply',
                        { 'btn-disabled': !isValidAddHoliday },
                      )}
                      onClick={this.applyAddHoliday}
                    >
                      <span>Apply</span>
                    </div>
                  </div>
                </div>}
                <List
                  key={`List-${HOLIDAYS_TAB_INDEX}`}
                  columnsToDisplay={columnsToDisplay[selectedTab]}
                  items={holidays && holidays.Holidays && (recursiveSearch(holidays.Holidays, holidaysState.searchValue))}
                  hideDots={!this.canEditHolidays}
                  hideEntityEdit
                  dontShowOptions
                  isHaveRights={this.canEditHolidays}
                  onDeleteClick={this.showDeleteHolidayModal}
                  hideDotsIfOverriden
                  config={{
                    'Location': { cell: CellLabel },
                    'Description': {
                      cell: CellTextField,
                      valueSetter: this.updateHoliday,
                      isEditable: employeeDetails
                        && employeeDetails.BusinessLocation
                        && employeeDetails.BusinessLocation.IsPersonal,
                    },
                    'Date': { cell: CellDatePicker, valueSetter: this.updateHoliday },
                  }}
                />
              </div>
            ) : selectedTab === VACATIONS_TAB_INDEX ? (
              <Vacations
                key='Vacations-2'
                employeeId={employee.Id}
                yearsToSelect={7} // number of years to render in select-box
                isEditable={ManageEmployeeVacations}
              />
            ) : selectedTab === RESUME_TAB_INDEX ? (
              <div>
                <DocumentList
                  key={`DocumentList-${RESUME_TAB_INDEX}`}
                  columnsToDisplay={columnsToDisplay[selectedTab]}
                  items={recursiveSearch(
                    sortList(resume, resumeState.sortColumn, resumeState.sortDirection),
                    resumeState.searchValue,
                  )}
                  handleStatusColor={getStatusColor}
                  actionLabel
                  onEditClick={this.updateEmployeeResume}
                  onDeleteClick={ManageEmployeeResume ? showDialog : null}
                  isEditable={ManageEmployeeResume}
                  setSortSettings={this.setSortSettings('resume')}
                  sortColumnName={resumeState.sortColumn}
                  sortDirection={resumeState.sortDirection}
                  showItemsEditMenu={ManageEmployeeResume}
                  viewFinancialInfo
                />
                {
                  showAddResumeFields ? <div className='c-list__item-cont c-tabs employee-info__tabs'>
                    <div className='c-list__item-column resume-member-inputs c-list__item-column'>
                      <UpdateDocumentItem
                        item={addResume}
                        onChangeNewDocumentObj={this.getNewResumeObj}
                        disableSaveDocument={blockSaveResume}
                        newDocumentCancel={this.newResumeCancel}
                        newDocumentApply={this.newResumeApply}
                      />
                    </div>
                  </div> : null
                }
              </div>
            ) : selectedTab === CERTIFICATES_TAB_INDEX ? (
              <>
                {showAddCertificateModal && <CertificationModal
                  closeDialog={this.closeCertificateModel}
                  certificatesOptions={certificatesOptions}
                  certificationRequesterOptions={certificationRequesterOptions}
                  confirmDialog={this.addCertificate}
                  isEditMode={isEditCertificateMode}
                  editCertificateObject={editCertificateObject}
                />}
                <List
                  key={`List-${CERTIFICATES_TAB_INDEX}`}
                  columnsToDisplay={columnsToDisplay[selectedTab]}
                  items={employeeCerteficates
                    && recursiveSearch(
                      this.sortCertificates(employeeCerteficates, certificatesState.sortColumn, certificatesState.sortDirection),
                      certificatesState.searchValue,
                    )}
                  setSortSettings={this.setSortSettings('certificates')}
                  sortColumnName={certificatesState.sortColumn}
                  sortDirection={certificatesState.sortDirection}
                  config={{
                    'Status': {
                      cell: CustomStatusCell,
                    },
                  }}
                  isHaveRights={ManageEmployeeCertificates}
                  hideAddNewEntity
                  getFullEditItem
                  onEditClick={this.editCertificate}
                  onDeleteClick={this.openDeleteCertificateDialog}
                  hideDots={!ManageEmployeeCertificates}
                  dontShowOptions={!ManageEmployeeCertificates}
                  dontUseDeleteConfig
                />
                {showDeleteCertificateDialog
                  && <ConfirmationDialog
                    dialogHeader='REMOVE CERTIFICATE'
                    dialogTitle='Are you sure to remove this item?'
                    closeButtonTitle='Cancel'
                    confirmButtonTitle='Accept'
                    closeDialog={this.closeDeleteCertificateDialog}
                    confirmDialog={this.confirmDeleteCertificateDialog}
                  />}
              </>
            ) : selectedTab === BACKGROUND_CHECKS_TAB_INDEX ? (
              <>
                {showAddBackgroundCheckModal && <BackgroundCheckModal
                  closeDialog={this.closeBackgroundCheckModal}
                  confirmDialog={this.addBackgroundCheck}
                  isEditMode={isEditBackgroundCheckMode}
                  editBackgroundCheckObject={editBackgroundCheckObject}
                />}
                <List
                  key={`List-${BACKGROUND_CHECKS_TAB_INDEX}`}
                  columnsToDisplay={columnsToDisplay[selectedTab]}
                  items={backgroundChecks
                    && sortList(backgroundChecks, backgroundChecksState.sortColumn, backgroundChecksState.sortDirection)}
                  setSortSettings={this.setSortSettings('backgroundChecks')}
                  sortColumnName={backgroundChecksState.sortColumn}
                  sortDirection={backgroundChecksState.sortDirection}
                  config={{
                    'Status': {
                      cell: CustomStatusCell,
                    },
                  }}
                  isHaveRights={canEditBackgroundChecks}
                  hideAddNewEntity
                  getFullEditItem
                  onEditClick={this.editBackgroundCheck}
                  onDeleteClick={this.openDeleteBackgroundCheckDialog}
                  hideDots={!canEditBackgroundChecks}
                  dontShowOptions={!canEditBackgroundChecks}
                  dontUseDeleteConfig
                />
                {showDeleteBackgroundCheckDialog
                 && <ConfirmationDialog
                   dialogHeader='REMOVE BACKGROUND CHECK'
                   dialogTitle='Are you sure to remove this item?'
                   closeButtonTitle='Cancel'
                   confirmButtonTitle='Accept'
                   closeDialog={this.closeDeleteBackgroundCheckDialog}
                   confirmDialog={this.confirmDeleteBackgroundCheckDialog}
                 />}
              </>
            ) : selectedTab === TRAININGS_TAB_INDEX ? (
              <>
                {showAddEmployeeTrainingModal && <EmployeeTrainingModal
                  closeDialog={this.closeEmployeeTrainingModal}
                  confirmDialog={this.addEmployeeTraining}
                  isEditMode={isEditEmployeeTrainingMode}
                  editEmployeeTrainingObject={editEmployeeTrainingObject}
                />}
                <List
                  key={`List-${TRAININGS_TAB_INDEX}`}
                  columnsToDisplay={columnsToDisplay[selectedTab]}
                  items={recursiveSearch(
                    sortList(
                      this.updateEmployeeTrainingDates(employeeTrainings),
                      employeeTrainingsState.sortColumn,
                      employeeTrainingsState.sortDirection,
                    ),
                    employeeTrainingsState.searchValue,
                  )}
                  setSortSettings={this.setSortSettings('trainings')}
                  sortColumnName={employeeTrainingsState.sortColumn}
                  sortDirection={employeeTrainingsState.sortDirection}
                  config={{
                    'Status': {
                      cell: CustomStatusCell,
                    },
                  }}
                  hideDeleteEntity
                  onDeleteClick={this.openDeleteEmployeeTrainingDialog}
                  hideAddNewEntity
                  getFullEditItem
                  onEditClick={this.editEmployeeTraining}
                  editSpan='Set Training Date'
                  isHaveRights
                  hideDots={!canEditBackgroundChecks}
                />
                {showDeleteEmployeeTrainingDialog
                 && <ConfirmationDialog
                   dialogHeader='REMOVE EMPLOYEE TRAINING'
                   dialogTitle='Are you sure to remove this item?'
                   closeButtonTitle='Cancel'
                   confirmButtonTitle='Accept'
                   closeDialog={this.closeDeleteEmployeeTrainingDialog}
                   confirmDialog={this.confirmDeleteEmployeeTrainingDialog}
                 />}
              </>
            ) : null
          }
        </div>
      </Scrollbars>
    );
  }
}

EmployeeDetails.propTypes = {
  employeeDetails: PropTypes.object,
  employeeDepartments: PropTypes.array,
  statuses: PropTypes.array,
  offices: PropTypes.array,
  businesstrips: PropTypes.array,
  locations: PropTypes.array,
  jobtitles: PropTypes.array,
  skillLevels: PropTypes.array,
  selectedTab: PropTypes.number,
  getEmployeeDetails: PropTypes.func,
  showSkillsModal: PropTypes.func,
  postNewEmployeeResume: PropTypes.func,
  putEmployeeResume: PropTypes.func,
  putEmployeeSkills: PropTypes.func,
  getSelectedTab: PropTypes.func,
  ManageEmployeeSkills: PropTypes.bool,
  EditEmployeeBasicInfo: PropTypes.bool,
  ManageEmployeeResume: PropTypes.bool,
  HasFinancialAccess: PropTypes.bool,
  ManageEmployeeVacations: PropTypes.bool,
  ManageEmployeeCertificates: PropTypes.bool,
  ManageOfficeCalendar: PropTypes.bool,
  listSettings: PropTypes.object,
};

export default connect(store => ({
  employeeDetails: store.membersReducer.employee,
  employeeDepartments: store.filtersReducer.filters.EmployeeDepartments,
  statuses: store.filtersReducer.filters.EmployeeStatusesNew,
  offices: store.filtersReducer.filters.Offices,
  businesstrips: store.filtersReducer.filters.BusinessTrips,
  locations: store.filtersReducer.filters.Locations,
  jobtitles: store.filtersReducer.filters.JobTitles,
  skillLevels: store.membersReducer.employeeSkillLevels,
  selectedTab: store.selectedTabReducer.selectedTab,
  UserOfficeId: store.authReducer.user.UserOfficeId,
  isAdmin: store.userRoleReducer.isAdmin,
  employeeCerteficates: store.membersReducer.employeeCertificates,
  certificatesOptions: store.filtersReducer.filters.Certificates,
  listSettings: store.membersReducer.listSettings,
  holidays: store.membersReducer.holidays,
  businessLocations: store.membersReducer.businessLocations,
  user: store.authReducer.user,
  backgroundChecks: store.membersReducer.backgroundChecks,
  canEditBackgroundChecks: store.membersReducer.canEditBackgroundChecks,
  employeeTrainings: store.membersReducer.employeeTrainings,
  projectInfo: store.membersReducer.projectInfo,
  canEditProjectInfo: store.membersReducer.canEditProjectInfo,
  shouldRestrictEmployeeInfo: store.membersReducer.shouldRestrictEmployeeInfo,
  hasBackFill: store.membersReducer.hasBackFill,
  allBackFillTypes: store.membersReducer.allBackFillTypes,
  certificationRequesterOptions: store.membersReducer.certificationRequesters,
}), {
  getEmployeeDetails,
  showSkillsModal,
  getFilters,
  postNewEmployeeResume,
  putEmployeeResume,
  deleteResume,
  putEmployeeSkills,
  deleteEmployeeSkill,
  getSelectedTab,
  showDialog,
  showAddVacationModal,
  editVacation,
  addCertificate,
  editCertificate,
  deleteCertificate,
  setComponent,
  resetComponent,
  getEmployeeDetailsResume,
  getEmployeeDetailsProjects,
  getEmployeeDetailsSkills,
  getEmployeeDetailsCertificates,
  resetEmployeeDetails,
  setListSettings,
  loadEmployeeHolidays,
  addHoliday,
  updateHoliday,
  deleteHoliday,
  addBusinessLocation,
  showAlertDialog,
  getEmployeeBusinessLocations,
  getEmployeeResourceManagers,
  getEmployeeHrManagers,
  getCanEditBackgroundChecks,
  getBackgroundChecks,
  setBackgroundChecks,
  addBackgroundCheck,
  editBackgroundCheck,
  deleteBackgroundCheck,
  getEmployeeTrainings,
  setEmployeeTrainings,
  addEmployeeTraining,
  editEmployeeTraining,
  deleteEmployeeTraining,
  getEmployeeProjectInfo,
  updateEmployeeProjectInfo,
  getCanEditEmployeeProjectInfo,
  getShouldRestrictEmployeeInfo,
  getHasBackFill,
  getAllBackFillTypes,
  hideProjectInfo,
})(EmployeeDetails);
