import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonOptions from 'common/components/form-items/button-options/button-options';
import ButtonImportPDF from 'common/components/form-items/button-import-pdf/button-import-pdf';
import CustomIcon from '../../icon/Icon';

export class ButtonsGroup extends Component {
  render() {
    const {
      showSyncButton,
      addExtend,
      showAddButton,
      showExportButton,
      showMargeButton,
      isActiveExtend,
      ADSync,
      extendRoles,
      exportDocument,
      onAddNew,
      mergeElements,
      blockAddButton,
      showTitleText,
      customAddButton,
      isActiveMerge,
      showButtonOptions,
      onDownloadFile,
      onImportFile,
      showImportSOWButton,
      customImportSOWButton,
      blockImportSOWButton,
      showAddMSAButton,
      customAddMSAButton,
      blockAddMSAButton,
      onAddMSA,
      onTrainingsSync,
    } = this.props;
    return (
      <div className='right-toolbar-part'>
        {showSyncButton && (
          <div className='sync-buttons-group'>
            <div className='toolbar-button' onClick={onTrainingsSync}>
              <CustomIcon iconName='trainingsSync' />
              <span>Sync Trainings</span>
            </div>
            <div className='toolbar-button' onClick={ADSync}>
              <CustomIcon iconName='sync' />
              <span>AD Sync</span>
            </div>
          </div>
        )}
        {addExtend && (
          <div
            className={
              isActiveExtend
                ? 'toolbar-button'
                : 'toolbar-button-disabled btn-disabled'
            }
            onClick={extendRoles}
            title={isActiveExtend ? null : 'Need to select role for extend'}
          >
            <CustomIcon iconName='add-extend' />
          </div>
        )}
        {showImportSOWButton && (
          <div
            className={classNames(
              blockImportSOWButton
                ? 'toolbar-button-disabled btn-disabled'
                : 'toolbar-button',
            )}
            title={
              blockImportSOWButton
                ? showTitleText
                  ? showTitleText
                  : null
                : undefined
            }
          >
            <ButtonImportPDF
              title={customImportSOWButton ? customImportSOWButton : 'Add New'}
              onChange={onImportFile}
              disabled={blockImportSOWButton}
            >
              { blockImportSOWButton ? <div className='loader-circle loader-import' /> : <CustomIcon iconName='files' /> }
            </ButtonImportPDF>
          </div>
        )}
        {showAddButton && (
          <div
            className={classNames(
              blockAddButton
                ? 'toolbar-button-disabled btn-disabled'
                : 'toolbar-button',
            )}
            title={
              blockAddButton
                ? showTitleText
                  ? showTitleText
                  : null
                : undefined
            }
            onClick={!blockAddButton ? onAddNew : undefined}
          >
            <CustomIcon iconName='plus-orange' />
            <span>{customAddButton ? customAddButton : 'Add New'}</span>
          </div>
        )}
        {showAddMSAButton && (
          <div
            className={classNames(
              blockAddMSAButton
                ? 'toolbar-button-disabled btn-disabled'
                : 'toolbar-button',
            )}
            onClick={!blockAddMSAButton ? onAddMSA : undefined}
          >
            <CustomIcon iconName='plus-orange' />
            <span>{customAddMSAButton ? customAddMSAButton : 'Add New MSA'}</span>
          </div>
        )}
        {showExportButton && (
          <div className='toolbar-button' onClick={exportDocument}>
            <CustomIcon iconName='export' />
            <span>Export</span>
          </div>
        )}
        {showMargeButton && (
          <div
            className={classNames('toolbar-button', {
              'toolbar-button-disabled btn-disabled': !isActiveMerge,
            })}
            onClick={mergeElements}
          >
            <CustomIcon iconName='export' />
            <span>Merge</span>
          </div>
        )}
        {showButtonOptions && (
          <ButtonOptions
            styles='toolbar-button'
            onImportFile={onImportFile}
            onDownloadFile={onDownloadFile}
          />
        )}
      </div>
    );
  }
}

ButtonsGroup.propTypes = {
  showSyncButton: PropTypes.bool,
  showImportSOWButton: PropTypes.bool,
  addExtend: PropTypes.bool,
  showAddButton: PropTypes.bool,
  showExportButton: PropTypes.bool,
  showMargeButton: PropTypes.bool,
  isActiveExtend: PropTypes.bool,
  blockAddButton: PropTypes.bool,
  blockImportSOWButton: PropTypes.bool,
  isActiveMerge: PropTypes.bool,
  ADSync: PropTypes.func,
  extendRoles: PropTypes.func,
  onAddNew: PropTypes.func,
  exportDocument: PropTypes.func,
  showTitleText: PropTypes.string,
  customAddButton: PropTypes.string,
  customImportSOWButton: PropTypes.string,
  mergeElements: PropTypes.func,
};
