const DEFAULT_GROUPS_PERMISSIONS = {
  APIAccess: {
    HasSiteAccess: true,
  },
  HR: {
    HasSiteAccess: true,
    IsNotificationsEnable: true,
    ManageClientContact: true,
    EditEmployeeBasicInfo: true,
    ManageEmployeeSkills: true,
    ManageEmployeeVacations: true,
    ManageEmployeeResume: true,
    CanSyncData: true,
    ManageProjectRoleAssignments: true,
    ManageEmployeeCertificates: true,
  },
  AccountManager: {
    HasSiteAccess: true,
    HasFinancialAccess: true,
    IsNotificationsEnable: true,
    CanCreateClient: true,
    EditClientBasicInfo: true,
    ManageClientContact: true,
    CanCreateProject: true,
    EditProjectBasicInfo: true,
    EditProjectBasicFinanceInfo: true,
    ManageProjectContracts: true,
    ManageProjectRoles: true,
    ManageProjectRoleAssignments: true,
  },
  EngineeringManager: {
    HasSiteAccess: true,
    IsNotificationsEnable: true,
    ManageClientContact: true,
    ManageProjectRoleAssignments: true,
    ManageEmployeeSkills: true,
    ManageEmployeeVacations: true,
    ManageEmployeeCertificates: true,
    ViewEmployeesAssignedToProject: true,
  },
  OfficeDirector: {
    HasSiteAccess: true,
    HasFinancialAccess: true,
    IsNotificationsEnable: true,
    ManageClientContact: true,
    EditOfficeBasicInfo: true,
    ManageOfficeCalendar: true,
    ManageOfficeLdapPath: true,
    EditEmployeeBasicInfo: true,
    ManageEmployeeSkills: true,
    ManageEmployeeVacations: true,
    ManageEmployeeResume: true,
    CanSyncData: true,
    CanCreateProject: true,
    EditProjectBasicInfo: true,
    EditProjectBasicFinanceInfo: true,
    ManageProjectContracts: true,
    ManageProjectRoles: true,
    ManageProjectRoleAssignments: true,
    ManageEmployeeCertificates: true,
  },
  DeliveryManager: {
    HasSiteAccess: true,
    HasFinancialAccess: true,
    IsNotificationsEnable: true,
    CanCreateClient: true,
    EditClientBasicInfo: true,
    ManageClientContact: true,
    CanCreateProject: true,
    EditProjectBasicInfo: true,
    EditProjectBasicFinanceInfo: true,
    ManageProjectContracts: true,
    ManageProjectRoles: true,
    ManageProjectRoleAssignments: true,
    ManageEmployeeCertificates: true,
    ViewEmployeesAssignedToProject: true,
  },
  FinanceManager: {
    HasSiteAccess: true,
    HasFinancialAccess: true,
    IsNotificationsEnable: true,
    CanCreateClient: true,
    EditClientBasicInfo: true,
    ManageClientContact: true,
    EditProjectBasicFinanceInfo: true,
    ManageProjectContracts: true,
  },
  Admin: {
    HasSiteAccess: true,
    HasFinancialAccess: true,
    IsNotificationsEnable: true,
    AddToCCInNotifications: true,
    CanCreateClient: true,
    EditClientBasicInfo: true,
    ManageClientContact: true,
    EditOfficeBasicInfo: true,
    ManageOfficeCalendar: true,
    ManageOfficeLdapPath: true,
    EditEmployeeBasicInfo: true,
    ManageEmployeeSkills: true,
    ManageEmployeeVacations: true,
    ManageEmployeeResume: true,
    CanSyncData: true,
    CanCreateProject: true,
    EditProjectBasicInfo: true,
    EditProjectBasicFinanceInfo: true,
    ManageProjectContracts: true,
    ManageProjectRoles: true,
    ManageProjectRoleAssignments: true,
    ManageEmployeeCertificates: true,
    ViewEmployeesAssignedToProject: true,
  },
  Accountant: {
    HasSiteAccess: true,
    HasFinancialAccess: true,
    IsNotificationsEnable: true,
    ReadBillings: true,
    ManageBillings: true,
    SyncMinioStorage: true,
  },
};

export const getCorrectPermissionsGroup = (choosenRoles, listRoles) => {
  let permissions = {};
  choosenRoles.forEach((role) => {
    listRoles.forEach((item) => {
      if (item.Id === role) {
        const permissionKey = item.Name.replace(' ', '');
        permissions = {
          ...permissions,
          ...DEFAULT_GROUPS_PERMISSIONS[permissionKey],
        };
      }
    });
  });
  return permissions;
};
