export const SET_LIST_SETTINGS = '@employees/SET_LIST_SETTINGS';
export const RESET_LIST_SETTINGS = '@employees/RESET_LIST_SETTINGS';
export const SEARCH_MEMBERS = '@employees/SEARCH_MEMBERS';
export const SET_MEMBERS = '@employees/SET_MEMBERS';
export const RESET_MEMBERS = '@employees/RESET_MEMBERS';
export const CHANGE_COLUMNS_TO_DISPLAY = '@member/CHANGE_COLUMNS_TO_DISPLAY';
export const GET_PROJECTS = '@employees/GET_PROJECTS';
export const GET_EMPLOYEE = '@employees/GET_EMPLOYEE';
export const SET_EMPLOYEE = '@employees/SET_EMPLOYEE';
export const RESET_EMPLOYEE_DETAILS = '@employees/RESET_EMPLOYEE_DETAILS';
export const SHOW_SKILLS_MODAL = '@employees/SHOW_SKILLS_MODAL';
export const CLOSE_SKILLS_MODAL = '@employees/CLOSE_SKILLS_MODAL';
export const GET_EMPLOYEE_SKILLS = '@employees/GET_EMPLOYEE_SKILLS';
export const SET_SKILL_LEVELS = '@employees/SET_SKILL_LEVELS';
export const SET_SKILLS = '@employees/SET_SKILLS';
export const POST_MAIN_SKILLS = '@employee/POST_MAIN_SKILLS';
export const POST_EMPLOYEE_RESUME = '@employee/POST_EMPLOYEE_RESUME';
export const PUT_EMPLOYEE_RESUME = '@employee/PUT_EMPLOYEE_RESUME';
export const DELETE_EMPLOYEE_RESUME = '@employee/DELETE_EMPLOYEE_RESUME';
export const UPDATE_EMPLOYEE_SKILLS = '@employee/UPDATE_EMPLOYEE_SKILLS';
export const DELETE_EMPLOYEE_SKILL = '@employee/DELETE_EMPLOYEE_SKILL';
export const SYNCHRONIZE_EMPLOYEES = '@employee/SYNCHRONIZE_EMPLOYEES';
export const SHOW_SYNC_MODAL = '@employee/SHOW_SYNC_MODAL';
export const CLOSE_SYNC_MODAL = '@employee/CLOSE_SYNC_MODAL';
export const GET_VACATIONS = '@employee/GET_VACATIONS';
export const SET_VACATIONS = '@employee/SET_VACATIONS';
export const CREATE_VACATION = '@employee/CREATE_VACATION';
export const EDIT_VACATION = '@employee/EDIT_VACATION';
export const SHOW_ADD_VACATION_MODAL = '@employee/SHOW_ADD_VACATION_MODAL';
export const HIDE_ADD_VACATION_MODAL = '@employee/HIDE_ADD_VACATION_MODAL';
export const SET_VACATION_TO_CHANGE = '@employee/SET_VACATION_TO_CHANGE';
export const GET_EMPLOYEE_DETAILS_RESUME = '@employee/GET_EMPLOYEE_DETAILS_RESUME';
export const GET_EMPLOYEE_DETAILS_PROJECTS = '@employee/GET_EMPLOYEE_DETAILS_PROJECTS';
export const GET_EMPLOYEE_DETAILS_SKILLS = '@employee/GET_EMPLOYEE_DETAILS_SKILLS';
export const GET_EMPLOYEE_DETAILS_CERTIFICATES = '@employee/GET_EMPLOYEE_DETAILS_CERTIFICATES';
export const SET_SELECTED_YEAR = '@employee/SET_SELECTED_YEAR';
export const SET_EMPLOYEE_RESUME = '@employee/SET_EMPLOYEE_RESUME';
export const SET_EMPLOYEE_PROJECTS = '@employee/SET_EMPLOYEE_PROJECTS';
export const SET_EMPLOYEE_SKILLS = '@employee/SET_EMPLOYEE_SKILLS';
export const TRANSITION_TO_EDIT_PROJECT = '@employee/TRANSITION_TO_EDIT_PROJECT';
export const SET_EMPLOYEES_TOTAL_COUNT = '@employee/SET_EMPLOYEES_TOTAL_COUNT';
export const SET_OR_CLEAR_MAIN_SKILLS_ERROR = '@employee/SHOW_MAIN_SKILLS_ERROR';
export const GET_CERTIFICATE = '@employee/GET_CERTIFICATE';
export const SET_CERTIFICATE = '@employee/SET_CERTIFICATE';
export const CLEAR_CERTIFICATE = '@employee/CLEAR_CERTIFICATE';
export const PUT_CERTIFICATE = '@employee/PUT_CERTIFICATE';
export const POST_CERTIFICATE = '@employee/POST_CERTIFICATE';
export const DELETE_CERTIFICATE = '@employee/DELETE_CERTIFICATE';
export const UPDATE_EMPLOYEE_OFFICE_BEFORE_RELOCATE = '@employee/UPDATE_EMPLOYEE_OFFICE_BEFORE_RELOCATE';
export const GET_PREV_PATH_FOR_EMPLOYEES = '@employee/GET_PREV_PATH_FOR_EMPLOYEES';
export const DELETE_EMPLOYEE = '@employee/DELETE_EMPLOYEE';
export const SET_EMPLOYEE_HOLIDAYS = '@employee/SET_EMPLOYEE_HOLIDAYS';
export const GET_EMPLOYEE_HOLIDAYS = '@employee/GET_EMPLOYEE_HOLIDAYS';
export const PUT_EMPLOYEE_BUSINESS_LOCATION = '@employee/PUT_EMPLOYEE_BUSINESS_LOCATION';
export const SET_EMPLOYEE_BUSINESS_LOCATIONS = '@employee/SET_EMPLOYEE_BUSINESS_LOCATIONS';
export const GET_EMPLOYEE_BUSINESS_LOCATIONS = '@employee/GET_EMPLOYEE_BUSINESS_LOCATIONS';
export const GET_EMPLOYEE_RESOURCE_MANAGERS = '@employee/GET_EMPLOYEE_RESOURCE_MANAGERS';
export const SET_EMPLOYEE_RESOURCE_MANAGERS = '@employee/SET_EMPLOYEE_RESOURCE_MANAGERS';
export const GET_EMPLOYEE_HR_MANAGERS = '@employee/GET_EMPLOYEE_HR_MANAGERS';
export const SET_EMPLOYEE_HR_MANAGERS = '@employee/SET_EMPLOYEE_HR_MANAGERS';
export const GET_CAN_EDIT_BACKGROUND_CHECKS = '@employee/GET_CAN_EDIT_BACKGROUND_CHECKS';
export const SET_CAN_EDIT_BACKGROUND_CHECKS = '@employee/SET_CAN_EDIT_BACKGROUND_CHECKS';
export const GET_BACKGROUND_CHECKS = '@employee/GET_BACKGROUND_CHECKS';
export const SET_BACKGROUND_CHECKS = '@employee/SET_BACKGROUND_CHECKS';
export const POST_BACKGROUND_CHECK = '@employee/POST_BACKGROUND_CHECK';
export const PUT_BACKGROUND_CHECK = '@employee/PUT_BACKGROUND_CHECK';
export const DELETE_BACKGROUND_CHECK = '@employee/DELETE_BACKGROUND_CHECK';
export const GET_EMPLOYEE_TRAINING = '@employee/GET_EMPLOYEE_TRAINING';
export const SET_EMPLOYEE_TRAINING = '@employee/SET_EMPLOYEE_TRAINING';
export const POST_EMPLOYEE_TRAINING = '@employee/POST_EMPLOYEE_TRAINING';
export const PUT_EMPLOYEE_TRAINING = '@employee/PUT_EMPLOYEE_TRAINING';
export const DELETE_EMPLOYEE_TRAINING = '@employee/DELETE_EMPLOYEE_TRAINING';
export const GET_AVAILABLE_TRAININGS = '@employee/GET_AVAILABLE_TRAININGS';
export const SET_AVAILABLE_TRAININGS = '@employee/SET_AVAILABLE_TRAININGS';
export const GET_AVAILABLE_TRAINING_CLIENTS = '@employee/GET_AVAILABLE_TRAINING_CLIENTS';
export const SET_AVAILABLE_TRAINING_CLIENTS = '@employee/SET_AVAILABLE_TRAINING_CLIENTS';
export const GET_EMPLOYEE_PROJECT_INFO = '@employee/GET_EMPLOYEE_PROJECT_INFO';
export const SET_EMPLOYEE_PROJECT_INFO = '@employee/SET_EMPLOYEE_PROJECT_INFO';
export const PUT_EMPLOYEE_PROJECT_INFO = '@employee/PUT_EMPLOYEE_PROJECT_INFO';
export const GET_CAN_EDIT_EMPLOYEE_PROJECT_INFO = '@employee/GET_CAN_EDIT_EMPLOYEE_PROJECT_INFO';
export const SET_CAN_EDIT_EMPLOYEE_PROJECT_INFO = '@employee/SET_CAN_EDIT_EMPLOYEE_PROJECT_INFO';
export const GET_SHOULD_RESTRICT_EMPLOYEE_INFO = '@employee/GET_SHOULD_RESTRICT_EMPLOYEE_INFO';
export const SET_SHOULD_RESTRICT_EMPLOYEE_INFO = '@employee/SET_SHOULD_RESTRICT_EMPLOYEE_INFO';
export const GET_HAS_BACK_FILL = '@employee/GET_HAS_BACK_FILL';
export const SET_HAS_BACK_FILL = '@employee/SET_HAS_BACK_FILL';
export const GET_ALL_BACK_FILL_TYPES = '@employee/GET_ALL_BACK_FILL_TYPES';
export const SET_BACK_FILL_TYPES = '@employee/SET_BACK_FILL_TYPES';
export const HIDE_PROJECT_INFO = '@employee/HIDE_PROJECT_INFO';
export const SET_CERTIFICATION_REQUESTERS = '@employee/SET_CERTIFICATION_REQUESTERS';
export const SYNCHRONIZE_TRAININGS = '@employee/SYNCHRONIZE_TRAININGS';

export const getPrevPathForEmployees = (path) => ({
  type: GET_PREV_PATH_FOR_EMPLOYEES,
  payload: path,
});

export const setListSettings = (settings) => ({
  type: SET_LIST_SETTINGS,
  payload: settings,
});

export const resetListSettings = () => ({
  type: RESET_LIST_SETTINGS,
});

export const transitionToEditProject = (id) => ({
  type: TRANSITION_TO_EDIT_PROJECT,
  payload: {
    id,
  },
});

export const setEmployeeResume = (resume) => ({
  type: SET_EMPLOYEE_RESUME,
  payload: resume,
});

export const setEmployeeProjects = (projects) => ({
  type: SET_EMPLOYEE_PROJECTS,
  payload: projects,
});

export const setEmployeeSkills = (skills) => ({
  type: SET_EMPLOYEE_SKILLS,
  payload: skills,
});

export const setMembers = (members) => ({
  type: SET_MEMBERS,
  payload: {
    members,
  },
});

export const resetMembers = () => ({
  type: RESET_MEMBERS,
});

export const changeColumnsToDisplay = (columnsToDisplay) => {
  return {
    type: CHANGE_COLUMNS_TO_DISPLAY,
    payload: {
      columnsToDisplay,
    },
  };
};

export const searchMembers = (value, filters = {}, take, skip = 0, sortSetting) => {
  return {
    type: SEARCH_MEMBERS,
    payload: {
      searchValue: value,
      filters,
      take,
      skip,
      sortSetting,
    },
  };
};

export const getProjects = (projects = []) => {
  return {
    type: GET_PROJECTS,
    payload: {
      projects,
    },
  };
};

export const getEmployeeDetails = (id) => {
  return {
    type: GET_EMPLOYEE,
    payload: {
      id,
    },
  };
};
export const getEmployeeDetailsResume = (id) => {
  return {
    type: GET_EMPLOYEE_DETAILS_RESUME,
    payload: {
      id,
    },
  };
};
export const getEmployeeDetailsProjects = (id) => {
  return {
    type: GET_EMPLOYEE_DETAILS_PROJECTS,
    payload: {
      id,
    },
  };
};
export const getEmployeeDetailsSkills = (id) => {
  return {
    type: GET_EMPLOYEE_DETAILS_SKILLS,
    payload: {
      id,
    },
  };
};
export const getEmployeeDetailsCertificates = (id) => {
  return {
    type: GET_EMPLOYEE_DETAILS_CERTIFICATES,
    payload: {
      id,
    },
  };
};

export const setEmployeeDetails = (employee) => {
  return {
    type: SET_EMPLOYEE,
    payload: employee,
  };
};

export const resetEmployeeDetails = () => ({
  type: RESET_EMPLOYEE_DETAILS,
});

export const showSkillsModal = () => ({
  type: SHOW_SKILLS_MODAL,
  payload: {
    isVisibleSkillsModal: true,
  },
});

export const closeSkillsModal = () => ({
  type: CLOSE_SKILLS_MODAL,
  payload: {
    isVisibleSkillsModal: false,
  },
});

export const getEmployeeMainSkills = () => ({
  type: GET_EMPLOYEE_SKILLS,
});

export const setSkillLevels = (levels) => ({
  type: SET_SKILL_LEVELS,
  payload: {
    levels,
  },
});

export const setSkills = (skills) => ({
  type: SET_SKILLS,
  payload: {
    skills,
  },
});

export const postMainSkills = (skills) => ({
  type: POST_MAIN_SKILLS,
  payload: {
    skills,
  },
});

export const setOrClearErrorSkills = (error = '') => ({
  type: SET_OR_CLEAR_MAIN_SKILLS_ERROR,
  payload: error,
});

export const postNewEmployeeResume = (newResume) => ({
  type: POST_EMPLOYEE_RESUME,
  payload: newResume,
});

export const putEmployeeResume = (newResume) => ({
  type: PUT_EMPLOYEE_RESUME,
  payload: newResume,
});

export const deleteResume = (id) => ({
  type: DELETE_EMPLOYEE_RESUME,
  payload: id,
});

export const putEmployeeSkills = (employeeId, skills) => ({
  type: UPDATE_EMPLOYEE_SKILLS,
  payload: {
    employeeId,
    skills,
  },
});

export const deleteEmployeeSkill = (id) => ({
  type: DELETE_EMPLOYEE_SKILL,
  payload: id,
});

export const synchronizeEmployees = (department, options) => ({
  type: SYNCHRONIZE_EMPLOYEES,
  payload: {
    department,
    sortSetting: {
      column: 'Name',
      sortDirection: 'Up',
    },
    ...options,
  },
});

export const showSyncModal = (options) => ({
  type: SHOW_SYNC_MODAL,
  payload: options,
});

export const closeSyncModal = () => ({
  type: CLOSE_SYNC_MODAL,
});

export const getVacations = (employeeId, year) => ({
  type: GET_VACATIONS,
  payload: {
    employeeId,
    year,
  },
});

export const setVacations = (vacations) => ({
  type: SET_VACATIONS,
  payload: {
    vacations,
  },
});

export const createVacation = (vacation, employeeId, year) => ({
  type: CREATE_VACATION,
  payload: {
    vacation,
    employeeId,
    year,
  },
});

export const editVacation = (vacation, employeeId, year) => ({
  type: EDIT_VACATION,
  payload: {
    vacation,
    employeeId,
    year,
  },
});

export const showAddVacationModal = () => ({
  type: SHOW_ADD_VACATION_MODAL,
});

export const hideAddVacationModal = () => ({
  type: HIDE_ADD_VACATION_MODAL,
});

export const setVacationToChange = (vacationToChange) => ({
  type: SET_VACATION_TO_CHANGE,
  payload: {
    vacationToChange,
  },
});

export const setSelectedYear = (year) => ({
  type: SET_SELECTED_YEAR,
  payload: {
    year,
  },
});

export const setEmployeesTotalCount = (count) => ({
  type: SET_EMPLOYEES_TOTAL_COUNT,
  payload: count,
});

export const getCertificate = (employeeId) => ({
  type: GET_CERTIFICATE,
  payload: employeeId,
});

export const setCertificate = (certificates) => ({
  type: SET_CERTIFICATE,
  payload: certificates,
});

export const clearCertificate = () => ({
  type: CLEAR_CERTIFICATE,
});

export const addCertificate = (employeeId, sendData) => ({
  type: POST_CERTIFICATE,
  payload: {
    employeeId,
    sendData,
  },
});

export const editCertificate = (employeeId, certificateId, sendData) => ({
  type: PUT_CERTIFICATE,
  payload: {
    employeeId,
    certificateId,
    sendData,
  },
});

export const deleteCertificate = (employeeId, certificateId) => ({
  type: DELETE_CERTIFICATE,
  payload: {
    employeeId,
    certificateId,
  },
});

export const updateEmployeeOfficeBeforeRelocate = (employeeId, locationId) => ({
  type: UPDATE_EMPLOYEE_OFFICE_BEFORE_RELOCATE,
  payload: { employeeId, locationId },
});

export const deleteEmployee = (employeeId) => ({
  type: DELETE_EMPLOYEE,
  payload: { employeeId },
});

export const setEmployeeHolidays = (holidays) => ({
  type: SET_EMPLOYEE_HOLIDAYS,
  payload: { holidays },
});

export const loadEmployeeHolidays = (employeeId, year) => ({
  type: GET_EMPLOYEE_HOLIDAYS,
  payload: {
    employeeId,
    year,
  },
});

export const updateEmployeeBusinessLocation = (employeeId, businessLocationId, year = null) => ({
  type: PUT_EMPLOYEE_BUSINESS_LOCATION,
  payload: {
    employeeId,
    businessLocationId,
    year,
  },
});

export const setEmployeeBusinessLocations = (businessLocations) => ({
  type: SET_EMPLOYEE_BUSINESS_LOCATIONS,
  payload: { businessLocations },
});

export const getEmployeeBusinessLocations = (employeeId) => ({
  type: GET_EMPLOYEE_BUSINESS_LOCATIONS,
  payload: { employeeId },
});

export const getEmployeeResourceManagers = (employeeId) => ({
  type: GET_EMPLOYEE_RESOURCE_MANAGERS,
  payload: { employeeId },
});

export const setEmployeeResourceManagers = (resourceManagers) => ({
  type: SET_EMPLOYEE_RESOURCE_MANAGERS,
  payload: { resourceManagers },
});

export const getEmployeeHrManagers = (employeeId) => ({
  type: GET_EMPLOYEE_HR_MANAGERS,
  payload: { employeeId },
});

export const setEmployeeHrManagers = (hrManagers) => ({
  type: SET_EMPLOYEE_HR_MANAGERS,
  payload: { hrManagers },
});

export const getCanEditBackgroundChecks = (employeeId) => ({
  type: GET_CAN_EDIT_BACKGROUND_CHECKS,
  payload: employeeId,
});

export const setCanEditBackgroundCheck = (canEdit) => ({
  type: SET_CAN_EDIT_BACKGROUND_CHECKS,
  payload: canEdit,
});

export const getBackgroundChecks = (employeeId) => ({
  type: GET_BACKGROUND_CHECKS,
  payload: employeeId,
});

export const setBackgroundChecks = (backgroundChecks) => ({
  type: SET_BACKGROUND_CHECKS,
  payload: backgroundChecks,
});

export const addBackgroundCheck = (employeeId, sendData) => ({
  type: POST_BACKGROUND_CHECK,
  payload: {
    employeeId,
    sendData,
  },
});

export const editBackgroundCheck = (employeeId, backgroundCheckId, sendData) => ({
  type: PUT_BACKGROUND_CHECK,
  payload: {
    employeeId,
    backgroundCheckId,
    sendData,
  },
});

export const deleteBackgroundCheck = (employeeId, backgroundCheckId) => ({
  type: DELETE_BACKGROUND_CHECK,
  payload: {
    employeeId,
    backgroundCheckId,
  },
});

export const getEmployeeTrainings = (employeeId) => ({
  type: GET_EMPLOYEE_TRAINING,
  payload: employeeId,
});

export const setEmployeeTrainings = (employeeTrainings) => ({
  type: SET_EMPLOYEE_TRAINING,
  payload: employeeTrainings,
});

export const addEmployeeTraining = (employeeTraining) => ({
  type: POST_EMPLOYEE_TRAINING,
  payload: {
    employeeTraining,
  },
});

export const editEmployeeTraining = (employeeTrainingId, employeeTraining) => ({
  type: PUT_EMPLOYEE_TRAINING,
  payload: {
    employeeTrainingId,
    employeeTraining,
  },
});

export const deleteEmployeeTraining = (employeeTrainingId) => ({
  type: DELETE_EMPLOYEE_TRAINING,
  payload: {
    employeeTrainingId,
  },
});

export const getAvailableTrainings = () => ({
  type: GET_AVAILABLE_TRAININGS,
});

export const setAvailableTrainings = (employeeTraining) => ({
  type: SET_AVAILABLE_TRAININGS,
  payload: employeeTraining,
});

export const getAvailableTrainingClients = () => ({
  type: GET_AVAILABLE_TRAINING_CLIENTS,
});

export const setAvailableTrainingClients = (clients) => ({
  type: SET_AVAILABLE_TRAINING_CLIENTS,
  payload: clients,
});

export const getEmployeeProjectInfo = (employeeId) => ({
  type: GET_EMPLOYEE_PROJECT_INFO,
  payload: {
    employeeId,
  },
});

export const setEmployeeProjectInfo = (projectInfo) => ({
  type: SET_EMPLOYEE_PROJECT_INFO,
  payload: projectInfo,
});

export const updateEmployeeProjectInfo = (projectInfoId, employeeId, updatedProjectInfo) => ({
  type: PUT_EMPLOYEE_PROJECT_INFO,
  payload: {
    projectInfoId,
    employeeId,
    updatedProjectInfo,
  },
});

export const getCanEditEmployeeProjectInfo = (employeeId) => ({
  type: GET_CAN_EDIT_EMPLOYEE_PROJECT_INFO,
  payload: {
    employeeId,
  },
});

export const setCanEditEmployeeProjectInfo = (canEdit) => ({
  type: SET_CAN_EDIT_EMPLOYEE_PROJECT_INFO,
  payload: canEdit,
});

export const getShouldRestrictEmployeeInfo = (employeeId) => ({
  type: GET_SHOULD_RESTRICT_EMPLOYEE_INFO,
  payload: {
    employeeId,
  },
});

export const setShouldRestrictEmployeeInfo = (shouldRestrict) => ({
  type: SET_SHOULD_RESTRICT_EMPLOYEE_INFO,
  payload: shouldRestrict,
});

export const getHasBackFill = (employeeId) => ({
  type: GET_HAS_BACK_FILL,
  payload: {
    employeeId,
  },
});

export const setHasBackFill = (hasBackFill) => ({
  type: SET_HAS_BACK_FILL,
  payload: hasBackFill,
});

export const getAllBackFillTypes = () => ({
  type: GET_ALL_BACK_FILL_TYPES,
});

export const setBackFillTypes = (backFillTypes) => ({
  type: SET_BACK_FILL_TYPES,
  payload: backFillTypes,
});

export const hideProjectInfo = (employeeId, projectInfoId) => ({
  type: HIDE_PROJECT_INFO,
  payload: {
    employeeId,
    projectInfoId,
  },
});

export const setCertificationRequesters = (requesters) => ({
  type: SET_CERTIFICATION_REQUESTERS,
  payload: requesters,
});

export const synchronizeTrainings = () => ({
  type: SYNCHRONIZE_TRAININGS,
});
