export const GET_MANAGE_EMPLOYEE_DEPARTMENTS = '@admin/GET_MANAGE_EMPLOYEE_DEPARTMENTS';
export const SET_MANAGE_EMPLOYEE_DEPARTMENTS = '@admin/SET_MANAGE_EMPLOYEE_DEPARTMENTS';
export const POST_MANAGE_EMPLOYEE_DEPARTMENT = '@admin/POST_MANAGE_EMPLOYEE_DEPARTMENT';
export const PUT_MANAGE_EMPLOYEE_DEPARTMENT = '@admin/PUT_MANAGE_EMPLOYEE_DEPARTMENT';

export const getEmployeeDepartments = () => ({
  type: GET_MANAGE_EMPLOYEE_DEPARTMENTS,
});

export const setEmployeeDepartments = employeeDepartments => ({
  type: SET_MANAGE_EMPLOYEE_DEPARTMENTS,
  payload: employeeDepartments,
});

export const postEmployeeDepartment = department => ({
  type: POST_MANAGE_EMPLOYEE_DEPARTMENT,
  payload: department,
});

export const putEmployeeDepartment = department => ({
  type: PUT_MANAGE_EMPLOYEE_DEPARTMENT,
  payload: department,
});
