import {
  CHANGE_CERTIFICATIONS_COLUMNS_TO_DISPLAY,
  RESET_CERTIFICATIONS_LIST_SETTINGS,
  SET_CERTIFICATIONS_LIST_SETTINGS,
} from '../actions/certificationsActions';
import {
  CERTIFICATION_STATUS_ACTIVE_ID,
  CERTIFICATION_STATUS_PERPETUAL_ID,
  EMPLOYEE_DEPARTMENT_DELIVERY_MANAGERS_ID,
  EMPLOYEE_DEPARTMENT_TECHNICAL_PRODUCTION_ID,
  EMPLOYEES_STATUS_BENCH_ID,
  EMPLOYEES_STATUS_EMPLOYEE_ID,
  EMPLOYEES_STATUS_UNPAID_ID,
} from '../../../utils/const-variable';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Status',
  sortDirection: 'Up',
  filters: {
    EmployeeTypes: [EMPLOYEES_STATUS_EMPLOYEE_ID, EMPLOYEES_STATUS_BENCH_ID, EMPLOYEES_STATUS_UNPAID_ID],
    EmployeeCertificateStatuses: [CERTIFICATION_STATUS_ACTIVE_ID, CERTIFICATION_STATUS_PERPETUAL_ID],
    EmployeeDepartments: [EMPLOYEE_DEPARTMENT_TECHNICAL_PRODUCTION_ID, EMPLOYEE_DEPARTMENT_DELIVERY_MANAGERS_ID],
  },
  takeAmountReports: 50,
};

export const defaultColumnsToDisplay = [
  {
    Name: 'Name', Id: 'Employee', className: 'big-report-col', isObject: true, isSelected: true, necessary: true,
  },
  {
    Name: 'Office', Id: 'Office', className: 'fixed-medium-report-col', isObject: true, isSelected: true,
  },
  {
    Name: 'Type', Id: 'EmployeeType', className: 'fixed-medium-report-col', isObject: true, isSelected: true,
  },
  {
    Name: 'Specialization', Id: 'CertificationSpecialization', className: 'fixed-medium-report-col', isSelected: true,
  },
  {
    Name: 'RequestedBy', Id: 'CertificationRequester', className: 'fixed-medium-report-col', isObject: true, isSelected: true,
  },
  {
    Name: 'Issue Date', Id: 'CertificationDate', className: 'fixed-medium-report-col', isDate: true, isSelected: true,
  },
  {
    Name: 'Expiration Date', Id: 'ExpirationDate', className: 'fixed-medium-report-col', isDate: true, isSelected: true,
  },
  {
    Name: 'Link', Id: 'CertificationLink', className: 'fixed-medium-report-col', isSelected: true,
  },
  {
    Name: 'Status', Id: 'Status', className: 'small-col', isObject: true, isSelected: true,
  },
];

export const initialState = {
  listSettings: defaultListSettings,
  columnsToDisplay: defaultColumnsToDisplay,
};

const certificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CERTIFICATIONS_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_CERTIFICATIONS_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case CHANGE_CERTIFICATIONS_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    default:
      return state;
  }
};

export default certificationsReducer;
