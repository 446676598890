/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  getEmployeeMainSkills, resetMembers, resetListSettings, getPrevPathForEmployees, setListSettings,
} from './actions/membersActions';
import { getDepartments } from '../../common/actions/authActions';
import { getFilters, getFiltersCertifications } from '../../common/actions/filtersActions';
import MembersListPage from './components/members-list/members-list';
import EmployeeDetails from './components/member-details/member-details';
import EmployeeSkillsModal from './components/member-details/member-skills-modal';
import AddVacationModal from './components/member-details/vacations/components/add-vacation-modal';
import SyncModal from './components/member-sync-modal/sync-modal';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { loadOfficeLocations } from 'pages/projects/actions/filterLocationsActions.js';
import './members-page.css';
import {
  loadBaseBusinessLocations,
} from '../offices/actions/officesActions';
import {
  EMPLOYEES_STATUS_BENCH_ID,
  EMPLOYEES_STATUS_CONTRACTOR_ID,
  EMPLOYEES_STATUS_EMPLOYEE_ID,
  EMPLOYEES_STATUS_UNPAID_ID,
} from '../../utils/const-variable';

const filtersNames = [
  'Offices',
  'Positions',
  'Projects',
  'ProjectStatuses',
  'EmployeeStatusesNew',
  'EmployeeStatuses',
  'Locations',
  'BusinessTrips',
  'JobTitles',
  'TeamPositions',
  'EmployeeAssignmentStatuses',
  'EmployeeDepartments',
];

class MembersPage extends React.Component {
  constructor(props) {
    super(props);

    const navStateFilters = props.location.state && props.location.state.filters;
    this.state = {
      defaultFilters: {
        Statuses: (navStateFilters && navStateFilters.Statuses) || [
          EMPLOYEES_STATUS_EMPLOYEE_ID,
          EMPLOYEES_STATUS_BENCH_ID,
          EMPLOYEES_STATUS_UNPAID_ID,
          EMPLOYEES_STATUS_CONTRACTOR_ID,
        ],
        Offices: navStateFilters && navStateFilters.Offices,
        EmployeeAssignmentStatuses: [2],
      },
    };
  }

  componentDidMount() {
    const {
      getFilters, getEmployeeMainSkills, getDepartments, loadOfficeLocations, getFiltersCertifications, prevPath, location,
      loadBaseBusinessLocations,
    } = this.props;
    const urlIdPath = location.pathname.split('/employees/')[1];
    const patternId = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    getFilters(filtersNames);
    getFiltersCertifications();
    getEmployeeMainSkills();
    getDepartments();
    loadOfficeLocations();
    loadBaseBusinessLocations();

    if (prevPath || patternId.test(urlIdPath)) {
      this.resetDefaultFilters();
    }
  }

  componentDidUpdate() {
    const { location, getPrevPathForEmployees } = this.props;

    const urlIdPath = location.pathname;
    const pattern = /\/employees/i;
    if (pattern.test(urlIdPath)) {
      getPrevPathForEmployees(urlIdPath);
    }
  }

  componentWillUnmount() {
    const { resetMembers, resetListSettings } = this.props;
    resetMembers();
    resetListSettings();
  }

  resetDefaultFilters = () => {
    this.setState({
      defaultFilters: null,
    });
  };

  MembersList = (props) => {
    const { defaultFilters } = this.state;
    const { prevPath } = this.props;

    return (
      <MembersListPage
        defaultFilters={defaultFilters}
        prevPath={prevPath}
        {...this.props}
        {...props}
      />
    );
  };

  EmployeeDetails = (props) => {
    return (
      <EmployeeDetails
        resetDefaultFilters={this.resetDefaultFilters}
        {...this.props}
        {...props}
      />
    );
  };

  render() {
    const {
      isVisibleSkillsModal, isVisibleSyncModal, isVisibleAddVacationModal,
    } = this.props;
    return (
      <DocumentTitle title={applicationTitle.getTitile('employees')}>
        <>
          <Switch>
            <Route exact path='/employees' render={this.MembersList} />
            <Route exact path='/employees/:id' render={this.EmployeeDetails} />
            <Redirect to='/employees' />
          </Switch>
          {
            isVisibleSkillsModal && <EmployeeSkillsModal />
          }
          {
            isVisibleSyncModal && <SyncModal />
          }
          {
            isVisibleAddVacationModal && <AddVacationModal />
          }
        </>
      </DocumentTitle>
    );
  }
}

MembersPage.propTypes = {
  fetchMembers: PropTypes.func,
  getFilters: PropTypes.func,
  getEmployeeMainSkills: PropTypes.func,
  getDepartments: PropTypes.func,
  isVisibleSkillsModal: PropTypes.bool,
  isVisibleSyncModal: PropTypes.bool,
  isVisibleAddVacationModal: PropTypes.bool,
  EditEmployeeBasicInfo: PropTypes.bool,
  ManageEmployeeSkills: PropTypes.bool,
  HasFinancialAccess: PropTypes.bool,
  ManageEmployeeVacations: PropTypes.bool,
  ManageEmployeeResume: PropTypes.bool,
  ManageOfficeCalendar: PropTypes.bool,
};

export default connect((store) => ({
  isVisibleSkillsModal: store.membersReducer.isVisibleSkillsModal,
  isVisibleSyncModal: store.membersReducer.isVisibleSyncModal,
  isVisibleAddVacationModal: store.membersReducer.isVisibleAddVacationModal,
  statuses: store.filtersReducer.filters.EmployeeStatusesNew,
  prevPath: store.membersReducer.prevPath,
}), {
  getFilters,
  getEmployeeMainSkills,
  getDepartments,
  loadOfficeLocations,
  getFiltersCertifications,
  resetMembers,
  resetListSettings,
  setListSettings,
  getPrevPathForEmployees,
  loadBaseBusinessLocations,
})(MembersPage);
