import { SET_MANAGE_ROLE_TYPES } from '../actions/adminManageRoleTypes';
import { SET_MANAGE_JOB_TITLES } from '../actions/adminManageJobTitles';
import { SET_MANAGE_PROJECT_TYPES } from '../actions/adminManageProjectTypes';
import { SET_MANAGE_LOCATIONS } from '../actions/adminManageLocations';
import { SET_MANAGE_SKILLS } from '../actions/adminManageSkills';
import { SET_MANAGE_BUSINESS_TRIPS } from '../actions/adminManageBisunessTrips';
import { SET_MANAGE_PAYMENT_TERMS } from '../actions/adminManagePaymentTerms';
import { SET_MANAGE_INVOICE_DATES } from '../actions/adminManageInvoiceDates';
import { SET_MANAGE_SENIORITY_LEVELS } from '../actions/adminManageSeniorityLevels';
import { SET_MANAGE_SKILL_LEVELS } from '../actions/adminManageSkillLevels';
import { SET_MANAGE_SOW_TYPES } from '../actions/adminManageSowTypes';
import { SET_MANAGE_COUNTRIES } from '../actions/adminManageCountries';
import { SET_MANAGE_DOCUMENT_TYPES } from '../actions/adminManageDocumentTypes';
import { SET_MANAGE_CERTIFICATES } from '../actions/adminCertificatesActions';
import { SET_SOW_RENEWAL_STATUS } from '../actions/adminManageSOWRenewalStatus';
import { SET_ASSIGNMENT_TYPES } from '../actions/adminAssignmentTypesAction';
import { SET_MANAGE_ENGAGEMENT_TYPES } from '../actions/adminManageEngagementTypes';
import {
  SET_LIST_SETTINGS_LOOKUPS,
  RESET_LIST_SETTINGS_LOOKUPS,
} from '../actions/adminActions';
import { SET_EMPLOYEE_TRAININGS, SET_EMPLOYEE_TRAINING_CLIENTS } from '../actions/adminManageEmployeeTrainings';
import { SET_MANAGE_BACK_FILL_TYPES } from '../actions/adminManageBackFillTypes';
import { SET_MANAGE_EMPLOYEE_DEPARTMENTS } from '../actions/adminManageEmployeeDepartments';
import { SET_MANAGE_EMPLOYEE_TYPES } from '../actions/adminManageEmployeeTypes';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Name',
  sortDirection: 'Up',
};

const initialState = {
  roleTypes: [],
  jobTitles: [],
  projectTypes: [],
  locations: [],
  skills: [],
  businessTrips: [],
  paymentTerms: [],
  invoiceDates: [],
  seniorityLevels: [],
  skillLevels: [],
  sowTypes: [],
  countries: [],
  documentTypes: [],
  certificates: [],
  sowRenewalStatuses: [],
  assignmentTypes: [],
  engagementTypes: [],
  trainings: [],
  clients: [],
  backFillTypes: [],
  employeeDepartments: [],
  employeeTypes: [],
  listSettings: defaultListSettings,
};

const adminManageLookups = (state = initialState, action) => {
  switch (action.type) {
    case SET_MANAGE_PROJECT_TYPES:
      return {
        ...state,
        projectTypes: action.payload,
      };
    case SET_MANAGE_SOW_TYPES:
      return {
        ...state,
        sowTypes: action.payload,
      };
    case SET_MANAGE_SKILLS:
      return {
        ...state,
        skills: action.payload,
      };
    case SET_MANAGE_ROLE_TYPES:
      return {
        ...state,
        roleTypes: action.payload,
      };
    case SET_MANAGE_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case SET_MANAGE_JOB_TITLES:
      return {
        ...state,
        jobTitles: action.payload,
      };
    case SET_MANAGE_BUSINESS_TRIPS:
      return {
        ...state,
        businessTrips: action.payload,
      };
    case SET_MANAGE_PAYMENT_TERMS:
      return {
        ...state,
        paymentTerms: action.payload,
      };
    case SET_MANAGE_INVOICE_DATES:
      return {
        ...state,
        invoiceDates: action.payload,
      };
    case SET_MANAGE_SENIORITY_LEVELS:
      return {
        ...state,
        seniorityLevels: action.payload,
      };
    case SET_MANAGE_SKILL_LEVELS:
      return {
        ...state,
        skillLevels: action.payload,
      };
    case SET_MANAGE_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_MANAGE_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.payload,
      };
    case SET_MANAGE_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload,
      };
    case SET_SOW_RENEWAL_STATUS:
      return {
        ...state,
        sowRenewalStatuses: action.payload,
      };
    case SET_ASSIGNMENT_TYPES:
      return {
        ...state,
        assignmentTypes: action.payload,
      };
    case SET_LIST_SETTINGS_LOOKUPS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case SET_MANAGE_ENGAGEMENT_TYPES:
      return {
        ...state,
        engagementTypes: action.payload,
      };
    case RESET_LIST_SETTINGS_LOOKUPS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_EMPLOYEE_TRAININGS:
      return {
        ...state,
        trainings: action.payload,
      };
    case SET_EMPLOYEE_TRAINING_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    case SET_MANAGE_BACK_FILL_TYPES:
      return {
        ...state,
        backFillTypes: action.payload,
      };
    case SET_MANAGE_EMPLOYEE_DEPARTMENTS:
      return {
        ...state,
        employeeDepartments: action.payload,
      };
    case SET_MANAGE_EMPLOYEE_TYPES:
      return {
        ...state,
        employeeTypes: action.payload,
      };
    default:
      return state;
  }
};

export default adminManageLookups;
