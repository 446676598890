import { call, put, takeEvery } from 'redux-saga/effects';
import { finishRequest, startRequest } from '../../../common/actions/loadingActions';
import http from '../../../services/http';
import {
  GET_MANAGE_EMPLOYEE_DEPARTMENTS,
  POST_MANAGE_EMPLOYEE_DEPARTMENT,
  PUT_MANAGE_EMPLOYEE_DEPARTMENT,
  setEmployeeDepartments,
} from '../actions/adminManageEmployeeDepartments';

export function* getEmployeeDepartments() {
  try {
    yield put(startRequest());
    const employeeDepartments = yield call(http, {
      method: 'GET',
      url: 'employee-departments',
    });

    yield put(setEmployeeDepartments(employeeDepartments.data));
  } finally {
    yield put(finishRequest());
  }
}

export function* putEmployeeDepartment(action) {
  try {
    yield put(startRequest());

    yield call(http, {
      method: 'PUT',
      url: 'employee-departments',
      data: action.payload,
    });
    yield call(getEmployeeDepartments);
  } finally {
    yield put(finishRequest());
  }
}

export function* postEmployeeDepartment(action) {
  try {
    yield put(startRequest());

    yield call(http, {
      method: 'POST',
      url: 'employee-departments',
      data: {
        Name: action.payload.Name,
      },
    });
    yield call(getEmployeeDepartments);
  } finally {
    yield put(finishRequest());
  }
}

export default function* adminManageEmployeeDepartments() {
  yield takeEvery(GET_MANAGE_EMPLOYEE_DEPARTMENTS, getEmployeeDepartments);
  yield takeEvery(POST_MANAGE_EMPLOYEE_DEPARTMENT, postEmployeeDepartment);
  yield takeEvery(PUT_MANAGE_EMPLOYEE_DEPARTMENT, putEmployeeDepartment);
}
