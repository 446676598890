import {
  SET_MEMBERS,
  RESET_MEMBERS,
  CHANGE_COLUMNS_TO_DISPLAY,
  SET_EMPLOYEE,
  RESET_EMPLOYEE_DETAILS,
  SHOW_SKILLS_MODAL,
  CLOSE_SKILLS_MODAL,
  SET_SKILLS,
  SET_SKILL_LEVELS,
  SET_VACATIONS,
  SHOW_ADD_VACATION_MODAL,
  HIDE_ADD_VACATION_MODAL,
  SET_VACATION_TO_CHANGE,
  SET_SELECTED_YEAR,
  SET_EMPLOYEE_RESUME,
  SET_EMPLOYEE_PROJECTS,
  SET_EMPLOYEE_SKILLS,
  CLOSE_SYNC_MODAL,
  SHOW_SYNC_MODAL,
  SET_EMPLOYEES_TOTAL_COUNT,
  SET_OR_CLEAR_MAIN_SKILLS_ERROR,
  SET_CERTIFICATE,
  SET_LIST_SETTINGS,
  RESET_LIST_SETTINGS,
  GET_PREV_PATH_FOR_EMPLOYEES,
  SET_EMPLOYEE_HOLIDAYS,
  SET_EMPLOYEE_BUSINESS_LOCATIONS,
  SET_EMPLOYEE_RESOURCE_MANAGERS,
  SET_EMPLOYEE_HR_MANAGERS,
  SET_BACKGROUND_CHECKS,
  SET_CAN_EDIT_BACKGROUND_CHECKS,
  SET_EMPLOYEE_TRAINING,
  SET_AVAILABLE_TRAININGS,
  SET_AVAILABLE_TRAINING_CLIENTS,
  SET_EMPLOYEE_PROJECT_INFO,
  SET_CAN_EDIT_EMPLOYEE_PROJECT_INFO,
  SET_SHOULD_RESTRICT_EMPLOYEE_INFO,
  SET_HAS_BACK_FILL,
  SET_BACK_FILL_TYPES, SET_CERTIFICATION_REQUESTERS,
} from '../actions/membersActions.js';
import {
  EMPLOYEES_STATUS_BENCH_ID,
  EMPLOYEES_STATUS_CONTRACTOR_ID,
  EMPLOYEES_STATUS_EMPLOYEE_ID,
  EMPLOYEES_STATUS_UNPAID_ID,
} from '../../../utils/const-variable';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Name',
  sortDirection: 'Up',
  scrollTo: '',
  filters: {
    EmployeeAssignmentStatuses: [2],
    Statuses: [
      EMPLOYEES_STATUS_EMPLOYEE_ID,
      EMPLOYEES_STATUS_BENCH_ID,
      EMPLOYEES_STATUS_UNPAID_ID,
      EMPLOYEES_STATUS_CONTRACTOR_ID,
    ],
    EmployeeDepartments: [],
  },
  detailsTabsState: {
    skills: {
      searchValue: '',
      sortColumn: 'Level',
      sortDirection: 'Up',
    },
    resume: {
      searchValue: '',
      sortColumn: 'Name',
      sortDirection: 'Up',
    },
    projects: {
      searchValue: '',
      sortColumn: 'Name',
      sortDirection: 'Up',
    },
    certificates: {
      searchValue: '',
      sortColumn: 'Status',
      sortDirection: 'Up',
    },
    holidays: {
      searchValue: '',
      sortColumn: 'Date',
      sortDirection: 'Up',
    },
    backgroundChecks: {
      sortColumn: 'ExpirationDate',
      sortDirection: 'Down',
    },
    trainings: {
      searchValue: '',
      sortColumn: 'DateofPassing',
      sortDirection: 'Up',
    },
    activeProjectInfo: {
      sortColumn: 'Client',
      sortDirection: 'Up',
    },
    finishedProjectInfo: {
      sortColumn: 'Client',
      sortDirection: 'Up',
    },
  },
};

const initialState = {
  prevPath: '',
  isVisibleSkillsModal: false,
  isVisibleSyncModal: false,
  syncOptions: {},
  isVisibleAddVacationModal: false,
  employeesTotalCount: 0,
  members: [],
  employee: {
    Skills: [],
    Projects: [],
    Documents: [],
  },
  employeeSkills: [],
  employeeSkillLevels: [],
  employeeCertificates: [],
  statuses: [{
    Name: 'Onsite',
    Id: '0',
  }, {
    Name: 'Offshore',
    Id: '1',
  }],
  positions: [{
    Name: 'Product Manager',
    Id: '1',
  }],
  columnsToDisplay: [
    {
      Name: 'Name', isSelected: true, Id: 'Name', className: 'big-col', necessary: true, hasBadge: true,
    },
    {
      Name: 'Office', isSelected: true, Id: 'Office', className: 'medium-col', isObject: true,
    },
    {
      Name: 'Location', isSelected: true, Id: 'Location', className: 'medium-col', isObject: true,
    },
    {
      Name: 'BusinessLocation', isSelected: true, Id: 'BusinessLocation', className: 'medium-col', isObject: true,
    },
    {
      Name: 'Email', isSelected: true, Id: 'Email', className: 'small-col',
    },
    {
      Name: 'Cell', isSelected: true, Id: 'Cell', className: 'medium-col',
    },
    {
      Name: 'Current position', isSelected: true, Id: 'JobTitle', className: 'medium-col', isObject: true,
    },
    {
      Name: 'Start Date In Company', isSelected: true, Id: 'StartDateInCompany', className: 'small-col', isDate: true,
    },
    {
      Name: 'Project', isSelected: true, Id: 'Projects', className: 'big-col', isArray: true,
    },
    {
      Name: 'Department', isSelected: true, Id: 'Department', className: 'small-col', isObject: true, useEmbeddedColor: true,
    },
    {
      Name: 'Type', isSelected: true, Id: 'Type', className: 'small-col', isObject: true, useEmbeddedColor: true,
    },
  ],
  vacations: [],
  vacationToChange: {},
  selectedYear: '',
  skillError: '',
  listSettings: defaultListSettings,
  holidays: [],
  businessLocations: [],
  resourceManagers: [],
  hrManagers: [],
  backgroundChecks: [],
  canEditBackgroundChecks: false,
  employeeTrainings: [],
  availableTrainings: [],
  availableTrainingClients: [],
  projectInfo: [],
  canEditProjectInfo: false,
  shouldRestrictEmployeeInfo: false,
  hasBackFill: false,
  allBackFillTypes: [],
  certificationRequesters: [],
};

// eslint-disable-next-line default-param-last
const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PREV_PATH_FOR_EMPLOYEES:
      return {
        ...state,
        prevPath: action.payload,
      };
    case SET_OR_CLEAR_MAIN_SKILLS_ERROR:
      return {
        ...state,
        skillError: action.payload,
      };
    case SET_EMPLOYEE_RESUME:
      return {
        ...state,
        employee: {
          ...state.employee,
          Documents: action.payload,
        },
      };
    case SET_EMPLOYEE_PROJECTS:
      return {
        ...state,
        employee: {
          ...state.employee,
          Projects: action.payload,
        },
      };
    case SET_EMPLOYEE_SKILLS:
      return {
        ...state,
        employee: {
          ...state.employee,
          Skills: action.payload,
        },
      };
    case SET_MEMBERS: {
      const newMemberIds = new Set(action.payload.members.map((member) => member.Id));
      return {
        ...state,
        members: [...state.members.filter((member) => !newMemberIds.has(member.Id)), ...action.payload.members],
      };
    }
    case RESET_MEMBERS:
      return {
        ...state,
        members: [],
        employeesTotalCount: 0,
      };
    case CHANGE_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    case SET_EMPLOYEE:
      return {
        ...state,
        employee: {
          ...state.employee,
          ...action.payload,
        },
      };
    case RESET_EMPLOYEE_DETAILS:
      return {
        ...state,
        employee: {},
      };
    case SHOW_SKILLS_MODAL:
    case CLOSE_SKILLS_MODAL:
      return {
        ...state,
        isVisibleSkillsModal: action.payload.isVisibleSkillsModal,
        skillError: '',
      };
    case SET_SKILLS:
      return {
        ...state,
        employeeSkills: action.payload.skills,
      };
    case SET_SKILL_LEVELS:
      return {
        ...state,
        employeeSkillLevels: action.payload.levels,
      };
    case SHOW_SYNC_MODAL:
      return {
        ...state,
        isVisibleSyncModal: true,
        syncOptions: action.payload,
      };
    case CLOSE_SYNC_MODAL:
      return {
        ...state,
        isVisibleSyncModal: false,
        syncOptions: {},
      };
    case SET_VACATIONS:
      return {
        ...state,
        vacations: action.payload.vacations,
      };
    case SHOW_ADD_VACATION_MODAL:
      return {
        ...state,
        isVisibleAddVacationModal: true,
      };
    case HIDE_ADD_VACATION_MODAL:
      return {
        ...state,
        isVisibleAddVacationModal: false,
        vacationToChange: {},
      };
    case SET_VACATION_TO_CHANGE:
      return {
        ...state,
        vacationToChange: action.payload.vacationToChange,
      };
    case SET_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.payload.year,
      };
    case SET_EMPLOYEES_TOTAL_COUNT:
      return {
        ...state,
        employeesTotalCount: action.payload,
      };
    case SET_CERTIFICATE:
      return {
        ...state,
        employeeCertificates: action.payload,
      };
    case SET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case SET_EMPLOYEE_HOLIDAYS:
      return {
        ...state,
        holidays: action.payload.holidays,
      };
    case SET_EMPLOYEE_BUSINESS_LOCATIONS:
      return {
        ...state,
        businessLocations: action.payload.businessLocations,
      };
    case SET_EMPLOYEE_RESOURCE_MANAGERS:
      return {
        ...state,
        resourceManagers: action.payload.resourceManagers,
      };
    case SET_EMPLOYEE_HR_MANAGERS:
      return {
        ...state,
        hrManagers: action.payload.hrManagers,
      };
    case SET_BACKGROUND_CHECKS:
      return {
        ...state,
        backgroundChecks: action.payload,
      };
    case SET_CAN_EDIT_BACKGROUND_CHECKS:
      return {
        ...state,
        canEditBackgroundChecks: action.payload,
      };
    case SET_EMPLOYEE_TRAINING:
      return {
        ...state,
        employeeTrainings: action.payload,
      };
    case SET_AVAILABLE_TRAININGS:
      return {
        ...state,
        availableTrainings: action.payload,
      };
    case SET_AVAILABLE_TRAINING_CLIENTS:
      return {
        ...state,
        availableTrainingClients: action.payload,
      };
    case SET_EMPLOYEE_PROJECT_INFO:
      return {
        ...state,
        projectInfo: action.payload,
      };
    case SET_CAN_EDIT_EMPLOYEE_PROJECT_INFO:
      return {
        ...state,
        canEditProjectInfo: action.payload,
      };
    case SET_SHOULD_RESTRICT_EMPLOYEE_INFO:
      return {
        ...state,
        shouldRestrictEmployeeInfo: action.payload,
      };
    case SET_HAS_BACK_FILL:
      return {
        ...state,
        hasBackFill: action.payload,
      };
    case SET_BACK_FILL_TYPES:
      return {
        ...state,
        allBackFillTypes: action.payload,
      };
    case SET_CERTIFICATION_REQUESTERS:
      return {
        ...state,
        certificationRequesters: action.payload,
      };
    default:
      return state;
  }
};

export default membersReducer;
