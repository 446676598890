import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sortList, sortFilterList } from 'common/components/list/sortList';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import isObjectNotEquals from 'utils/object-comparison';
import isEmptyObject from 'utils/isEmptyObject';
import {
  transitionToEmployeeDetails, searchBenchReports, setBenchReports, setBenchListSettings, resetBenchListSettings,
} from '../../actions/benchReportsActions';
import { downloadXLSXDocument } from '../../actions/xlsx-action';
import { getFilters } from 'common/actions/filtersActions';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { CellSkill } from 'common/components/list/custom-cell/cell-skills';
import { emptyArray } from 'utils/const-variable';
import { CellLinkForRedirect } from 'common/components/list/custom-cell/cell-link-for-redirect';
import { setComponent, resetComponent } from 'common/actions/headerActions';

const filtersNames = [
  'Offices',
  'EmployeeStatusesNew',
  'EmployeeDepartments',
];

export class BenchReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsToDisplay: [
        {
          Name: 'Office', Id: 'Office', className: 'medium-col', isObject: true, deleteArrow: 'delete-arrow',
        },
        {
          Name: 'Employee', Id: 'Employee', className: 'medium-col', isObject: true,
        },
        {
          Name: 'Employee Type', Id: 'EmployeeType', className: 'medium-col', isObject: true,
        },
        {
          Name: 'Job Title', Id: 'JobTitle', className: 'medium-col', isObject: true,
        },
        {
          Name: 'Skills', Id: 'MainSkills', className: 'medium-col',
        },
        {
          Name: 'Available', Id: 'Available', className: 'medium-col',
        },
        {
          Name: 'Until', Id: 'Until', className: 'medium-col', isDate: true,
        },
        {
          Name: 'Then Available', Id: 'ThenAvailable', className: 'medium-col', isString: true,
        },
      ],
    };
  }

  componentDidMount() {
    const {
      searchBenchReports, getFilters, setComponent, location, setBenchListSettings, listSettings: {
        searchValue, filters,
      },
    } = this.props;
    const { AsOfDate } = this.state;
    getFilters(filtersNames);

    if (location.state && location.state.filters && location.state.filters.OfficeIds) {
      setBenchListSettings({
        filters: {
          OfficeIds: location.state.filters.OfficeIds,
        },
      });
    } else {
      searchBenchReports(filters, searchValue);
    }

    setComponent({ title: `Bench report - ${AsOfDate || moment().format('MM/DD/YYYY')}` });
  }

  componentDidUpdate(prevProps) {
    const { searchBenchReports, listSettings } = this.props;
    const { searchValue, filters } = listSettings;

    if (isObjectNotEquals(prevProps.listSettings, listSettings)) {
      searchBenchReports(filters, searchValue);
    }
  }

  componentWillUnmount() {
    const { resetComponent, setBenchReports, resetBenchListSettings } = this.props;
    setBenchReports(emptyArray);
    resetComponent();
    resetBenchListSettings();
  }

  setSortSettings = (sortColumn, sortDirection) => {
    const { setBenchListSettings } = this.props;
    setBenchListSettings({
      sortColumn,
      sortDirection,
    });
  };

  applyFilters = (filters) => {
    const { setBenchListSettings } = this.props;
    const { AsOfDate } = this.state;
    const newFilters = {
      ...filters,
      AsOfDate,
    };
    const isExistsFilters = Object.keys(newFilters).some((filter) => newFilters[filter] && !isEmptyObject(newFilters[filter]));
    setBenchListSettings({
      filters: isExistsFilters ? newFilters : {},
    });
  }

  resetFilters = () => {
    const { setBenchListSettings } = this.props;
    setBenchListSettings({ filters: {} });
    this.setState({ AsOfDate: '' });
  }

  handleChangeDate = (date) => {
    this.setState({
      AsOfDate: date ? moment(date).format('MM/DD/YYYY') : null,
    });
  }

  downloadXLSXDocument = () => {
    const { downloadXLSXDocument, listSettings } = this.props;
    const { searchValue, filters } = listSettings;
    downloadXLSXDocument(filters, searchValue, 'bench-reports/export-xlsx?SearchString=', 'Bench Reports.xlsx');
  }

  goEmployeeDetails = (reportItem) => {
    const { transitionToEmployeeDetails } = this.props;
    if (reportItem.Employee) {
      transitionToEmployeeDetails(reportItem.Employee.Id);
    }
  }

  getLinkEmployeeDetails = (reportItem) => {
    return {
      pathname: `/employees/${reportItem.Employee.Id}`,
    };
  }

  searchBenchReports = (searchValue) => {
    const { setBenchListSettings } = this.props;
    setBenchListSettings({ searchValue });
  };

  render() {
    const {
      reports, offices, statuses, employeeDepartments, listSettings: {
        sortColumn, sortDirection, filters, searchValue,
      },
    } = this.props;
    const { columnsToDisplay, AsOfDate } = this.state;
    return (
      <div className='page-cont'>
        <DocumentTitle title={applicationTitle.getTitile('reports')} />
        <ListToolbar
          searchValue={searchValue}
          showExportButton
          exportDocument={this.downloadXLSXDocument}
          searchPlaceholder='Search Reports'
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          onSearchClick={this.searchBenchReports}
          items={[{
            name: 'Offices',
            id: 'OfficeIds',
            options: sortFilterList(offices) || [],
            multiSelect: true,
            placeholder: 'All Offices',
            filtersItem: filters && filters.OfficeIds,
          },
          {
            name: 'Employee Department',
            id: 'EmployeeDepartments',
            options: sortFilterList(employeeDepartments) || [],
            multiSelect: true,
            placeholder: 'All Employee Departments',
            filtersItem: filters && filters.EmployeeDepartments,
          },
          {
            name: 'Employee Type',
            id: 'EmployeeTypeIds',
            options: sortFilterList(statuses) || [],
            multiSelect: true,
            placeholder: 'All Employee Types',
            filtersItem: filters && filters.EmployeeTypeIds,
          },
          {
            name: 'As of Date',
            id: 'AsOfDate',
            datepicker: true,
            placeholder: 'Date',
            formClassName: 'step__form svg-calendar-add-info',
            datePickerClassName: 'step__date-picker data-pisker__filter--margin',
            value: AsOfDate,
            onChange: this.handleChangeDate,
          }]}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll'>
            <List
              columnsToDisplay={columnsToDisplay}
              items={sortList(reports.bench, sortColumn, sortDirection)}
              hideDots
              setSortSettings={this.setSortSettings}
              sortColumnName={sortColumn}
              sortDirection={sortDirection}
              fixedHeader
              dontShowOptions
              transition={this.getLinkEmployeeDetails}
              config={{
                'Employee': { cell: CellLinkForRedirect },
                'MainSkills': { cell: CellSkill },
              }}
            />
          </div>
        </Scrollbars>
      </div>
    );
  }
}

BenchReports.propTypes = {
  reports: PropTypes.object.isRequired,
  searchBenchReports: PropTypes.func.isRequired,
  downloadXLSXDocument: PropTypes.func.isRequired,
};

export default connect((store) => ({
  reports: store.reportsReducer.reports,
  locations: store.filtersReducer.filters.Locations,
  statuses: store.filtersReducer.filters.EmployeeStatusesNew,
  employeeDepartments: store.filtersReducer.filters.EmployeeDepartments,
  offices: store.filtersReducer.filters.Offices,
  listSettings: store.benchReportsReducer.listSettings,
}), {
  searchBenchReports,
  getFilters,
  downloadXLSXDocument,
  transitionToEmployeeDetails,
  setComponent,
  resetComponent,
  setBenchReports,
  setBenchListSettings,
  resetBenchListSettings,
})(BenchReports);
