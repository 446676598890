import { setEmployeeDetails, setVacations } from 'pages/members/actions/membersActions';
import {
  setProjectRoles,
  setProjects,
  setProjectDetails,
  setProjectTeams,
} from 'pages/projects/actions/projectsActions';
import { setClientById } from 'pages/clients/actions/clientsActions';
import { setExpiredContracts, setTotalCountContracts } from '../pages/contracts/actions/contractsActions';
import { setManagesRoleTypes } from '../pages/admin/actions/adminManageRoleTypes';
import { setManagesBusinessTrips } from '../pages/admin/actions/adminManageBisunessTrips';
import { setManagesPaymentTerms } from '../pages/admin/actions/adminManagePaymentTerms';
import { setManagesInvoiceDates } from '../pages/admin/actions/adminManageInvoiceDates';
import { setManagesSeniorityLevels } from '../pages/admin/actions/adminManageSeniorityLevels';
import { setManagesSkillLevels } from '../pages/admin/actions/adminManageSkillLevels';
import { setManageSowTypes } from '../pages/admin/actions/adminManageSowTypes';
import { setManageCertificates } from '../pages/admin/actions/adminCertificatesActions';
import { setManageSkills } from '../pages/admin/actions/adminManageSkills';
import { setManageProjectTypes } from '../pages/admin/actions/adminManageProjectTypes';
import { setManagesJobTitles } from '../pages/admin/actions/adminManageJobTitles';
import { setManagesLocations } from '../pages/admin/actions/adminManageLocations';
import { setManagesCountries } from '../pages/admin/actions/adminManageCountries';
import { setManagesDocumentTypes } from '../pages/admin/actions/adminManageDocumentTypes';
import { setSOWRenewalStatus } from '../pages/admin/actions/adminManageSOWRenewalStatus';
import { setAssignmentTypes } from '../pages/admin/actions/adminAssignmentTypesAction';
import { setManageEngagementTypes } from '../pages/admin/actions/adminManageEngagementTypes';
import { setManageApiTokens } from '../pages/admin/actions/adminManageApiTokens';
import { setBackFillTypes } from '../pages/admin/actions/adminManageBackFillTypes';
import { setEmployeeDepartments } from '../pages/admin/actions/adminManageEmployeeDepartments';
import { setEmployeeTypes } from '../pages/admin/actions/adminManageEmployeeTypes';

const updateSagasConfig = {
  'employees/resume': {
    url: 'employees',
    saga: setEmployeeDetails,
    errorMessage: 'Impossible to remove Resume.',
    isEmployee: true,
  },
  'employees/skills': {
    url: 'employees',
    saga: setEmployeeDetails,
    errorMessage: 'Impossible to remove Main Skill.',
    isEmployee: true,
  },
  'projects/roles': {
    url: 'projects',
    saga: setProjectRoles,
    errorMessage: 'Impossible to remove role. Role has associated data.',
    roleSaga: true,
  },
  'projects/roles/assignment': {
    url: 'projects',
    saga: setProjectRoles,
    errorMessage: 'Impossible to remove employee. Employee has associated data.',
    roleSaga: true,
  },
  'deleteClients': {
    url: 'clients',
    noUppdate: true,
    errorMessage: 'Impossible to remove client. Client has associated data.',
  },
  'deleteProjects': {
    url: 'projects',
    saga: setProjects,
    errorMessage: 'Impossible to remove project. Project has associated data',
    noUppdate: true,
  },
  'client/contacts': {
    url: 'clients',
    saga: setClientById,
    errorMessage: 'This contact uses in the project and it cannot be removed',
  },
  'projects/sow': {
    url: 'projects',
    saga: setProjectDetails,
    errorMessage: 'Impossible to remove SoW. SoW has associated data',
    roleSaga: true,
  },
  'projects/msa': {
    url: 'projects',
    saga: setProjectDetails,
    errorMessage: 'Impossible to remove MSA',
    roleSaga: true,
  },
  'projects/po': {
    url: 'projects',
    saga: setProjectDetails,
    errorMessage: 'Impossible to remove contract. Contract has associated data',
    roleSaga: true,
  },
  'projects/teams': {
    url: 'projects',
    saga: setProjectTeams,
    errorMessage: 'Impossible to delete team. Team has associated data',
    isTeam: true,
  },
  'contracts/sow': {
    url: 'contracts',
    saga: { setExpiredContracts, setTotalCountContracts },
    errorMessage: 'Impossible to remove SoW. SoW has associated data',
    contractSaga: true,
  },
  'contracts/po': {
    url: 'contracts',
    saga: { setExpiredContracts, setTotalCountContracts },
    errorMessage: 'Impossible to remove contract. Contract has associated data',
    contractSaga: true,
  },
  'employees/vacations': {
    url: 'employees',
    saga: setVacations,
    errorMessage: 'Impossible to remove Vacation. Vacation has associated data',
  },
  'role-types': {
    url: 'role_types',
    saga: setManagesRoleTypes,
    errorMessage: 'Impossible to remove Role Type. Type has associated data.',
  },
  'business_trips': {
    url: 'business_trips',
    saga: setManagesBusinessTrips,
    errorMessage: 'Impossible to remove Business Trip. Trip has associated data.',
  },
  'payment_term': {
    url: 'payment_term',
    saga: setManagesPaymentTerms,
    errorMessage: 'Impossible to remove Payment Term. Term has associated data.',
  },
  'InvoiceDates': {
    url: 'InvoiceDates',
    saga: setManagesInvoiceDates,
    errorMessage: 'Impossible to remove Invoice Date. Term has associated data.',
  },
  'seniority_levels': {
    url: 'seniority_levels',
    saga: setManagesSeniorityLevels,
    errorMessage: 'Impossible to remove Seniority Level. Level has associated data.',
  },
  'skill_levels': {
    url: 'skill_levels',
    saga: setManagesSkillLevels,
    errorMessage: 'Impossible to remove Skill Level. Level has associated data.',
  },
  'sow_types': {
    url: 'sow_types',
    saga: setManageSowTypes,
    errorMessage: 'Impossible to remove Sow Type. Type has associated data.',
  },
  'lookup_certificates': {
    url: 'certificates',
    saga: setManageCertificates,
    errorMessage: 'Impossible to remove Certificate. Type has associated data.',
  },
  'skills': {
    url: 'skills',
    saga: setManageSkills,
    errorMessage: 'Impossible to remove Skill. Skill has associated data.',
  },
  'project_types': {
    url: 'project_types',
    saga: setManageProjectTypes,
    errorMessage: 'Impossible to remove Project type. Project type has associated data',
  },
  'jobTitles': {
    url: 'jobTitles',
    saga: setManagesJobTitles,
    errorMessage: 'Impossible to remove Job Title. Job title has associated data',
  },
  'locations': {
    url: 'location_offices',
    saga: setManagesLocations,
    errorMessage: 'Impossible to remove Location. Location has associated data',
  },
  'countries': {
    url: 'countries',
    saga: setManagesCountries,
    errorMessage: 'Impossible to remove Country. Country has associated data',
  },
  'document_types': {
    url: 'document_types',
    saga: setManagesDocumentTypes,
    errorMessage: 'Impossible to remove Document Type. Type has associated data',
  },
  'renewal_statuses': {
    url: 'renewal-statuses',
    saga: setSOWRenewalStatus,
    errorMessage: 'Impossible to remove SOW Renewal Status. Type has associated data',
  },
  'assignment_types': {
    url: 'assignment_types',
    saga: setAssignmentTypes,
    errorMessage: 'Impossible to remove Assigment Type. Type has associated data',
  },
  'engagement_types': {
    url: 'engagement_types',
    saga: setManageEngagementTypes,
    errorMessage: 'Impossible to remove Project type. Project type has associated data',
  },
  'api_tokens': {
    url: 'auth/api_tokens',
    saga: setManageApiTokens,
    errorMessage: 'Impossible to remove api token.',
  },
  'back-fill-types': {
    url: 'back-fill-types',
    saga: setBackFillTypes,
    errorMessage: 'Impossible to remove backfill type',
  },
  'employee-departments': {
    url: 'employee-departments',
    saga: setEmployeeDepartments,
    errorMessage: 'Employee department is in use and cannot be removed',
  },
  'employee-types': {
    url: 'employee-types',
    saga: setEmployeeTypes,
    errorMessage: 'Employee type is in use and cannot be removed',
  },
};

const getUrl = (payload, history, key) => {
  switch (payload.searchUpdateSaga) {
    case 'projects/roles':
    case 'projects/roles/assignment':
      return `${updateSagasConfig[key].url}/${payload.entityId}/roles?isOnlyActive=${payload.isOnlyActive}`;
    case 'deleteProjects':
      return 'projects';
    case 'employees/vacations':
      return `${updateSagasConfig[key].url}/${payload.entityId}/vacations?yearOfVacation=${payload.year}`;
    case 'deleteClients':
    case 'role-types':
    case 'business_trips':
    case 'payment_term':
    case 'InvoiceDates':
    case 'seniority_levels':
    case 'skill_levels':
    case 'sow_types':
    case 'skills':
    case 'project_types':
    case 'jobTitles':
    case 'locations':
    case 'countries':
    case 'document_types':
    case 'lookup_certificates':
    case 'renewal_statuses':
    case 'assignment_types':
    case 'engagement_types':
    case 'api_tokens':
    case 'back-fill-types':
    case 'employee-departments':
    case 'employee-types':
      return `${updateSagasConfig[key].url}`;
    case 'contracts/po':
    case 'contracts/sow':
      return 'contracts?skip=0&take=25';
    case 'projects/teams':
      return `${updateSagasConfig[key].url}/${payload.entityId}/teams`;
    default:
      return `${updateSagasConfig[key].url}/${payload.entityId}`;
  }
};

export const getUppdateData = (payload, history) => {
  for (const key in updateSagasConfig) {
    if (key === payload.searchUpdateSaga) {
      return {
        url: getUrl(payload, history, key),
        saga: updateSagasConfig[key].saga,
        params: history.location.state,
        message: updateSagasConfig[key].errorMessage,
        roleSaga: updateSagasConfig[key].roleSaga,
        isEmployee: updateSagasConfig[key].isEmployee,
        noUppdate: updateSagasConfig[key].noUppdate,
        contractSaga: updateSagasConfig[key].contractSaga,
        isTeam: updateSagasConfig[key].isTeam,
      };
    }
  }
};
