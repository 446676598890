import React, { Component } from 'react';
import { ColorPicker } from 'office-ui-fabric-react';
import classNames from 'classnames';

export class CellColorPicker extends Component {
  constructor(props) {
    super(props);
    const { item, column } = this.props;
    this.state = {
      value: item[column.Id] || '#000000',
      isEditMode: false,
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.onBlur();
    }
  }

  toggleViewCell = () => {
    const { isEditMode } = this.state;

    this.setState({ isEditMode: !isEditMode });
  }

  handleChange = (e, color) => {
    this.setState({ value: `#${color.hex}` });
  };

  onBlur = () => {
    const { column, config, item } = this.props;
    const { value } = this.state;
    const { valueSetter } = config[column.Id];
    const newItem = {
      ...item,
      [column.Id]: value,
    };
    valueSetter(newItem, value);
    this.toggleViewCell();
  }

  render() {
    const { column, isHaveRights } = this.props;
    const { value, isEditMode } = this.state;

    return (
      <div
        className={classNames(column.className ? column.className : 'small-col', 'c-list__item-column', 'c-list__item-column--color-picker')}
      >
        {
          isEditMode && isHaveRights ? (
            <div ref={this.wrapperRef}>
              <ColorPicker
                color={value}
                className='c-list-item-color-picker'
                onChange={this.handleChange}
              />
            </div>
          ) : (
            <div
              onClick={this.toggleViewCell}
              className='c-list-item-color-picker--input'
            >
              <span style={{ backgroundColor: value }} />
            </div>
          )
        }
      </div>
    );
  }
}
