import {
  CHANGE_TRAININGS_COLUMNS_TO_DISPLAY,
  RESET_TRAININGS_LIST_SETTINGS,
  SET_TRAININGS_LIST_SETTINGS,
} from '../actions/trainingsActions';
import {
  EMPLOYEE_DEPARTMENT_ADMIN_STAFF_ID,
  EMPLOYEE_DEPARTMENT_DELIVERY_MANAGERS_ID,
  EMPLOYEE_DEPARTMENT_TECHNICAL_PRODUCTION_ID,
  EMPLOYEES_STATUS_BENCH_ID,
  EMPLOYEES_STATUS_EMPLOYEE_ID,
} from '../../../utils/const-variable';

const defaultListSettings = {
  searchValue: '',
  sortColumn: 'Employee',
  sortDirection: 'Up',
  filters: {
    EmployeeTypes: [EMPLOYEES_STATUS_EMPLOYEE_ID, EMPLOYEES_STATUS_BENCH_ID],
    EmployeeDepartments: [EMPLOYEE_DEPARTMENT_TECHNICAL_PRODUCTION_ID, EMPLOYEE_DEPARTMENT_DELIVERY_MANAGERS_ID, EMPLOYEE_DEPARTMENT_ADMIN_STAFF_ID],
  },
  takeAmountReports: 50,
};

export const defaultColumnsToDisplay = [
  {
    Name: 'Employee', Id: 'Employee', className: 'big-report-col', isObject: true, isSelected: true, necessary: true,
  }, {
    Name: 'Office', Id: 'Office', className: 'fixed-medium-report-col', isObject: true, isSelected: true,
  }, {
    Name: 'Hiring Date', Id: 'HiringDate', className: 'fixed-medium-report-col', isDate: true, isSelected: true,
  }, {
    Name: 'Client', Id: 'Clients', className: 'fixed-medium-report-col', isArray: true, isSelected: true,
  }, {
    Name: 'Project', Id: 'Projects', className: 'fixed-medium-report-col', isArray: true, isSelected: true,
  }, {
    Name: 'Direct Manager', Id: 'DirectManager', className: 'fixed-medium-report-col', isObject: true, isSelected: true,
  }, {
    Name: 'HR Manager', Id: 'HrManager', className: 'fixed-medium-report-col', isObject: true, isSelected: true,
  },
];

export const initialState = {
  listSettings: defaultListSettings,
  columnsToDisplay: defaultColumnsToDisplay,
};

const trainingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRAININGS_LIST_SETTINGS:
      return {
        ...state,
        listSettings: {
          ...state.listSettings,
          ...action.payload,
        },
      };
    case RESET_TRAININGS_LIST_SETTINGS:
      return {
        ...state,
        listSettings: defaultListSettings,
      };
    case CHANGE_TRAININGS_COLUMNS_TO_DISPLAY:
      return {
        ...state,
        columnsToDisplay: action.payload.columnsToDisplay,
      };
    default:
      return state;
  }
};

export default trainingsReducer;
