import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import ManageJobTitles from './manage-jobtitles/manage-jobtitles';
import ManageRoleTypes from './manage-role-types/manage-role-types';
import ManageProjectTypes from './manage-project-types/manage-project-types';
import ManageLocations from './manage-locations/manage-locations';
import ManageSkills from './manage-skills/manage-skills';
import ManageBusinessTrips from './manage-business-trip/manage-business-trip';
import ManagePaymentTerms from './manage-payment-terms/manage-payment-terms';
import ManageSeniorityLevels from './manage-seniority-levels/manage-seniority-levels';
import ManageSkillLevels from './manage-skill-levels/manage-skill-levels';
import ManageSowTypes from './manage-sow-types/manage-sow-types';
import ManageDocumentTypes from './manage-document-types/manage-document-types';
import ManageCertificates from './manage-certificates/manage-certificates';
import ManageInvoiceDates from './manage-invoice-dates/manage-invoice-dates';
import ManageEmployeeTrainings from './manage-employee-trainings/manage-employee-trainings';
import ManageSOWRenewalStatus from './manage-sow-renewal-status/manage-sow-renewal-status';
import ManageAssignmentTypes from './manage-assignment-types/manage-assignment-types';
import ManageEngagementTypes from './manage-engagement-types/manage-engagement-types';
import ManageBackFillTypes from './manage-back-fill-types/manage-back-fill-types';
import ManageEmployeeDepartments from './manage-employee-departments/manage-employee-departments';
import ManageEmployeeTypes from './manage-employee-types/manage-employee-types';
import { resetListSettingsLookups } from 'pages/admin/actions/adminActions';

export class ManageLookups extends Component {
  componentDidUpdate(prevProps) {
    const { location, resetListSettingsLookups } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      resetListSettingsLookups();
    }
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path='/admin/lookups/manage-jobtitles' component={ManageJobTitles} />
          <Route path='/admin/lookups/manage-role-types' component={ManageRoleTypes} />
          <Route path='/admin/lookups/manage-project-types' component={ManageProjectTypes} />
          <Route path='/admin/lookups/manage-locations' component={ManageLocations} />
          <Route path='/admin/lookups/manage-skills' component={ManageSkills} />
          <Route path='/admin/lookups/manage-business-trips' component={ManageBusinessTrips} />
          <Route path='/admin/lookups/manage-payment-terms' component={ManagePaymentTerms} />
          <Route path='/admin/lookups/manage-invoice-dates' component={ManageInvoiceDates} />
          <Route path='/admin/lookups/manage-employee-trainings' component={ManageEmployeeTrainings} />
          <Route path='/admin/lookups/manage-seniority-levels' component={ManageSeniorityLevels} />
          <Route path='/admin/lookups/manage-skill-levels' component={ManageSkillLevels} />
          <Route path='/admin/lookups/manage-sow-types' component={ManageSowTypes} />
          <Route path='/admin/lookups/document-types' component={ManageDocumentTypes} />
          <Route path='/admin/lookups/certificates' component={ManageCertificates} />
          <Route path='/admin/lookups/manage-renewal-status' component={ManageSOWRenewalStatus} />
          <Route path='/admin/lookups/manage-assignment-types' component={ManageAssignmentTypes} />
          <Route path='/admin/lookups/manage-engagement-types' component={ManageEngagementTypes} />
          <Route path='/admin/lookups/manage-back-fill-types' component={ManageBackFillTypes} />
          <Route path='/admin/lookups/manage-employee-departments' component={ManageEmployeeDepartments} />
          <Route path='/admin/lookups/manage-employee-types' component={ManageEmployeeTypes} />
        </Switch>
      </Fragment>
    );
  }
}

export default connect(() => ({}), {
  resetListSettingsLookups,
})(ManageLookups);
