import React, { Component, Fragment } from 'react';
import 'babel-polyfill';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Router, Route, Switch, Redirect,
} from 'react-router-dom';
import history from 'utils/history';
import SidebarComponent from 'common/components/sidebar/sidebar';
import HeaderComponent from 'common/components/header/header';
import { logout } from 'common/actions/authActions';
import { setRoles } from 'common/actions/userRoleActions';
import { getVersion } from 'common/actions/versionActions';
import { closeDialog, confirmDeleteDialog, closeAlertDialog } from 'common/actions/confirmationDialogActions';
import ProjectsPageComponent from 'pages/projects/projects-page';
import LoginPage from 'pages/login/login-page';
import MembersPage from 'pages/members/members-page';
import ClientsPage from 'pages/clients/clients-page';
import OfficesPage from 'pages/offices/offices-page';
import BillingPageComponent from 'pages/billing/billing-page';
import AdminPage from 'pages/admin/admin-page';
import ContractsPage from 'pages/contracts/contracts-page';
import ReportsPage from 'pages/reports/reports-page';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Scrollbars from 'react-custom-scrollbars';
import { Preloader } from 'common/components/preloader/preloader.js';
import { ConfirmationDialog } from 'common/components/confirmation-dialog/confirmation-dialog.js';
import { AlertDialog } from 'common/components/alert-dialog/alert-dialog.js';
import DocumentTitle from 'react-document-title';
import { applicationTitle } from 'utils/applicationTitle';
import { getPrevPathForEmployees } from './pages/members/actions/membersActions';
import { getPrevPathForProjects } from './pages/projects/actions/projectsActions';

import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      light: '#F26939',
      main: '#F26939',
      dark: '#F26939',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f44336',
    },
  },
  typography: {
    useNextVariants: true,
  },
});
class AppRoute extends Component {
  componentDidMount() {
    const notifications = JSON.parse(sessionStorage.getItem('notifications'));
    if (!notifications) {
      sessionStorage.setItem('notifications', JSON.stringify({ notifications: [] }));
    }
    const {
      role, setRoles, getVersion,
    } = this.props;
    role && setRoles(role.UserRoles);
    getVersion();
  }

  componentDidUpdate(prevProps) {
    const {
      role, setRoles, version, getVersion, resetStore,
    } = this.props;
    if (prevProps.role !== role && role !== null) {
      setRoles(role.UserRoles);
    }
    if (prevProps.version !== version) {
      getVersion();
    }
  }

  ProjectsPage = (props) => {
    const { role, getPrevPathForEmployees } = this.props;
    getPrevPathForEmployees('');
    return (
      <ProjectsPageComponent
        {...props}
        CanCreateProject={role.Permissions.CanCreateProject}
        EditProjectBasicInfo={role.Permissions.EditProjectBasicInfo}
        EditProjectBasicFinanceInfo={role.Permissions.EditProjectBasicFinanceInfo}
        ManageProjectContracts={role.Permissions.ManageProjectContracts}
        ManageProjectRoles={role.Permissions.ManageProjectRoles}
        ManageProjectRoleAssignments={role.Permissions.ManageProjectRoleAssignments}
        HasFinancialAccess={role.Permissions.HasFinancialAccess}
      />
    );
  };

  MembersPage = (props) => {
    const { role, getPrevPathForProjects, isAdmin } = this.props;
    getPrevPathForProjects('');
    return (
      <MembersPage
        {...props}
        HasFinancialAccess={role.Permissions.HasFinancialAccess}
        EditEmployeeBasicInfo={role.Permissions.EditEmployeeBasicInfo}
        ManageEmployeeSkills={role.Permissions.ManageEmployeeSkills}
        ManageEmployeeVacations={role.Permissions.ManageEmployeeVacations}
        ManageEmployeeResume={role.Permissions.ManageEmployeeResume}
        CanSyncData={role.Permissions.CanSyncData}
        ManageEmployeeCertificates={role.Permissions.ManageEmployeeCertificates}
        ManageOfficeCalendar={role.Permissions.ManageOfficeCalendar}
        ViewEmployeesAssignedToProject={role.Permissions.ViewEmployeesAssignedToProject}
        UserOfficeId={role.UserOfficeId}
        isAdmin={isAdmin}
      />
    );
  };

  OfficesPage = (props) => {
    const { role, getPrevPathForEmployees, getPrevPathForProjects } = this.props;
    getPrevPathForEmployees('');
    getPrevPathForProjects('');
    return (
      <OfficesPage
        {...props}
        EditOfficeBasicInfo={role.Permissions.EditOfficeBasicInfo}
        ManageOfficeCalendar={role.Permissions.ManageOfficeCalendar}
        ManageOfficeLdapPath={role.Permissions.ManageOfficeLdapPath}
      />
    );
  };

  ClientsPage = (props) => {
    const { role, getPrevPathForEmployees, getPrevPathForProjects } = this.props;
    getPrevPathForEmployees('');
    getPrevPathForProjects('');
    return (
      <ClientsPage
        {...props}
        CanCreateClient={role.Permissions.CanCreateClient}
        EditClientBasicInfo={role.Permissions.EditClientBasicInfo}
        ManageClientContact={role.Permissions.ManageClientContact}
      />
    );
  };

  ContractsPageContainer = (props) => {
    const { role, getPrevPathForEmployees, getPrevPathForProjects } = this.props;
    getPrevPathForEmployees('');
    getPrevPathForProjects('');
    return (
      <ContractsPage
        {...props}
        ManageProjectContracts={role.Permissions.ManageProjectContracts}
        HasFinancialAccess={role.Permissions.HasFinancialAccess}
      />
    );
  };

  ReportsPageContainer = (props) => {
    const {
      role, getPrevPathForEmployees, getPrevPathForProjects, isAdmin,
    } = this.props;
    getPrevPathForEmployees('');
    getPrevPathForProjects('');
    return (
      <ReportsPage
        {...props}
        HasFinancialAccess={role.Permissions.HasFinancialAccess}
        ManageProjectRoles={role.Permissions.ManageProjectRoles}
        ManageProjectContracts={role.Permissions.ManageProjectContracts}
        isAdmin={isAdmin}
      />
    );
  };

  BillingContainer = (props) => {
    const { getPrevPathForEmployees, getPrevPathForProjects } = this.props;
    getPrevPathForEmployees('');
    getPrevPathForProjects('');
    return (<BillingPageComponent
      {...props}
    />);
  }

  render() {
    const {
      authToken,
      version,
      loadingReducer,
      confirmationDialogReducer,
      closeDialog,
      confirmDeleteDialog,
      alertDialogReducer,
      closeAlertDialog,
      isAdmin,
      hasFinancialAccess,
      role,
    } = this.props;
    const rights = !!authToken;
    const usersListWithBillingAccess = [
      '6b38c2fa-2e44-4315-7a9d-08d6a1846d39', // OLEKSANDR BARDAKOV
      '0261ef4b-ab23-412b-b5ca-08d6d246b737', // IURII GOROKH
      'b7737a46-23f7-47d2-e860-08d77197f46e', // DENYS HOLOVIN
      '96fe469e-630f-4456-85d4-79711bf8f098', // LYUDMYLA KALMYKOVA
      'b8bfabbd-8f62-42c3-7a91-08d6a1846d39', // DMYTRO SHOKOV
      'f32dcfe9-2b43-40b6-7a8d-08d6a1846d39', // VIACHESLAV SHENDRYK
      '7b33570a-63ae-480f-e861-08d77197f46e', // ROSTYSLAV LUTSENKO
      '2c6d3db9-289b-422a-e863-08d77197f46e', // KYRYLO SHOFUL
    ];
    const isShowBilling = role && usersListWithBillingAccess.includes(role.EmployeeId);
    const { isLoading } = loadingReducer;

    return (
      <DocumentTitle title={applicationTitle.getTitile('theodolite')}>
        <Router history={history}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={theme}>
              <div className='app'>
                {
                  isLoading > 0 ? <Preloader /> : null
                }
                {
                  confirmationDialogReducer.isDialogOpened
                    ? (
                      <ConfirmationDialog
                        dialogHeader='DELETE'
                        dialogTitle={
                          confirmationDialogReducer.actionData && confirmationDialogReducer.actionData.dialogTitle ? (
                            confirmationDialogReducer.actionData.dialogTitle
                          ) : ('Are you sure to delete this item?')
                        }
                        closeButtonTitle={
                          confirmationDialogReducer.actionData
                          && confirmationDialogReducer.actionData.closeButtonTitle
                          || 'Cancel'
                        }
                        confirmButtonTitle={
                          confirmationDialogReducer.actionData
                          && confirmationDialogReducer.actionData.confirmButtonTitle
                          || 'Delete'
                        }
                        actionData={confirmationDialogReducer.actionData}
                        closeDialog={closeDialog}
                        confirmDialog={confirmDeleteDialog}
                      />) : null
                }
                {
                  alertDialogReducer.alertIsOpened
                    ? (
                      <AlertDialog
                        dialogHeader='Warning!'
                        dialogTitle={alertDialogReducer.errorMessage}
                        dialogData={alertDialogReducer.errorData}
                        confirmButtonTitle='OK'
                        closeDialog={closeAlertDialog}
                        confirmDialog={closeAlertDialog}
                      />) : null
                }
                {
                  rights
                    ? (<Switch>
                      <Route
                        path='/'
                        render={() => (
                          <Fragment>
                            <Scrollbars
                              autoHide
                              autoHideTimeout={300}
                              hideTracksWhenNotNeeded
                            >
                              <div className='page'>
                                <SidebarComponent
                                  admin={isAdmin}
                                  version={version}
                                  hasFinancialAccess={role.Permissions.HasFinancialAccess && hasFinancialAccess}
                                  isShowBilling={isShowBilling}
                                />
                                <div className='page-content'>
                                  <HeaderComponent />
                                  <Switch>
                                    <Route path='/reports' render={this.ReportsPageContainer} />
                                    <Route path='/projects' render={this.ProjectsPage} />
                                    <Route path='/employees' render={this.MembersPage} />
                                    <Route path='/clients' render={this.ClientsPage} />
                                    <Route path='/offices' render={this.OfficesPage} />
                                    <Route path='/contracts' render={this.ContractsPageContainer} />
                                    {isShowBilling && <Route path='/billing' render={this.BillingContainer} />}
                                    {
                                      isAdmin ? (
                                        <Route path='/admin' component={AdminPage} />
                                      ) : null
                                    }
                                    <Redirect to={new URLSearchParams(history.location.search).get('redirect') || '/projects'} />
                                  </Switch>
                                </div>
                              </div>
                            </Scrollbars>
                          </Fragment>)}
                      />
                    </Switch>)
                    : (<Switch>
                      <Route exact path='/login' component={LoginPage} />
                      <Redirect to={{
                        pathname: '/login',
                        search: `?redirect=${history.location.pathname}`,
                      }}
                      />
                    </Switch>)
                }
              </div>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </Router>
      </DocumentTitle>
    );
  }
}

AppRoute.propTypes = {
  authToken: PropTypes.string,
  expireIn: PropTypes.number,
  logout: PropTypes.func,
  role: PropTypes.object,
  confirmationDialogReducer: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default connect(store => ({
  authToken: store.authReducer.authToken,
  expireIn: store.authReducer.expireIn,
  role: store.authReducer.user,
  version: store.versionReducer.version,
  loadingReducer: store.loadingReducer,
  confirmationDialogReducer: store.dialogsReducer.confirmationDialogReducer,
  alertDialogReducer: store.dialogsReducer.alertDialogReducer,
  isAdmin: store.userRoleReducer.isAdmin,
  hasFinancialAccess: store.authReducer.HasFinancialAccess,
}), {
  logout,
  setRoles,
  getVersion,
  closeDialog,
  confirmDeleteDialog,
  closeAlertDialog,
  getPrevPathForEmployees,
  getPrevPathForProjects,
})(AppRoute);
