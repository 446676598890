import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  changeColumnsToDisplay,
  searchMembers,
  showSyncModal,
  synchronizeEmployees,
  setListSettings,
  resetMembers,
  synchronizeTrainings,
} from '../../actions/membersActions';
import { getSelectedTab } from 'common/actions/selectedTabActions';
import { List } from 'common/components/list/list';
import ListToolbar from 'common/components/list-toolbar/list-toolbar';
import history from 'utils/history';
import { sortFilterList } from 'common/components/list/sortList';
import {
  ADMIN_ROLE_NAME, EMPLOYEES_STATUS_ADMIN_ID, EMPLOYEES_STATUS_CANDIDATE, EMPLOYEES_STATUS_CANDIDATE_ID,
  EMPLOYEES_STATUS_CONTRACTOR, EMPLOYEES_STATUS_CONTRACTOR_ID, EMPLOYEES_STATUS_DISABLED_ID,
  EMPLOYEES_STATUS_EMPLOYEE,
  EMPLOYEES_STATUS_EMPLOYEE_ID, EMPLOYEES_STATUS_FORMER_ID, EMPLOYEES_STATUS_TRAINEE, EMPLOYEES_STATUS_TRAINEE_ID,
  PROJECT_ROLES_EMPLOYEES_ADMIN, PROJECT_ROLES_EMPLOYEES_DISABLED, PROJECT_ROLES_EMPLOYEES_FORMER,
} from 'utils/const-variable';
import isObjectNotEquals from 'utils/object-comparison';
import isEmptyObject from 'utils/isEmptyObject';
import { scrollToLastElement } from 'services/scrollToLastElement.js';
import { debounce } from 'services/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import Scrollbars from 'react-custom-scrollbars';
import { CustomStatusCell } from 'common/components/list/custom-cell/custom-status-cell';
import { downloadXLSXDocument } from '../../../reports/actions/xlsx-action';
import { setComponent, resetComponent } from 'common/actions/headerActions';
import { CellEmail } from 'common/components/list/custom-cell/cell-email';

const initialTakeAmount = 50;
const initialPage = 1;
const magnificationFactor = 15;

export class MembersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      takeAmount: initialTakeAmount,
    };
  }

  componentDidMount() {
    const {
      getSelectedTab,
      listSettings,
      totalCount,
      setComponent,
      searchMembers,
      defaultFilters,
      prevPath,
      setListSettings,
      resetMembers,
    } = this.props;

    const { takeAmount } = this.state;

    const {
      searchValue,
      sortColumn,
      sortDirection,
      filters,
      scrollTo,
    } = listSettings;

    resetMembers();

    if (!prevPath) {
      setListSettings({ filters: defaultFilters });
    } else {
      searchMembers(searchValue, filters, takeAmount, 0, { sortColumn, sortDirection });
    }

    getSelectedTab(0);
    scrollTo !== '' && scrollToLastElement('employees', scrollTo, true);
    setComponent({ title: `Employees (${totalCount})` });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      searchMembers, setComponent, listSettings, totalCount, members, resetMembers,
    } = this.props;

    const { takeAmount } = this.state;

    const {
      searchValue,
      sortColumn,
      sortDirection,
      filters,
    } = listSettings;

    if (takeAmount !== initialTakeAmount && prevState.takeAmount !== takeAmount) {
      searchMembers(searchValue, filters, takeAmount - members.length, members.length, { sortColumn, sortDirection });
    }

    if (isObjectNotEquals(prevProps.listSettings, listSettings)) {
      resetMembers();

      this.setState({
        takeAmount: initialTakeAmount,
      });

      this.scroll.pageLoaded = initialPage;

      searchMembers(searchValue, filters, initialTakeAmount, 0, { sortColumn, sortDirection });
    }

    if (prevProps.totalCount !== totalCount) {
      setComponent({ title: `Employees (${totalCount})` });
    }
  }

  componentWillUnmount() {
    const { resetComponent } = this.props;
    resetComponent();
  }

  setSearchValue = (value) => {
    const { setListSettings } = this.props;
    setListSettings({ searchValue: value });
  };

  setSortSettings = (sortColumn, sortDirection) => {
    const { setListSettings } = this.props;
    setListSettings({
      sortColumn,
      sortDirection,
    });
  }

  applyFilters = (filters) => {
    const { setListSettings } = this.props;
    const filtersToSend = !isEmptyObject(filters) ? filters : { isEmpty: true };
    setListSettings({
      filters: filtersToSend,
    });
  }

  resetFilters = () => {
    const { setListSettings } = this.props;
    setListSettings({
      filters: {},
    });
  }

  routeToEmployeeDetails = (id) => {
    history.push({
      pathname: `employees/${id}`,
    });
  }

  onSync = (department) => {
    const {
      user, showSyncModal, synchronizeEmployees, listSettings,
    } = this.props;

    const { takeAmount } = this.state;

    const {
      filters, searchValue, sortDirection, sortColumn,
    } = listSettings;

    const options = {
      filters,
      searchValue,
      take: takeAmount,
      sortSetting: {
        sortDirection,
        sortColumn,
      },
    };
    if (user.UserRoles.includes(ADMIN_ROLE_NAME)) {
      showSyncModal(options);
    } else {
      synchronizeEmployees(department, options);
    }
  };

  changeAmountEmployees = (page) => {
    const { isLoading } = this.props;
    const { takeAmount } = this.state;

    if (!isLoading) {
      this.setState({
        takeAmount: takeAmount + page * magnificationFactor,
      });
    }
  }

  getRequiredColumns = () => {
    const { columnsToDisplay } = this.props;
    const requiredColumns = [];

    for (const column of columnsToDisplay) {
      if (column.isSelected) {
        requiredColumns.push(column.Name.replace(/ /g, ''));
      }
    }

    return requiredColumns;
  }

  downloadXLSXDocument = () => {
    const { downloadXLSXDocument, listSettings } = this.props;
    const { searchValue, filters } = listSettings;
    const requestFilters = { ...filters };
    requestFilters.requiredColumns = this.getRequiredColumns();
    downloadXLSXDocument(requestFilters, searchValue, 'employees/export-xlsx?Name=', 'Employees.xlsx');
  }

  filterLocations = (locations) => {
    if (!locations) {
      return;
    }

    const filteredLocations = [];

    // eslint-disable-next-line no-unused-vars
    for (const location of locations) {
      const filteredLocation = filteredLocations.find(item => item.Name === location.Name);
      if (filteredLocation) {
        const index = filteredLocations.indexOf(filteredLocation);
        filteredLocations[index].Ids.push(location.Id);
      } else {
        filteredLocations.push({ Name: location.Name, Ids: [location.Id] });
      }
    }

    return filteredLocations;
  }

  mapTypes = (types) => {
    if (types && types.every(status => typeof status === 'number')) {
      return types;
    }

    const adminStatusName = PROJECT_ROLES_EMPLOYEES_ADMIN.replace(/ +/g, '');

    const mapProperties = {
      [EMPLOYEES_STATUS_EMPLOYEE]: EMPLOYEES_STATUS_EMPLOYEE_ID,
      [EMPLOYEES_STATUS_CONTRACTOR]: EMPLOYEES_STATUS_CONTRACTOR_ID,
      [PROJECT_ROLES_EMPLOYEES_FORMER]: EMPLOYEES_STATUS_FORMER_ID,
      [adminStatusName]: EMPLOYEES_STATUS_ADMIN_ID,
      [EMPLOYEES_STATUS_TRAINEE]: EMPLOYEES_STATUS_TRAINEE_ID,
      [PROJECT_ROLES_EMPLOYEES_DISABLED]: EMPLOYEES_STATUS_DISABLED_ID,
      [EMPLOYEES_STATUS_CANDIDATE]: EMPLOYEES_STATUS_CANDIDATE_ID,
    };
    const mappedTypes = [];

    for (const type of types) {
      if (type in mapProperties) {
        mappedTypes.push(mapProperties[type]);
      }
    }

    return mappedTypes;
  }

  onTrainingsSync = () => {
    const { synchronizeTrainings } = this.props;
    synchronizeTrainings();
  }

  render() {
    const {
      members,
      CanSyncData,
      offices,
      positions,
      projects,
      employeeAssignmentStatuses,
      statuses,
      locations,
      teamPositions,
      columnsToDisplay,
      changeColumnsToDisplay,
      departments,
      certificatesOptions,
      listSettings,
      totalCount,
      employeeDepartments,
    } = this.props;

    const {
      searchValue,
      sortColumn,
      sortDirection,
      filters,
    } = listSettings;

    const filteredLocations = this.filterLocations(locations);

    return (
      <div className='page-cont'>
        <ListToolbar
          searchPlaceholder='Search Employees'
          showExportButton
          exportDocument={this.downloadXLSXDocument}
          items={[{
            name: 'Offices',
            id: 'Offices',
            options: sortFilterList(offices) || [],
            multiSelect: true,
            placeholder: 'All Offices',
            filtersItem: filters && filters.Offices,
          }, {
            name: 'Job Titles',
            id: 'Positions',
            options: sortFilterList(positions) || [],
            suggestBox: true,
            multiple: false,
            placeholder: 'All Job Titles',
            filtersItem: filters && filters.Positions,
          }, {
            name: 'Projects',
            id: 'Projects',
            options: sortFilterList(projects) || [],
            suggestBox: true,
            multiple: false,
            placeholder: 'All Projects',
            filtersItem: filters && filters.Projects,
          }, {
            name: 'Projects Assignment Status',
            id: 'EmployeeAssignmentStatuses',
            options: sortFilterList(employeeAssignmentStatuses) || [],
            multiSelect: true,
            placeholder: 'All Projects Assignment Statuses',
            filtersItem: filters && filters.EmployeeAssignmentStatuses,
          }, {
            name: 'Employee Departments',
            id: 'EmployeeDepartments',
            options: sortFilterList(employeeDepartments) || [],
            multiSelect: true,
            placeholder: 'All Employee Departments',
            filtersItem: filters && filters.EmployeeDepartments,
          }, {
            name: 'Types',
            id: 'Statuses',
            options: sortFilterList(statuses) || [],
            multiSelect: true,
            placeholder: 'All Types',
            filtersItem: filters && filters.Statuses && this.mapTypes(filters.Statuses),
          }, {
            name: 'Locations',
            id: 'Locations',
            options: sortFilterList(filteredLocations) || [],
            suggestBox: true,
            multiple: true,
            placeholder: 'All Locations',
            filtersItem: filters && filters.Locations,
          }, {
            name: 'Business Locations',
            id: 'BusinessLocations',
            options: sortFilterList(filteredLocations) || [],
            suggestBox: true,
            multiple: true,
            placeholder: 'All Business Locations',
            filtersItem: filters && filters.BusinessLocations,
          }, {
            name: 'Certificates',
            id: 'Certificates',
            options: sortFilterList(certificatesOptions) || [],
            multiSelect: true,
            placeholder: 'All Certificates',
            filtersItem: filters && filters.Certificates,
          }, {
            name: 'Team Position',
            id: 'TeamPositions',
            options: sortFilterList(teamPositions) || [],
            multiSelect: true,
            placeholder: 'All Positions',
            filtersItem: filters && filters.TeamPositions,
          }]}
          filters={filters}
          applyFilters={this.applyFilters}
          resetFilters={this.resetFilters}
          onSearchClick={this.setSearchValue}
          searchValue={searchValue}
          columnsToDisplay={columnsToDisplay}
          changeColumnsToDisplay={changeColumnsToDisplay}
          showSyncButton={CanSyncData}
          onADSync={this.onSync}
          departments={departments}
          onTrainingsSync={this.onTrainingsSync}
        />
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          className='custom_scrollbar-container'
        >
          <div className='page-container_for-scroll' id='employees'>
            <InfiniteScroll
              pageStart={initialPage}
              loadMore={debounce(this.changeAmountEmployees, 500)}
              hasMore={members.length < totalCount}
              useWindow={false}
              initialLoad={false}
              ref={(scroll) => this.scroll = scroll}
            >
              <List
                page='employees'
                columnsToDisplay={columnsToDisplay}
                items={members}
                onItemClick={(id) => this.routeToEmployeeDetails(id)}
                setSortSettings={this.setSortSettings}
                sortColumnName={sortColumn}
                sortDirection={sortDirection}
                hideDots
                dontShowOptions
                fixedHeader
                config={{
                  'Department': {
                    cell: CustomStatusCell,
                  },
                  'Type': {
                    cell: CustomStatusCell,
                  },
                  'Email': {
                    cell: CellEmail,
                  },
                }}
              />
            </InfiniteScroll>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

MembersList.propTypes = {
  changeColumnsToDisplay: PropTypes.func,
  searchMembers: PropTypes.func,
  columnsToDisplay: PropTypes.array,
  members: PropTypes.array,
  offices: PropTypes.array,
  positions: PropTypes.array,
  projects: PropTypes.array,
  statuses: PropTypes.array,
  teamPositions: PropTypes.array,
  synchronizeEmployees: PropTypes.func,
  departments: PropTypes.array,
  user: PropTypes.object,
  showSyncModal: PropTypes.func,
  CanSyncData: PropTypes.bool,
  getSelectedTab: PropTypes.func,
  totalCount: PropTypes.number.isRequired,
  listSettings: PropTypes.object,
};

export default connect((store) => ({
  columnsToDisplay: store.membersReducer.columnsToDisplay,
  members: store.membersReducer.members,
  projects: store.filtersReducer.filters.Projects,
  employeeAssignmentStatuses: store.filtersReducer.filters.EmployeeAssignmentStatuses,
  locations: store.filtersReducer.filters.Locations,
  offices: store.filtersReducer.filters.Offices,
  statuses: store.filtersReducer.filters.EmployeeStatusesNew,
  employeeDepartments: store.filtersReducer.filters.EmployeeDepartments,
  positions: store.filtersReducer.filters.JobTitles,
  teamPositions: store.filtersReducer.filters.TeamPositions,
  departments: store.authReducer.departments,
  user: store.authReducer.user,
  totalCount: store.membersReducer.employeesTotalCount,
  certificatesOptions: store.filtersReducer.filters.Certificates,
  isLoading: store.loadingReducer.isLoading,
  listSettings: store.membersReducer.listSettings,
}), {
  changeColumnsToDisplay,
  searchMembers,
  synchronizeEmployees,
  showSyncModal,
  getSelectedTab,
  downloadXLSXDocument,
  setComponent,
  resetComponent,
  setListSettings,
  resetMembers,
  synchronizeTrainings,
})(MembersList);
