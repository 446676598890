export const applicationTitle = (() => {
  const titles = {
    'theodolite': 'Theodolite ',
    'loginPage': 'Theodolite  - LogIn',
    'projects': 'Theodolite  - Projects',
    'offices': 'Theodolite  - Offices',
    'clients': 'Theodolite  - Clients',
    'employees': 'Theodolite  - Employees',
    'contracts': 'Theodolite  - Contracts',
    'manageUsers': 'Theodolite  - Manage users',
    'settings': 'Theodolite  - Settings',
    'createProject': 'Theodolite  - Create Project',
    'createClient': 'Theodolite  - Create Client',
    'addRole': 'Theodolite  - Add Role',
    'editRole': 'Theodolite  - Edit Role',
    'viewRole': 'Theodolite  - View Role',
    'addSow': 'Theodolite  - Add SOW to Project',
    'editSow': 'Theodolite  - Edit SOW in Project',
    'viewSow': 'Theodolite  - View SOW in Project',
    'addPO': 'Theodolite  - Add PO to Project',
    'editPO': 'Theodolite  - Edit PO in Project',
    'viewPO': 'Theodolite  - View PO in Project',
    'addMSA': 'Theodolite - Add MSA to Project',
    'editMSA': 'Theodolite - Edit MSA in Project',
    'viewMSA': 'Theodolite - View MSA in Project',
    'editProjectEmployees': 'Theodolite  - Edit Employees in the role',
    'reports': 'Theodolite  - Reports',
    'lookups': 'Theodolite - Manage Lookups',
    'lookupsBusinessTrips': 'Theodolite - Manage Lookups - Business Trips',
    'lookupsPaymentTerms': 'Theodolite - Manage Lookups - Payment Terms',
    'lookupsJobTitles': 'Theodolite - Manage Lookups - Job Titles',
    'lookupsLocations': 'Theodolite - Manage Lookups - Locations',
    'lookupsProjectTypes': 'Theodolite - Manage Lookups - Project Types',
    'lookupsRoleTypes': 'Theodolite - Manage Lookups - Role Types',
    'lookupsSkills': 'Theodolite - Manage Lookups - Skills',
    'lookupsSeniorityLevels': 'Theodolite - Manage Lookups - Seniority Levels',
    'lookupsSkillLevels': 'Theodolite - Manage Lookups - Skill Levels',
    'lookupsCountry': 'Theodolite - Manage Lookups - Countries',
    'lookupsDocumentTypes': 'Theodolite - Manage Lookups - Document Types',
    'lookupsEngagementTypes': 'Theodolite - Manage Lookups - Engagement Types',
    'addAmendment': 'Theodolite  - Add Amendment to Project',
    'editAmendment': 'Theodolite  - Edit Amendment in Project',
    'viewAmendment': 'Theodolite  - View Amendment in Project',
    'lookupsCertificates': 'Theodolite - Manage Lookups - Certificates',
    'lookupsAssignmentTypes': 'Theodolite - Manage Lookups - Assignment Types',
    'billing': 'Theodolite  - Billing',
    'addTeam': 'Theodolite  - Add Team',
    'editTeam': 'Theodolite  - Edit Team',
    'manageApiTokens': 'Theodolite - Manage Api tokens',
    'lookupListOfTrainings': 'Employee - List of Trainings',
    'lookupsBackFillTypes': 'Theodolite - Manage Lookups - Backfill Types',
    'lookupsEmployeeDepartments': 'Theodolite - Manage Lookups - Employee Departments',
    'lookupsEmployeeTypes': 'Theodolite - Manage Lookups - Employee Types',
  };

  return { getTitile: titleName => titles[titleName] };
})();
